import React, { useRef, useState } from 'react';
import { ChevronDownIcon, DeleteIcon, EditIcon, EmailIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import { InvitationStatus, UserData } from '../../../../api/api-user';
import { useDeleteInvitedUserMutation } from '../../../site/customer-details/siteUsersApi';
import { selectSite } from '../../../site/siteSlice';
import { selectUser } from '../../userSlice';
import EditUserModal from './EditUserModal';
import {
  useDeleteConfirmedUserMutation,
  useResendInvitationMutation,
  useUpdateConfirmedUserMutation,
  useUpdateInvitedUserMutation,
} from './manageUsersApi';

type ManageUserMenuProps = {
  userDetails: UserData;
  index: number;
  status: InvitationStatus;
};

export default function ManageUserMenu({ userDetails, index, status }: ManageUserMenuProps) {
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });
  const [{ isDeleteModalOpen, isPromoteModalOpen, isEditUserModalOpen }, setState] = useState({
    isDeleteModalOpen: false,
    isPromoteModalOpen: false,
    isEditUserModalOpen: false,
  });
  const user = useSelector(selectUser);
  const { clipsal_solar_id } = useSelector(selectSite);
  const cancelDeleteRef = useRef<HTMLButtonElement | null>(null);
  const cancelPromoteRef = useRef<HTMLButtonElement | null>(null);
  const toast = useToast({ isClosable: true });
  const isAdminUser = user.role === 'ADMIN' || user.role === 'SUPER_ADMIN';

  const [resendInvite, { isLoading: isResendingInvitation }] = useResendInvitationMutation();
  const [deleteConfirmedUser, { isLoading: isDeletingConfirmed }] = useDeleteConfirmedUserMutation();
  const [deleteInvitedUser, { isLoading: isDeletingInvited }] = useDeleteInvitedUserMutation();
  const [updateInvitedUser, { isLoading: isPromotingInvited }] = useUpdateInvitedUserMutation();
  const [updateConfirmedUser, { isLoading: isPromotingConfirmed }] = useUpdateConfirmedUserMutation();

  const isDeleting = isDeletingConfirmed || isDeletingInvited;
  const isPromoting = isPromotingConfirmed || isPromotingInvited;

  const { user_email, user_id, user_name, role } = userDetails;

  const handleCloseDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: false,
    }));
  };
  const handleClosePromoteModal = () => {
    setState((prevState) => ({
      ...prevState,
      isPromoteModalOpen: false,
    }));
  };

  const isConfirmedUser = status === 'CONFIRMED';

  // hide delete button for own email
  const showDeleteButton = user_email !== user.user_email;

  // only admins can promote users to admin
  const showPromoteToAdminButton = isAdminUser && role === 'STAFF';

  return (
    <Menu>
      <MenuButton
        data-testid={`manage-user-button-${index}`}
        as={isMobileViewport ? IconButton : Button}
        {...(!isMobileViewport && { rightIcon: <ChevronDownIcon /> })}
        ml={2}
        minW={[8, 9]}
        h={[8, 9]}
      >
        {isMobileViewport ? <ChevronDownIcon /> : 'Manage'}
      </MenuButton>
      <MenuList minW={isMobileViewport ? 0 : 115} py={0}>
        {showDeleteButton && (
          <MenuItem
            data-testid={`user-${index}-delete`}
            icon={<DeleteIcon />}
            onClick={() => {
              setState((prevState) => ({ ...prevState, isDeleteModalOpen: true }));
            }}
          >
            {isConfirmedUser ? 'Delete User' : 'Revoke Invitation'}
          </MenuItem>
        )}

        {/* Cannot send email to confirmed users */}
        {!isConfirmedUser && (
          <MenuItem
            data-testid={`user-${index}-resend-email`}
            icon={isResendingInvitation ? <Spinner size="sm" /> : <EmailIcon />}
            isDisabled={isResendingInvitation}
            onClick={async () => {
              const response = await resendInvite(user_name);
              const isError = 'error' in response;
              const status = isError ? 'error' : 'success';
              const title = isError
                ? 'Something went wrong inviting the user!'
                : 'Successfully resent invitation to user!';
              toast({ status, title });
            }}
          >
            Resend Email
          </MenuItem>
        )}

        {/* Admins can promote staffs to admin */}
        {showPromoteToAdminButton && (
          <MenuItem
            data-testid={`user-${index}-promote`}
            icon={<Icon as={HiOutlineSpeakerphone} />}
            onClick={async () => {
              setState((prevState) => ({ ...prevState, isPromoteModalOpen: true }));
            }}
          >
            Promote to Admin
          </MenuItem>
        )}

        <MenuItem
          data-testid={`user-${index}-edit`}
          icon={<EditIcon />}
          onClick={async () => {
            setState((prevState) => ({ ...prevState, isEditUserModalOpen: true }));
          }}
        >
          Edit User
        </MenuItem>
      </MenuList>

      <AlertDialog
        isOpen={isPromoteModalOpen}
        leastDestructiveRef={cancelPromoteRef}
        onClose={handleClosePromoteModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent mx={4} data-testid={`promote-dialog`}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Promote Staff to Admin
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to promote <strong>{user_email}</strong> to admin?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelPromoteRef} onClick={handleClosePromoteModal} data-testid={`promote-cancel-button`}>
                Cancel
              </Button>
              <Button
                data-testid={`promote-confirm-button`}
                colorScheme="green"
                isLoading={isPromoting}
                onClick={async () => {
                  const body = { role: 'ADMIN' };
                  const response = await (isConfirmedUser
                    ? updateConfirmedUser({ body, userId: user_id || 0 })
                    : updateInvitedUser({ body, username: user_name }));
                  setState((prevState) => ({ ...prevState, isPromoteModalOpen: false }));
                  const isError = 'error' in response;
                  const status = isError ? 'error' : 'success';
                  const title = isError
                    ? 'Something went wrong promoting the user.'
                    : 'Successfully promoted to admin!';
                  toast({ status, title });
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isDeleteModalOpen}
        leastDestructiveRef={cancelDeleteRef}
        onClose={handleCloseDeleteModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent mx={4} data-testid={`delete-dialog`}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {isConfirmedUser ? 'Delete User' : 'Revoke Invitation'}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to {isConfirmedUser ? 'delete ' : 'revoke invitation for '}
              <strong>{user_email}</strong>?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelDeleteRef} onClick={handleCloseDeleteModal} data-testid={`delete-cancel-button`}>
                Cancel
              </Button>
              <Button
                data-testid={`delete-confirm-button`}
                colorScheme="red"
                isLoading={isDeleting}
                onClick={async () => {
                  const response = await (isConfirmedUser
                    ? deleteConfirmedUser(user_id || 0)
                    : deleteInvitedUser({ username: user_name, clipsal_solar_id }));
                  setState((prevState) => ({
                    ...prevState,
                    isDeleteModalOpen: false,
                  }));
                  const isError = 'error' in response;
                  const status = isError ? 'error' : 'success';
                  const title = isError ? 'Something went wrong deleting the user.' : 'Successfully deleted the user!';
                  toast({ status, title });
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <EditUserModal
        {...{
          isOpen: isEditUserModalOpen,
          onClose: () => setState((prevState) => ({ ...prevState, isEditUserModalOpen: false })),
          onOpen: () => setState((prevState) => ({ ...prevState, isEditUserModalOpen: true })),
          status,
          userDetails,
        }}
      />
    </Menu>
  );
}
