import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Assignment } from '../../../api/api-assignments';
import { InstalledDevice, Manufacturer } from '../../../api/api-device';
import { DeviceMetadata, DeviceType } from '../../../api/api-device-metadata';
import { get } from '../../../api/api-helpers';
import { RootState } from '../../../app/store';

export type SystemDetailsState = {
  manufacturers: Record<DeviceType, Manufacturer[]>;
  modelsByManufacturerId: Record<DeviceType, Record<string, DeviceMetadata[]>>; // Mapped by device type, then model ID
  assignments: Assignment[];
  inverters: InstalledDevice[];
  batteries: InstalledDevice[];
  evChargers: InstalledDevice[];
};

export const fetchManufacturers = createAsyncThunk<
  { manufacturers: Manufacturer[]; deviceType: DeviceType },
  DeviceType,
  { state: RootState }
>('systemDetails/fetchManufacturers', async (deviceType = 'INVERTER') => {
  const manufacturers = await get(`/fleet/manufacturers?device_type=${deviceType}`);
  return { manufacturers, deviceType };
});

export const fetchModelsByManufacturerId = createAsyncThunk<
  { models: DeviceMetadata[]; deviceType: DeviceType; manufacturerId: number },
  { manufacturerId: number; deviceType: DeviceType },
  { state: RootState }
>('systemDetails/fetchModelsByManufacturerId', async ({ manufacturerId, deviceType }, { getState }) => {
  const { systemDetails } = getState();

  // We can avoid a re-fetch if the models are already cached in the store, else nullish coalesce to an API result.
  const models =
    systemDetails.modelsByManufacturerId[deviceType]?.[manufacturerId] ??
    (await get(`/fleet/devices?manufacturer_id=${manufacturerId}&device_type=${deviceType}`));

  return {
    models,
    deviceType,
    manufacturerId,
  };
});

export const fetchAssignments = createAsyncThunk<Assignment[], void, { state: RootState }>(
  'systemDetails/fetchAssignments',
  async () => {
    return await get(`/fleet/assignments`);
  }
);

export const initialState: SystemDetailsState = {
  manufacturers: {
    INVERTER: [],
    SOLAR_MODULE: [],
    BATTERY_PACK: [],
    METER: [],
    EV_CHARGER: [],
  },
  modelsByManufacturerId: {
    INVERTER: {},
    SOLAR_MODULE: {},
    BATTERY_PACK: {},
    METER: {},
    EV_CHARGER: {},
  },
  assignments: [],
  inverters: [],
  batteries: [],
  evChargers: [],
};

export const systemDetailsSlice = createSlice({
  name: 'systemDetails',
  initialState,
  reducers: {
    setInverters: (state, action: PayloadAction<InstalledDevice[]>) => {
      state.inverters = action.payload;
    },
    setBatteries: (state, action: PayloadAction<InstalledDevice[]>) => {
      state.batteries = action.payload;
    },
    setEVChargers: (state, action: PayloadAction<InstalledDevice[]>) => {
      state.evChargers = action.payload;
    },
    removeInverter: (state, action: PayloadAction<number>) => {
      const inverterId = action.payload;
      state.inverters = state.inverters.filter((inverter) => inverter.row_id !== inverterId);
    },
    removeBattery: (state, action: PayloadAction<number>) => {
      const batteryId = action.payload;
      state.batteries = state.batteries.filter((battery) => battery.row_id !== batteryId);
    },
    removeEVCharger: (state, action: PayloadAction<number>) => {
      const evChargerId = action.payload;
      state.evChargers = state.evChargers.filter((evCharger) => evCharger.row_id !== evChargerId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchManufacturers.fulfilled, (state, action) => {
        return {
          ...state,
          manufacturers: {
            ...state.manufacturers,
            [action.payload.deviceType]: action.payload.manufacturers,
          },
        };
      })
      .addCase(fetchAssignments.fulfilled, (state, action) => {
        return {
          ...state,
          assignments: action.payload,
        };
      })
      .addCase(fetchModelsByManufacturerId.fulfilled, (state, action) => {
        return {
          ...state,
          modelsByManufacturerId: {
            ...state.modelsByManufacturerId,
            [action.payload.deviceType]: {
              ...state.modelsByManufacturerId[action.payload.deviceType],
              [action.payload.manufacturerId]: action.payload.models,
            },
          },
        };
      });
  },
});

export const { setInverters, setBatteries, setEVChargers, removeInverter, removeBattery, removeEVCharger } =
  systemDetailsSlice.actions;

export const selectAssignments = (state: RootState) => {
  return state.systemDetails.assignments;
};

export const selectManufacturers = (state: RootState) => {
  return state.systemDetails.manufacturers;
};

export const selectModels = (state: RootState) => {
  return state.systemDetails.modelsByManufacturerId;
};

export const selectManufacturersByDeviceType =
  (deviceType: DeviceType) =>
  (state: RootState): Manufacturer[] => {
    return state.systemDetails.manufacturers[deviceType];
  };

export const selectModelsByDeviceType =
  (deviceType: DeviceType) =>
  (state: RootState): Record<string, DeviceMetadata[]> => {
    return state.systemDetails.modelsByManufacturerId[deviceType];
  };

export const selectInverters = (state: RootState) => state.systemDetails.inverters;

export const selectBatteries = (state: RootState) => state.systemDetails.batteries;

export const selectEVChargers = (state: RootState) => state.systemDetails.evChargers;

export default systemDetailsSlice.reducer;
