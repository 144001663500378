import { useSelector } from 'react-redux';

import { ViewportType } from 'clipsal-cortex-utils/src/calculations/viewport-helpers';
import { useViewportType } from 'clipsal-cortex-utils/src/hooks/use-viewport-type';

import { baseApi } from '../../app/baseApi';
import { selectSite } from '../site/siteSlice';

export const siteApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNearmapImage: build.query<{ file_type: string; url: string }, { size: ViewportType; siteId: number }>({
      query: ({ size, siteId }) => `/v1/sites/${siteId}/image_url?size=${size}`,
      providesTags: (_, __, { siteId }) => [{ type: 'NearmapImage', id: siteId }],
    }),
    refreshSiteImage: build.mutation<void, number>({
      query: (siteId) => ({
        url: `/v1/sites/${siteId}/refresh_images`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, siteId) => [{ type: 'NearmapImage', id: siteId }],
    }),
  }),
});

export const { useGetNearmapImageQuery, useRefreshSiteImageMutation } = siteApi;

export const useNearmapImage = (skip?: boolean) => {
  const site = useSelector(selectSite);
  const { viewportType } = useViewportType();
  const { data } = useGetNearmapImageQuery({ size: viewportType, siteId: site.clipsal_solar_id }, { skip });
  return data?.url || '';
};
