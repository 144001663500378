import React from 'react';
import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  Skeleton,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Card from 'clipsal-cortex-ui/src/components/card/Card';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import FleetChartDesktopImg from '../../assets/images/fleet_chart_desktop.svg';
import FleetChartMobileImg from '../../assets/images/fleet_chart_mobile.svg';
import NewUserDashboardTemplate from '../../common/components/NewUserDashboardTemplate';
import { selectUser } from '../user/userSlice';
import DateRangeTypePicker from './DateRangeTypePicker';
import { FLEET_DASHBOARD_CHART_HEIGHT_PIXELS, getDisplayedDateTimeString } from './fleet-dashboard-helpers';
import FleetInfoStats from './FleetInfoStats';
import PeriodPicker from './PeriodPicker';
import UsageChart from './UsageChart';
import { useFleetData } from './useFleetData';
import FleetDashboardWidgets from './widgets/FleetDashboardWidgets';

type FleetDashboardContentsProps = {
  selectedTenantId: number;
};

export default function FleetDashboardContents({ selectedTenantId }: FleetDashboardContentsProps) {
  const user = useSelector(selectUser);
  const isSuperAdmin = user.role === 'SUPER_ADMIN';
  const {
    fleetData,
    handleUpdateDateRangeTypeAndPeriod,
    isWidgetDataLoaded,
    selectedDateRangeType,
    isChartLoaded,
    selectedPeriod,
    lastUpdated,
    showNewUserDashboardTemplate,
  } = useFleetData(selectedTenantId);
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const background = useColorModeValue('white', 'gray.900');

  if (!isWidgetDataLoaded)
    return (
      <Box h={'50vh'}>
        <CenteredLoader />
      </Box>
    );

  // Show the new user dashboard template if the user has no sites and is not a super admin
  if (showNewUserDashboardTemplate && !isSuperAdmin)
    return (
      <NewUserDashboardTemplate
        mobileImgSource={FleetChartMobileImg}
        desktopImgSource={FleetChartDesktopImg}
        secondaryText="Your Fleet Dashboard will appear here once you add your first Clipsal Cortex site."
      />
    );

  return (
    <>
      {isMobileViewport && (
        <Box>
          <Text mb={2} fontSize={'sm'} textAlign="center">
            Last updated {getDisplayedDateTimeString(lastUpdated)}
          </Text>
          <Box px={2}>
            <DateRangeTypePicker
              selectedDateRangeType={selectedDateRangeType}
              isDisabled={!isChartLoaded}
              setSelectedDateRangeType={(selectedDateRangeType) => {
                handleUpdateDateRangeTypeAndPeriod(selectedDateRangeType, selectedPeriod);
              }}
            />
          </Box>

          <Box rounded={10} bg={background} my={3} px={2}>
            <PeriodPicker
              isDisabled={!isChartLoaded}
              rangeType={selectedDateRangeType}
              selectedPeriod={selectedPeriod}
              onChangeSelectedPeriod={(selectedPeriod) =>
                handleUpdateDateRangeTypeAndPeriod(selectedDateRangeType, selectedPeriod)
              }
            />
          </Box>
        </Box>
      )}

      <Card p={[0, 0, 5]}>
        {isMobileViewport ? (
          <Heading p={[3, 3, 0]} mb={4} size={isMobileViewport ? 'md' : 'lg'}>
            Fleet Solar Production & Load Consumption
          </Heading>
        ) : (
          <Grid templateColumns={'35% 25% 40%'}>
            <Heading mb={2} size={isMobileViewport ? 'md' : 'lg'}>
              Fleet Solar Production & Load Consumption
            </Heading>

            <Center my={[2, 2, 0]} h="fit-content" w="100%">
              <Box minW={300} maxW={300} px={4}>
                <PeriodPicker
                  isDisabled={!isChartLoaded}
                  rangeType={selectedDateRangeType}
                  selectedPeriod={selectedPeriod}
                  onChangeSelectedPeriod={(selectedPeriod) =>
                    handleUpdateDateRangeTypeAndPeriod(selectedDateRangeType, selectedPeriod)
                  }
                />
              </Box>
            </Center>

            <DateRangeTypePicker
              selectedDateRangeType={selectedDateRangeType}
              isDisabled={!isChartLoaded}
              setSelectedDateRangeType={(selectedDateRangeType) => {
                handleUpdateDateRangeTypeAndPeriod(selectedDateRangeType, selectedPeriod);
              }}
            />
          </Grid>
        )}

        <Flex px={[3, 3, 0]} justify={'space-between'} my={2} w={['100%', '100%', '50%']}>
          <FleetInfoStats
            color={'customOrange.500'}
            title={'Solar Production'}
            value={Math.max(fleetData.produced.total, 0)}
            isLoaded={isChartLoaded}
          />
          <FleetInfoStats
            color={'day.500'}
            title={'Load Consumption'}
            value={Math.max(fleetData.consumed.total, 0)}
            isLoaded={isChartLoaded}
          />
        </Flex>
        {!isChartLoaded ? (
          <Skeleton h={`${FLEET_DASHBOARD_CHART_HEIGHT_PIXELS}px`} w="100%" />
        ) : (
          <UsageChart {...{ selectedDateRangeType, selectedPeriod, fleetData }} />
        )}
      </Card>
      <FleetDashboardWidgets selectedTenantId={selectedTenantId} />
    </>
  );
}
