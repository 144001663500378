/**
 * Calculates the ordinal suffix for a number.
 *
 * @param rank - The number to return the ordinal suffix for.
 */
export function getOrdinalSuffix(rank: number) {
  // Calculate rank modulo 10 and 100
  const modTen = rank % 10;
  const modHundred = rank % 100;

  if (modTen === 1 && modHundred !== 11) {
    return 'st';
  } else if (modTen === 2 && modHundred !== 12) {
    return 'nd';
  } else if (modTen === 3 && modHundred !== 13) {
    return 'rd';
  }

  return 'th';
}
