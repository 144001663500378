import React, { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectUser } from '../user/userSlice';
import BusinessDetailsModal from './BusinessDetailsModal';

export const BusinessDetailsModalWrapper = () => {
  const user = useSelector(selectUser);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // If the user is able to login with cognito but we do not have a record on db
  // Ask user for tenant details and complete signup process
  // NOTE: We get this error only when a user completes OTP verification but fails
  //       to complete signup
  useEffect(() => {
    if (user.isLoggedIn && user.loginError === 'USER_NOT_FOUND' && !user.tenant_id) onOpen();
  }, [user.loginError, user.loginError, user.tenant_id]);

  return isOpen ? <BusinessDetailsModal {...{ isOpen: isOpen, onClose: onClose }} /> : null;
};
