import * as yup from 'yup';

const manualScheduleSchema = yup.object().shape({
  schedules: yup.array().of(
    yup.object().shape({
      daysOfWeek: yup.array().of(yup.number()),
      startTime: yup.string(),
    })
  ),
});

const smartScheduleSchema = yup.object().shape({
  exportThreshold: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'This field is required.')
    .required('This field is required.'),
  runTimeHours: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'Must be greater than 0')
    .lessThan(24, 'Cannot run for more than 24 hours in a day.')
    .required('This field is required.'),
  runTimeMinutes: yup
    .number()
    .typeError('This field is required.')
    .lessThan(60, 'Must be less than 60')
    .required('This field is required.'),
});

const switchSchema = yup.object().shape({
  categoryId: yup.number(),
  label: yup.string(),
  contactorType: yup.string().oneOf(['NO', 'NC']),
  schedulingType: yup.string().oneOf(['STANDARD', 'SUPPRESSED', 'AUTO', 'TIMED']),
  schedulingConfig: yup.object().shape({
    AUTO: smartScheduleSchema.when('schedulingType', { is: 'AUTO', then: smartScheduleSchema.required() }),
    MANUAL: manualScheduleSchema.when('schedulingType', {
      is: 'TIMED',
      then: manualScheduleSchema.required(),
    }),
  }),
});

export const meterSwitchSchema = yup.object().shape({
  meterId: yup.string(),
  meterName: yup.string(),
  switches: yup.array().of(switchSchema),
});

export const switchConfigFormSchema = yup.object().shape({
  meters: yup.array().of(meterSwitchSchema),
});
