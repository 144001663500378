import React, { useContext, useEffect, useRef, useState } from 'react';
import { App } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Box, useToast } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { Appliance } from '../../../api/api-appliance';
import { InstalledDevice } from '../../../api/api-device';
import { get, patch } from '../../../api/api-helpers';
import { useAppDispatch } from '../../../app/hooks';
import CustomWebSocket from '../../../utils/websocket-wrapper';
import { setHasUnsavedChanges } from '../../wizard/wizardSlice';
import { WizardSubRouteCardWrapper } from '../../wizard/WizardSubRouteCardWrapper';
import { SiteRouteChangeContext, SiteRouteChangeContextProps } from '../Site';
import { selectSite } from '../siteSlice';
import {
  fetchAssignments,
  selectAssignments,
  setBatteries,
  setEVChargers,
  setInverters,
} from '../system-details/systemDetailsSlice';
import { WEBSOCKET_ENDPOINT, WebsocketStatusResponseV2 } from './circuit-tests/circuit-status-websocket-types';
import { checkMeterPhasing } from './meter-phasing-helpers';
import {
  addMeters,
  selectRawMeters,
  selectTestStatusV2,
  setAppliances,
  updateMeterStatusFromWebSocketV2,
} from './meterSetupSlice';
import { createWebSocket, selectWebSocketState, updateSubscribedSite } from './webSocketSlice';

export default function MeterSetup() {
  const { onMoveForward } = useContext<SiteRouteChangeContextProps>(SiteRouteChangeContext);
  const [isLoaded, testResults] = useFetchMetersAndSubscribeToWebsocket();

  return (
    <>
      {!isLoaded || isEmpty(testResults) ? (
        <MeterSetupCenteredLoader />
      ) : (
        <Box>
          <Outlet context={{ onMoveForward } satisfies SiteRouteChangeContextProps} />
        </Box>
      )}
    </>
  );
}

function MeterSetupCenteredLoader() {
  const { pathname } = useLocation();
  const isOnMeterSetupSubRoute = /\/meter_setup\/configure\//.test(pathname);

  // Wizard Stepper is hidden on meter setup sub routes, so we need to override padding to the top of the wizard
  const wizardCardProps = isOnMeterSetupSubRoute
    ? {
        containerProps: { pt: 0, mt: 0 },
        p: [0],
      }
    : undefined;

  return (
    <WizardSubRouteCardWrapper {...wizardCardProps}>
      <CenteredLoader minH={300} />
    </WizardSubRouteCardWrapper>
  );
}

/**
 * Custom hook which fetches meters for the site, then creates (or uses an existing) websocket instance, and
 * subscribes to the meters fetched in the first phase.
 *
 * @returns Tuple containing indices:
 *            1 - Boolean specifying whether all data has loaded and the page is ready for user interaction.
 *            2 - All test results.
 */
function useFetchMetersAndSubscribeToWebsocket(): [boolean, WebsocketStatusResponseV2] {
  const [isLoaded, setLoaded] = useState(false);
  const [hasFetchedOtherDevices, setHasFetchedOtherDevices] = useState(false);
  const dispatch = useAppDispatch();
  const site = useSelector(selectSite);
  const assignments = useSelector(selectAssignments);
  const toast = useToast();
  const { webSocket, subscribedSiteId } = useSelector(selectWebSocketState);
  const testResults = useSelector(selectTestStatusV2);
  const meters = useSelector(selectRawMeters);
  const listenerRef = useRef<PluginListenerHandle | null>(null);

  useEffect(() => {
    async function fetchAPI() {
      dispatch(setHasUnsavedChanges(false));

      try {
        const meterDevices = await get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/meters`);
        if (meterDevices.length) {
          // Get all inverters, batteries, EV chargers, appliances and meter data
          const [inverters, batteries, evChargers, appliances, ...wattwatchersMeterData] = await Promise.all([
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/inverters`),
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/batteries`),
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/ev_chargers`),
            get<Appliance[]>(`/fleet/sites/${site.clipsal_solar_id}/appliances`),
            ...meterDevices.map((meter) =>
              get<WattwatchersMeter>(`/fleet/wattwatchers/installed_meters/${meter.row_id}`)
            ),
          ]);
          setHasFetchedOtherDevices(true);

          // update phasing in meters if required as it needs to match site phasing
          const { isCorrectlyPhased, updatedMeters } = checkMeterPhasing(site.electrical_config, wattwatchersMeterData);
          if (!isCorrectlyPhased) {
            // Update each meter respectively
            const updatedMeterData = await Promise.all(
              updatedMeters.map<Promise<WattwatchersMeter>>((m) =>
                patch(`/fleet/wattwatchers/installed_meters/${m.installed_device_id}`, m)
              )
            );
            toast({
              title: `Meter voltage references updated!`,
              description: 'Circuit voltage references has been updated as per site electrical configuration.',
              status: 'info',
              isClosable: true,
            });
            dispatch(addMeters(updatedMeterData));
          } else {
            dispatch(addMeters(wattwatchersMeterData));
          }

          // update data in redux
          dispatch(setAppliances(appliances));
          dispatch(setInverters(inverters));
          dispatch(setBatteries(batteries));
          dispatch(setEVChargers(evChargers));

          // Cache circuit type options (aka assignments or monitor types)
          if (!assignments.length) await dispatch(fetchAssignments());

          // fetch inverters and batteries if not fetched yet
        } else if (!hasFetchedOtherDevices) {
          // Get all inverters, batteries and EV chargers
          const [inverters, batteries, evChargers] = await Promise.all([
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/inverters`),
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/batteries`),
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/ev_chargers`),
          ]);
          dispatch(setInverters(inverters));
          dispatch(setBatteries(batteries));
          dispatch(setEVChargers(evChargers));
          setHasFetchedOtherDevices(true);
        }
      } catch (e) {
        console.error(e);
        toast({
          title: 'Unable to load meter data.',
          description: 'Note that Solar Analytics meters will not work with this tool.',
          status: 'error',
          isClosable: true,
        });
      }
      setLoaded(true);
    }

    // As this component is wrapped with React Transition group, it is cloned in each route changes as
    // it is required for sliding animation. Hence this is rerendered on every route changes.
    // Thus we have to use redux values to determine if we need to fetch API
    if (!isLoaded && !Object.keys(meters).length) {
      fetchAPI();
    } else {
      if (Object.keys(meters).length && !isLoaded) setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, toast, site.clipsal_solar_id]);

  useEffect(() => {
    async function connectToWebsocket() {
      /**
       * Handler invoked when a message is received. Updates Redux according to the message.
       *
       * @param message - JSON string formatted message.
       */
      function onMessage(this: CustomWebSocket, message: MessageEvent<string>) {
        try {
          const status: WebsocketStatusResponseV2 = JSON.parse(message.data);
          dispatch(updateMeterStatusFromWebSocketV2(status));
        } catch (e) {}
      }

      /**
       * Handler invoked when the websocket connection is opened.
       */
      function onOpen(this: CustomWebSocket) {
        this.sendJsonMessage({ action: 'subscribeSite', clipsal_solar_id: site.clipsal_solar_id });
      }

      // Build the websocket if it doesn't exist
      if (!webSocket) {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const webSocketOptions = {
          url: `${WEBSOCKET_ENDPOINT}?token=${token}`,
          onMessage,
          onOpen,
        };

        // Await the websocket connection
        await dispatch(createWebSocket(webSocketOptions));
      } else if (subscribedSiteId !== site.clipsal_solar_id) {
        dispatch(updateSubscribedSite(site.clipsal_solar_id));
        // The websocket exists but is not subscribed to the site currently being commissioned -- update that.
        webSocket?.sendJsonMessage({ action: 'subscribeSite', clipsal_solar_id: site.clipsal_solar_id });
      }
    }

    connectToWebsocket();
  }, [dispatch, webSocket, subscribedSiteId, site.clipsal_solar_id]);

  useEffect(() => {
    const isNativeDevice = Capacitor.isNativePlatform();

    if (!isNativeDevice) return;

    // Remove the old listener
    listenerRef?.current?.remove();

    function onMessage(this: CustomWebSocket, message: MessageEvent<string>) {
      try {
        const status: WebsocketStatusResponseV2 = JSON.parse(message.data);
        dispatch(updateMeterStatusFromWebSocketV2(status));
      } catch (e) {}
    }

    function onOpen(this: CustomWebSocket) {
      this.sendJsonMessage({ action: 'subscribeSite', clipsal_solar_id: site.clipsal_solar_id });
    }

    listenerRef.current = App.addListener('appStateChange', async ({ isActive }) => {
      if (webSocket && !webSocket.isConnected() && isActive) {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const webSocketOptions = {
          url: `${WEBSOCKET_ENDPOINT}?token=${token}`,
          onMessage,
          onOpen,
        };

        // Await the websocket connection
        await dispatch(createWebSocket(webSocketOptions));
      }
    });

    return () => {
      // Remove the old listener
      listenerRef?.current?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isLoaded, testResults];
}
