import { Tenant, TenantToSave } from '../../../api/api-tenant';

export type BusinessData = {
  name: string;
  address: string;
  size: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
};

export const mapBusinessApiDataToForm = (tenant: Tenant): BusinessData => {
  return {
    name: tenant.tenant_name,
    size: '',
    address: tenant.address || '',
    city: tenant.city || '',
    state: tenant.state || '',
    postCode: tenant.zipcode || '',
    country: tenant.country || '',
  };
};

export const mapBusinessFormDataToAPI = (tenant: BusinessData): TenantToSave => {
  return {
    tenant_name: tenant.name,
    address: tenant.address,
    city: tenant.city,
    state: tenant.state,
    country: tenant.country,
    zipcode: tenant.postCode,
  };
};
