import React from 'react';
import { Box, Center, Flex, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import Card from 'clipsal-cortex-ui/src/components/card/Card';

import { COMPONENT_MIN_HEIGHT } from '../../../common/constants';
import ProductFeatureCarousel from '../ProductFeatureCarousel';

function Signup() {
  const isDesktopViewport = useBreakpointValue(
    {
      base: false,
      lg: true,
    },
    { ssr: false }
  );
  const backgroundColor = useColorModeValue('white', 'customBodyBackground.800');

  return (
    <Box
      position={'absolute'}
      top={'env(safe-area-inset-top)'}
      left={0}
      w="100vw"
      bg={backgroundColor}
      h={COMPONENT_MIN_HEIGHT}
      px={[2, 2, 0]}
    >
      {isDesktopViewport ? (
        <Flex boxSize={'full'}>
          <Box minW={'50%'}>
            <ProductFeatureCarousel />
          </Box>
          <Box minW={'50%'} maxW={'50%'} overflowX="hidden">
            <Center h={'100%'}>
              <Card
                w={'100%'}
                minH={600}
                maxH={COMPONENT_MIN_HEIGHT}
                overflowY="auto"
                maxW="450px"
                rounded={10}
                padding={8}
                shadow="0px 4px 4px rgba(0, 0, 0, 0.1), 0px -1px 4px rgba(0, 0, 0, 0.1)"
              >
                <Outlet />
              </Card>
            </Center>
          </Box>
        </Flex>
      ) : (
        <Box px={4}>
          <Outlet />
        </Box>
      )}
    </Box>
  );
}

export default Signup;
