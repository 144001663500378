import { VoltageReference } from 'clipsal-cortex-types/src/api/api-ww-meter';

import packageJson from '../../package.json';
import { ElectricalConfiguration, MeteringConfiguration } from '../api/api-site';
import { ENV_TYPE_SHORTHAND } from '../env-type';
import { REGION_SHORTHAND } from '../region-type';
import { SelectOption } from './types/types';

export const SUPPORT_EMAIL = 'fleet@clipsalcortex.com';
export const SUPPORT_PHONE = '+61283109220';
export const APPLE_APP_STORE_URL = 'https://apps.apple.com/us/app/clipsal-solar-pulse-installer/id1583705822';
export const ANDROID_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.clipsalsolar.onboarding';
export const PRIVACY_POLICY_URL = 'https://clipsalsolar.wpengine.com/privacy-policy/';
export const CLIPSAL_TENANT_ID = 1;
export const MACQUARIE_TENANT_ID = 28;
export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const COMPONENT_MIN_HEIGHT = 'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))';
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ALLOWED_COUNTRIES = ['Australia', 'United States', 'New Zealand'];

export const ALLOWED_COUNTRIES_SELECT_OPTIONS = ALLOWED_COUNTRIES.map((c) => ({ label: c, value: c }));

export const MIN_PASSWORD_LENGTH = 8;

export const COMMON_CHART_DATETIME_FORMATS = {
  second: '%l:%M:%S %P',
  minute: '%l:%M %P',
  hour: '%l %P',
  day: '%e. %b',
  week: '%e. %b',
  month: "%b '%y",
  year: '%Y',
};

export const BOTTOM_NAV_HEIGHT = 'calc(58px + env(safe-area-inset-bottom))';

// Select option arrays
export const VOLTAGE_CONFIGURATIONS: SelectOption<ElectricalConfiguration>[] = [
  { value: '1ph', label: '1 Phase' },
  { value: '2ph', label: '2 Phase' },
  { value: '3ph', label: '3 Phase' },
];

export const VOLTAGE_REFERENCE_TYPES: SelectOption<VoltageReference>[] = [
  { value: 'P1', label: 'Phase A' },
  { value: 'P2', label: 'Phase B' },
  { value: 'P3', label: 'Phase C' },
];

export const METERING_CONFIGURATIONS: SelectOption<MeteringConfiguration>[] = [
  {
    label: 'Gross Metered',
    value: 'GROSS',
  },
  {
    label: 'Net Metered',
    value: 'NET',
  },
];

export const POLLING_INTERVAL_MS = 5000;

export const CONTROLLED_LOAD_ASSIGNMENT_TYPES = ['load_hot_water', 'load_underfloor_heating'];

// UI constants
export const SIDENAV_WIDTH = '250px';

// Highcharts Colors
export const COLOURS = [
  '#2ab5ba',
  '#ff0000',
  '#e5a309',
  '#00bf03',
  '#f800df',
  '#0032e0',
  '#a567eb',
  '#b3f591',
  '#cb5f2d',
];

export const TOP_NAV_HEIGHT = 48;
export const TOP_NAV_SPACING_AFFORDANCE = `${TOP_NAV_HEIGHT}px`;

export const APP_VERSION = packageJson.version;

// need to add cortexEnvType as capacitor builds are always in production mode
export const IS_PRODUCTION =
  import.meta.env['MODE'] === 'production' && localStorage.getItem('cortexEnvType') !== 'STAGING';
export const IS_RUNNING_CYPRESS_TESTS = (window as any).Cypress;
export const IS_RUNNING_HEADLESS =
  /HeadlessChrome/.test(window.navigator.userAgent) || /headless/i.test(navigator.appVersion);

export const IS_NOT_DEVELOPMENT = import.meta.env.MODE !== 'development';

export const IS_PRODUCTION_BUILD = IS_PRODUCTION && !IS_RUNNING_CYPRESS_TESTS && !IS_RUNNING_HEADLESS;

export const ENDPOINT =
  localStorage.getItem('customEndpoint') ||
  (import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_ENDPOINT`] as string);
export const USER_POOL_ID =
  localStorage.getItem('customCognitoUserPoolId') ||
  (import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_COGNITO_USER_POOL_ID`] as string);
export const CLIENT_ID =
  localStorage.getItem('customCognitoUserPoolClientId') ||
  (import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_CLIENT_ID`] as string);

export const WIZARD_HEIGHT = 56;

export const CORTEX_METER_TROUBLESHOOTING_URL =
  'https://support.clipsalcortex.com/hc/en-us/articles/4473126048143-Troubleshooting';
