import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Image,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { selectSite } from '../../siteSlice';
import { selectRawMeters } from '../meterSetupSlice';
import { CircuitTestStatus, DISPLAYED_VOLTAGE_REF_TYPES, TEST_STATUS_TO_CONFIG, TestTypeV2 } from './test-types';

type CircuitInformationBottomDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  circuit: ChannelData;
  testType: TestTypeV2;
  status: CircuitTestStatus;
};

const TEST_TYPE_TO_TITLE: Record<TestTypeV2, string> = {
  [TestTypeV2.PowerFactor]: 'Power Factor Test',
  [TestTypeV2.Polarity]: 'Polarity Test',
};

const TEST_STATUS_TO_TITLE: Record<CircuitTestStatus, string> = {
  [CircuitTestStatus.Uninitialized]: 'Not Yet Run',
  [CircuitTestStatus.InProgress]: 'In Progress',
  [CircuitTestStatus.Skipped]: 'Skipped',
  [CircuitTestStatus.Success]: 'Passed',
  [CircuitTestStatus.Undetermined]: 'Undetermined',
  [CircuitTestStatus.Failed]: 'Failed',
  [CircuitTestStatus.Retest]: 'Requires Retest',
  [CircuitTestStatus.NeedConfirmation]: 'Needs Confirmation',
};

export function CircuitInformationBottomDrawer({
  isOpen,
  onClose,
  circuit,
  status,
  testType,
}: CircuitInformationBottomDrawerProps) {
  const [route, setRoute] = useState<'main' | 'furtherInfo'>('main');
  const rawMeters = useSelector(selectRawMeters);
  const [meterId, circuitNumber] = circuit.ww_circuit_id.split('_');
  const meterForCircuit = rawMeters[meterId];
  const site = useSelector(selectSite);
  const phaseSelectOptions = DISPLAYED_VOLTAGE_REF_TYPES[site.electrical_config];
  const visiblePhaseValue = phaseSelectOptions.find((option) => option.value === circuit.voltage_reference);

  function getCircuitName() {
    const meterName = meterForCircuit?.label ?? `Meter ${Object.values(rawMeters).indexOf(meterForCircuit) + 1}`;
    return `${circuit.circuit_name} (${visiblePhaseValue?.label}) (${meterName}, ${circuitNumber})`;
  }

  function getMessage() {
    if (testType === TestTypeV2.Polarity) {
      const testStatusToText: Record<CircuitTestStatus, JSX.Element> = {
        [CircuitTestStatus.Failed]: (
          <>
            <Text my={4} textAlign="center">
              Incorrect polarity detected on {getCircuitName()}.
            </Text>
            <Text>Try reversing the polarity of the CT in the app and rerun the test.</Text>
          </>
        ),
        [CircuitTestStatus.Undetermined]: (
          <Text textAlign="center">
            The current is not quite high enough to test for {getCircuitName()}. Please check the CT connection and
            ensure there is load on the circuit and re-run the test.
          </Text>
        ),
        [CircuitTestStatus.Success]: <Text textAlign="center">Correct polarity detected on {getCircuitName()}.</Text>,
        [CircuitTestStatus.Skipped]: (
          <Text textAlign="center">
            {TEST_TYPE_TO_TITLE[testType]} skipped on {getCircuitName()}. Any incorrect data may cause data
            inconsistencies in the Cortex app. You can run the {TEST_TYPE_TO_TITLE[testType]} again.
          </Text>
        ),
        [CircuitTestStatus.Retest]: (
          <Text textAlign="center">You need to run the test again to retest the polarity.</Text>
        ),
        [CircuitTestStatus.InProgress]: <Text textAlign="center">This circuit's test is currently in progress.</Text>,
        [CircuitTestStatus.Uninitialized]: (
          <Text textAlign="center">
            This test has not yet been run. You can run the test by clicking the black button at the bottom of the page.
          </Text>
        ),
        [CircuitTestStatus.NeedConfirmation]: (
          <Text textAlign="center">This test needs confirmation for polarity.</Text>
        ),
      };

      return testStatusToText[status];
    } else {
      const testStatusToText: Record<CircuitTestStatus, JSX.Element> = {
        [CircuitTestStatus.Failed]: (
          <>
            <Text my={4} textAlign="center">
              Incorrect power factor detected on {getCircuitName()}.
            </Text>
            <Text>Please change the phasing of the CT using the app and re-run the test.</Text>
          </>
        ),
        [CircuitTestStatus.Undetermined]: (
          <Text textAlign="center">
            The current is not quite high enough to test for {getCircuitName()}. Please check the CT connection and
            ensure there is load on the circuit and re-run the test.
          </Text>
        ),
        [CircuitTestStatus.Success]: (
          <Text textAlign="center">Correct power factor detected on {getCircuitName()}.</Text>
        ),
        [CircuitTestStatus.Skipped]: (
          <Text textAlign="center">
            {TEST_TYPE_TO_TITLE[testType]} skipped on {getCircuitName()}. Any incorrect data may cause data
            inconsistencies in the Cortex app. You can run the {TEST_TYPE_TO_TITLE[testType]} again.
          </Text>
        ),
        [CircuitTestStatus.Retest]: (
          <Text textAlign="center">You need to run the test again to retest the power factor.</Text>
        ),
        [CircuitTestStatus.InProgress]: <Text textAlign="center">This circuit's test is currently in progress.</Text>,
        [CircuitTestStatus.Uninitialized]: (
          <Text textAlign="center">
            This test has not yet been run. You can run the test by clicking the black button at the bottom of the page.
          </Text>
        ),
        [CircuitTestStatus.NeedConfirmation]: <Text textAlign="center">This test needs confirmation for phasing.</Text>,
      };

      return testStatusToText[status];
    }
  }

  function getFurtherInfoMessage() {
    if (testType === TestTypeV2.Polarity) {
      return (
        <>
          <UnorderedList>
            <ListItem>The arrow direction on the CT is incorrect</ListItem>
            <ListItem>The CT wiring (pink & white cables) is incorrect</ListItem>
          </UnorderedList>

          <Text>Note: Incorrect phasing can also result in an incorrect polarity (3ph systems only).</Text>
        </>
      );
    } else {
      return (
        <UnorderedList>
          <ListItem>The configured phase of the circuit does not match the physical phasing of the circuit</ListItem>
          <ListItem>Voltage Inputs may not be correctly connected to the Cortex meter</ListItem>
          <ListItem>The CT is not positioned on the correct circuit and the The CT clamp is loose</ListItem>
        </UnorderedList>
      );
    }
  }

  function getTitle() {
    if (route === 'main') return `${TEST_TYPE_TO_TITLE[testType]} ${TEST_STATUS_TO_TITLE[status]}`;
    else return `Common reasons for ${TEST_TYPE_TO_TITLE[testType]} failure`;
  }

  const routeToContent: Record<'main' | 'furtherInfo', JSX.Element> = {
    main: (
      <>
        {getMessage()}
        {status === CircuitTestStatus.Failed && (
          <Center mt={5}>
            <Button onClick={() => setRoute('furtherInfo')} colorScheme={'customBlue'} variant={'ghost'}>
              Common reasons for failure
            </Button>
          </Center>
        )}
      </>
    ),
    furtherInfo: <>{getFurtherInfoMessage()}</>,
  };

  return (
    <Drawer placement={'bottom'} isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent data-testid="circuit-test-circuit-drawer-container">
        <DrawerCloseButton data-testid="circuit-test-circuit-drawer-close-btn" />
        <Center py={3}>
          {status !== CircuitTestStatus.InProgress ? (
            <Image
              data-testid="circuit-test-circuit-drawer-icon"
              w={['30%', '30%', '10%']}
              src={TEST_STATUS_TO_CONFIG[status].icon}
              alt={TEST_STATUS_TO_CONFIG[status].title}
            />
          ) : (
            <Spinner color={'primaryBranding.500'} size={'xl'} />
          )}
        </Center>

        <Heading data-testid="circuit-test-circuit-drawer-title" mt={3} textAlign={'center'} size={'lg'}>
          {getTitle()}
        </Heading>

        <Box m={2}>{routeToContent[route]}</Box>
      </DrawerContent>
    </Drawer>
  );
}
