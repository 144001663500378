import React, { useEffect } from 'react';
import { ArrowForwardIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';
import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import MeterCTSelect from '../../../../common/components/MeterCTSelect';
import { SIDENAV_WIDTH } from '../../../../common/constants';
import { selectRawMeters } from '../meterSetupSlice';
import { CTConfigFormData } from './ct-configuration-form-types';
import { COMMON_NEXT_BUTTON_PROPS } from './ct-configuration-helpers';
import { TabComponentProps } from './CTConfigurationContainer';
import FieldIconWrapper from './FieldIconWrapper';
import SelectedCircuitsCard, { EmptySelectedCircuitsCard } from './SelectedCircuitsCard';
import { useReservedCTsForBattery } from './useReservedCTsForBattery';

export default function BatteryCTConfiguration(props: TabComponentProps) {
  const { control, errors, updateCTFunctions, isUpdatingCTs, circuitState } = props;

  const { fields } = useFieldArray({
    name: `battery` as const,
    control,
  });

  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  return (
    <Flex direction="column" data-testid="battery-tab-panel">
      <Accordion defaultIndex={0} allowToggle>
        {fields.map((field, index) => {
          const errorsAtIndex = errors?.battery?.[index];
          return (
            <AccordionItem key={`${field.id}-${index}`}>
              <AccordionButton role={'button'} as={Box}>
                <Flex w={'100%'} justify={'space-between'} align={'center'}>
                  <Flex align="center">
                    <Heading size="sm" fontWeight="500">
                      Battery {index + 1}
                    </Heading>
                    {errorsAtIndex && <WarningIcon color={'red.500'} w={5} h={5} ml={2} />}
                  </Flex>
                  <Flex align={'center'}>
                    <AccordionIcon />
                  </Flex>
                </Flex>
              </AccordionButton>

              <AccordionPanel pb={4}>
                {errorsAtIndex && (
                  <Text color="red.500" mt={2}>
                    {(errorsAtIndex?.circuits as any)?.message}
                  </Text>
                )}
                <Text color="dusk.100" mb={8} mt={2}>
                  Select which CT's are measuring battery
                </Text>
                <NestedBatteryCTConfigurationFieldArray field={field} batteryApplianceIndex={index} {...props} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <Flex
        position={'fixed'}
        bottom={16}
        left={0}
        zIndex={99}
        width="100%"
        justifyContent={'center'}
        paddingLeft={isMobileViewport ? 0 : SIDENAV_WIDTH}
        pointerEvents="none"
      >
        <Flex width={isMobileViewport ? '100%' : '60%'}>
          <Button
            data-testid="submit-battery-button"
            aria-label="Submit Battery"
            rightIcon={isUpdatingCTs ? <Spinner size="sm" /> : <ArrowForwardIcon />}
            disabled={isUpdatingCTs || !!circuitState.pendingMeterIds.length}
            {...COMMON_NEXT_BUTTON_PROPS}
            onClick={() => updateCTFunctions['battery']()}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export function NestedBatteryCTConfigurationFieldArray({
  control,
  batteryApplianceIndex,
  field,
  register,
  onCircuitChange,
  circuitState,
  errors,
  trigger,
}: TabComponentProps & {
  batteryApplianceIndex: number;
  field: FieldArrayWithId<CTConfigFormData, 'battery', 'id'>;
}) {
  const rawMeters = useSelector(selectRawMeters);
  const { values } = circuitState;
  const { fields, append, remove } = useFieldArray({
    name: `battery.${batteryApplianceIndex}.circuits` as 'battery.0.circuits',
    control,
  });
  const toast = useToast();
  const errorsAtIndex = errors?.battery?.[batteryApplianceIndex];

  const Icon = CIRCUIT_TYPE_TO_ICON['battery_storage'];
  const FieldIcon = (
    <FieldIconWrapper>
      <Icon w={6} h={6} />
    </FieldIconWrapper>
  );

  useEffect(() => {
    // show toast errors as the form can be lengthier
    // and will make it harder to spot error messages
    if (errorsAtIndex?.circuits) {
      toast({
        title: `Missing CT in Battery ${batteryApplianceIndex + 1}`,
        description: (errorsAtIndex?.circuits as any)?.message + ` ${batteryApplianceIndex + 1}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [errorsAtIndex, toast, batteryApplianceIndex]);

  const reservedCts = useReservedCTsForBattery(control, batteryApplianceIndex);

  function handleSelectCircuit(circuitData: ChannelData) {
    // returns index if found otherwise returns -1
    const indexOfCTField = fields.findIndex((selectedCT) => circuitData.ww_circuit_id === selectedCT.ww_circuit_id);

    if (indexOfCTField >= 0) {
      remove(indexOfCTField);
    } else {
      const channel = {
        ...circuitData,
        circuit_name: 'Battery Storage',
        clipsal_monitors: 'battery storage',
      };
      append(channel, { shouldFocus: false });
    }

    // revalidate form if there is any errors for battery tab
    if (errors?.battery) trigger(`battery.${batteryApplianceIndex}.circuits` as 'battery.0.circuits');
  }

  return (
    <Box mt={4}>
      {Object.values(rawMeters).map((meter, idx) => {
        return (
          <MeterCTSelect
            meterIndex={idx}
            key={idx + field.id}
            meter={meter}
            onCTClick={handleSelectCircuit}
            selectedCTs={fields}
            reservedCTs={reservedCts}
          />
        );
      })}

      <Box mb={10}>
        {fields.map((field, idx) => {
          const switchValues = values.find((value) => value.circuitId === field.ww_circuit_id);
          if (!switchValues) throw new Error(`Unable to find switch value for circuit id ${field.ww_circuit_id}`);

          return (
            <SelectedCircuitsCard
              key={field.ww_circuit_id}
              source={`battery.${batteryApplianceIndex}.circuits` as `battery.${number}.circuits`}
              field={field}
              index={idx}
              register={register}
              onDismiss={() => remove(idx)}
              iconComponent={FieldIcon}
              onCircuitChange={onCircuitChange}
              polarityValue={switchValues.polarity}
              voltageRefValue={switchValues.voltageReference}
              ctRating={switchValues.ctRating}
            />
          );
        })}
        {!fields.length && <EmptySelectedCircuitsCard iconComponent={FieldIcon} />}
      </Box>
    </Box>
  );
}
