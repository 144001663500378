import { TIMEZONES } from 'clipsal-cortex-utils/src/constants/timezone-states';

import { LongPowerDataType, ShortPowerDataType } from './meter-config-types';

const ALLOWED_TZS = [
  'Australia/Sydney',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Melbourne',
  'Australia/Brisbane',
  'Australia/Perth',
  'America/Los_Angeles',
];

export const FORMATTED_TIMEZONES = TIMEZONES.filter((timezone) => ALLOWED_TZS.includes(timezone));

export const FORMATTED_TIMEZONES_FOR_SELECT = FORMATTED_TIMEZONES.map((timezone) => ({
  value: timezone,
  label: timezone,
}));

export const DATA_TYPE_TO_VALUE_SUFFIX = {
  pRealKw: 'kW',
  pReactiveKw: 'kW',
  powerFactor: '',
  current: 'Amps',
  iRMS: 'Amps',
  iRMSMin: 'Amps',
  iRMSMax: 'Amps',
  vRMS: 'Volts',
  vRMSMin: 'Volts',
  vRMSMax: 'Volts',
};

export const SHORT_DATA_TYPE_TO_TITLE_MAP: Record<ShortPowerDataType, string> = {
  pRealKw: 'Power (Real)',
  pReactiveKw: 'Power (Reactive)',
  iRMS: 'Current',
  vRMS: 'Voltage',
  powerFactor: 'Power Factor',
};

export const LONG_DATA_TYPE_TO_TITLE_MAP: Record<LongPowerDataType, string> = {
  pRealKw: 'Power (Real)',
  pReactiveKw: 'Power (Reactive)',
  vRMSMax: 'Voltage (Max)',
  vRMSMin: 'Voltage (Min)',
  iRMSMax: 'Current (Max)',
  iRMSMin: 'Current (Min)',
  powerFactor: 'Power Factor',
};
