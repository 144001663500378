/**
 * Core source of truth for experimental features in the app.
 */
export enum ExperimentalFeature {
  // @TODO: remove placeholder when we have a new experimental feature
  // without this typescript breaks. Maybe we can use new implementation later
  // we cannot hide experimental modal as custom endpoint still exists in it
  Placeholder = 'enablePlaceHolder',
}

/**
 * Retrieves an experimental feature flag value from local storage, returning a boolean value.
 *
 * @param flagName - The flag name in question.
 * @returns Whether the flag is enabled.
 */
export function getFlagValue(flagName: ExperimentalFeature): boolean {
  return localStorage.getItem(flagName) === 'true';
}

/**
 * Sets an experimental feature flag value in local storage. See the `ExperimentalFeatures` component for further
 * details.
 *
 * @param flagName - The string name of the flag to set in local storage.
 * @param value - The boolean value to set in local storage. Saves as a string.
 */
export function setFlagValue(flagName: ExperimentalFeature, value: boolean): void {
  localStorage.setItem(flagName, value.toString());
}
