import React, { useEffect, useRef, useState } from 'react';
import { QuestionIcon } from '@chakra-ui/icons';
import { Box, Button, Center, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { useAppDispatch } from '../../../../app/hooks';
import CustomButton from '../../../../common/components/CustomButton';
import InfoBottomDrawer from '../../../../common/components/InfoBottomDrawer';
import { COMPONENT_MIN_HEIGHT } from '../../../../common/constants';
import { MeterSetupPollingSubRouteProps } from '../MeterSetupPollingWrapper';
import {
  selectAllCircuits,
  selectBackupCircuitAppliance,
  selectHybridAppliance,
  selectPolarityConfirmationStatus,
  selectRawMeters,
  selectTestStatusV2,
  setHybridPolarityConfirmation,
} from '../meterSetupSlice';
import SubRouteTopNav from '../SubRouteTopNav';
import { CircuitInformationBottomDrawer } from './CircuitInformationBottomDrawer';
import CircuitStatusCard from './CircuitStatusCard';
import CircuitTestChart from './CircuitTestChart';
import GridHybridInfoDrawerButton from './GridHybridInfoDrawerButton';
import SkipTestBottomDrawer from './SkipTestBottomDrawer';
import { CircuitTestStatus, TestTypeV2 } from './test-types';
import useMeterTestHandlers, { getTestStatusBasedOnPolarityConfirmation } from './use-meter-test-handlers';

export default function HybridTest() {
  const { onCircuitChange, circuitState } = useOutletContext<MeterSetupPollingSubRouteProps>();
  const navigate = useNavigate();
  const hybridAppliance = useSelector(selectHybridAppliance);
  const backupCircuitAppliance = useSelector(selectBackupCircuitAppliance);
  const allCircuits = useSelector(selectAllCircuits);
  const testStatus = useSelector(selectTestStatusV2);
  // stores prev state of test result
  const testRef = useRef<CircuitTestStatus>(testStatus?.hybrid?.status || CircuitTestStatus.Uninitialized);
  const {
    isOpen: isPolarityConfirmationDrawerOpen,
    onOpen: OnOpenPolarityConfirmationDrawer,
    onClose: OnClosePolarityConfirmationDrawer,
  } = useDisclosure();
  const toast = useToast();
  const polarityConfirmationStatus = useSelector(selectPolarityConfirmationStatus);
  const dispatch = useAppDispatch();
  const { buttonTextColor, secondaryButtonTextColor } = useColorModeValue(
    { buttonTextColor: 'white', secondaryButtonTextColor: 'customBlack.500' },
    { buttonTextColor: 'black', secondaryButtonTextColor: 'dusk100.200' }
  );
  useEffect(() => {
    const hybridTestStatus = testStatus?.hybrid?.status || CircuitTestStatus.Uninitialized;

    // If current test is in progress or was previously in progress, update testRef
    // This ensures we only show confirm polarity drawer when a test is ran
    if (hybridTestStatus === CircuitTestStatus.InProgress || testRef.current === CircuitTestStatus.InProgress) {
      testRef.current = hybridTestStatus;

      // If current test passed or failed show confirm polarity
      if (hybridTestStatus !== CircuitTestStatus.InProgress) {
        //scroll up so that graph is visible
        window.scrollTo(0, 250);
        OnOpenPolarityConfirmationDrawer();
      } else {
        dispatch(setHybridPolarityConfirmation(false));
      }
    }
  }, [testStatus, OnOpenPolarityConfirmationDrawer, dispatch]);

  const { isOpen: isSkipDialogOpen, onOpen: onOpenSkipDialog, onClose: onCloseSkipDialog } = useDisclosure();
  const {
    isOpen: isCircuitInfoDrawerOpen,
    onOpen: onOpenCircuitInfoDrawer,
    onClose: onCloseCircuitInfoDrawer,
  } = useDisclosure();
  const hybridAndBackupCircuits = allCircuits.filter(
    (c) =>
      hybridAppliance?.circuits.includes(c.clipsal_circuit_id) ||
      backupCircuitAppliance?.circuits.includes(c.clipsal_circuit_id)
  );
  const [handleStartTest, handleSkipTest] = useMeterTestHandlers(hybridAndBackupCircuits, 'hybrid');
  const rawMeters = useSelector(selectRawMeters);
  const [circuitWithCurrentlyOpenDrawer, setCircuitWithCurrentlyOpenDrawer] = useState<{
    circuit: ChannelData | null;
    testType: TestTypeV2 | null;
  }>({ circuit: null, testType: null });

  if (!hybridAndBackupCircuits.length) {
    return <Box>There is no hybrid or backup appliance. You should not be able to get to this page.</Box>;
  }

  function getStatusForOpenCircuit() {
    const { circuit, testType } = circuitWithCurrentlyOpenDrawer;
    if (!circuit || !testType) throw new Error(`Tried to open circuit status drawer without values in state.`);

    const result = testStatus.hybrid?.[testType].circuits.find((c) => c.circuit_id === circuit.ww_circuit_id);

    if (!result) return CircuitTestStatus.Uninitialized;

    return testType === TestTypeV2.PowerFactor
      ? (result.power_factor_test_status as CircuitTestStatus)
      : (result.polarity_test_status as CircuitTestStatus);
  }

  return (
    <Box pb={hybridAndBackupCircuits.length > 1 ? 0 : 24} minHeight={COMPONENT_MIN_HEIGHT}>
      <SubRouteTopNav onGoBack={() => navigate(-1)} title="Hybrid CT Test">
        {!!backupCircuitAppliance?.circuits?.length ? <GridHybridInfoDrawerButton /> : <></>}
      </SubRouteTopNav>

      <CircuitTestChart source="hybrid" testType={TestTypeV2.Polarity} />

      <Center flexDirection="column">
        {hybridAndBackupCircuits.map((c, i) => {
          // Get the status for each test type for this circuit.
          // Note that if it is not found, the test is considered uninitialized.
          const circuitPolarityResult = testStatus.hybrid?.[TestTypeV2.Polarity].circuits.find(
            (circuitTestResult) => circuitTestResult.circuit_id === c.ww_circuit_id
          );
          const circuitPowerFactorResult = testStatus.hybrid?.[TestTypeV2.PowerFactor].circuits.find(
            (circuitTestResult) => circuitTestResult.circuit_id === c.ww_circuit_id
          );

          const circuitMeterId = c.ww_circuit_id.split('_')[0];
          const meterForCircuit = rawMeters[circuitMeterId];
          const meterNameForCircuit =
            meterForCircuit?.label ?? `Meter ${Object.values(rawMeters).indexOf(meterForCircuit) + 1}`;

          const circuitPolarityPhaseValues = circuitState.values.find(({ circuitId }) => circuitId === c.ww_circuit_id);
          if (!circuitPolarityPhaseValues)
            throw new Error(`Unable to find circuit phase and polarity value for ID ${c.ww_circuit_id}`);

          const { polarity, voltageReference, ctRating } = circuitPolarityPhaseValues;
          let powerFactorResult = circuitPowerFactorResult?.power_factor_test_status ?? CircuitTestStatus.Uninitialized;
          let polarityResult = circuitPolarityResult?.polarity_test_status ?? CircuitTestStatus.Uninitialized;

          // If the top level is skipped, the circuits are skipped too.
          if (testStatus.hybrid?.status === CircuitTestStatus.Skipped) {
            powerFactorResult = CircuitTestStatus.Skipped;
            polarityResult = CircuitTestStatus.Skipped;
          }

          // check if polarity needs confirmation
          polarityResult = getTestStatusBasedOnPolarityConfirmation(
            polarityConfirmationStatus['hybrid'],
            polarityResult
          );

          return (
            <Box w={['100%', '100%', '100%', '70%']} px={5} py={2} key={c.ww_circuit_id}>
              <CircuitStatusCard
                onOpenDrawer={(circuit, testType) => {
                  if (polarityResult === CircuitTestStatus.NeedConfirmation) {
                    OnOpenPolarityConfirmationDrawer();
                  } else {
                    setCircuitWithCurrentlyOpenDrawer({ circuit, testType });
                    onOpenCircuitInfoDrawer();
                  }
                }}
                key={i}
                meterName={meterNameForCircuit}
                circuit={c}
                isPhaseValuePending={voltageReference.isPending}
                phaseValue={voltageReference.value}
                onChangePhaseValue={async (value) => await onCircuitChange(c.ww_circuit_id, value, 'voltageReference')}
                isPolarityValuePending={polarity.isPending}
                polarityValue={polarity.value}
                onChangePolarityValue={async (value) => await onCircuitChange(c.ww_circuit_id, value, 'polarity')}
                ctRatingValue={ctRating.value}
                isCtRatingValuePending={ctRating.isPending}
                onChangeCtRatingValue={async (value) => await onCircuitChange(c.ww_circuit_id, value, 'ctRating')}
                powerFactorResult={powerFactorResult}
                polarityResult={polarityResult}
                testedPowerFactorValue={circuitPowerFactorResult?.power_factor ?? 0}
                testedPowerValue={circuitPolarityResult?.power_kw ?? 0}
              />
            </Box>
          );
        })}
      </Center>

      <Center py={5} flexDirection="column">
        <CustomButton color={buttonTextColor} data-testid="run-test" onClick={handleStartTest} my={2}>
          Run Hybrid CT Test
        </CustomButton>

        <Center w={'100%'}>
          <Button
            data-testid="skip-test"
            w={['75%', '75%', '40%']}
            onClick={onOpenSkipDialog}
            color={secondaryButtonTextColor}
            variant={'ghost'}
          >
            Skip all
          </Button>
        </Center>
      </Center>

      <InfoBottomDrawer
        isOpen={isPolarityConfirmationDrawerOpen}
        onClose={OnClosePolarityConfirmationDrawer}
        useDefaultLayout
        drawerHeight={'fit-content'}
        heading="Polarity State"
        // eslint-disable-next-line max-len
        subHeading="Please check the power graph is correctly showing when the battery is charging or discharging. Positive power is charging, Negative is discharging. If the graph is showing the opposite reverse the CTs Polarity."
        icon={<QuestionIcon w={16} h={16} color="customLinkBlue.500" />}
      >
        <CustomButton
          onClick={() => {
            dispatch(setHybridPolarityConfirmation(true));
            OnClosePolarityConfirmationDrawer();
          }}
          w="55%"
          maxW="250px"
        >
          Confirm Polarity
        </CustomButton>
        <Button
          mb={8}
          variant="ghost"
          fontSize="md"
          onClick={async () => {
            try {
              const reversePolarityPromises = hybridAndBackupCircuits.map((circuit) => {
                const newPolarityValue = circuit.polarity === 'normal' ? 'reverse' : 'normal';
                return onCircuitChange(circuit.ww_circuit_id, newPolarityValue, 'polarity');
              });

              await Promise.all(reversePolarityPromises);
            } catch (error) {
              toast({
                title: 'Failed to reverse polarity!',
                status: 'error',
                isClosable: true,
              });
            }
            OnClosePolarityConfirmationDrawer();
          }}
        >
          Reverse Polarity
        </Button>
      </InfoBottomDrawer>

      <SkipTestBottomDrawer
        isOpen={isSkipDialogOpen}
        onClose={(isConfirmed) => {
          if (isConfirmed) handleSkipTest();
          onCloseSkipDialog();
        }}
      />

      {circuitWithCurrentlyOpenDrawer.circuit && circuitWithCurrentlyOpenDrawer.testType && (
        <CircuitInformationBottomDrawer
          circuit={circuitWithCurrentlyOpenDrawer.circuit}
          status={getStatusForOpenCircuit()}
          isOpen={isCircuitInfoDrawerOpen}
          onClose={() => {
            setCircuitWithCurrentlyOpenDrawer({ circuit: null, testType: null });
            onCloseCircuitInfoDrawer();
          }}
          testType={circuitWithCurrentlyOpenDrawer.testType}
        />
      )}
    </Box>
  );
}
