import { baseApi } from '../../../app/baseApi';

export const siteApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    refreshSiteCircuits: build.mutation<void, number>({
      query: (siteId) => ({
        url: `/v1/sites/${siteId}/refresh_circuits`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useRefreshSiteCircuitsMutation } = siteApi;
