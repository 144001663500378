import React, { useState } from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FieldArrayWithId, UseFieldArrayAppend, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { SCHNEIDER_ELECTRIC_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { InstalledDevice } from '../../../api/api-device';
import { del } from '../../../api/api-helpers';
import { useAppDispatch } from '../../../app/hooks';
import Counter from '../../../common/components/Counter';
import { selectSite } from '../siteSlice';
import { MissingModelDrawer } from './MissingModel';
import ModelManufacturerFieldPair from './ModelManufacturerFieldPair';
import { CommonFieldListProps, SystemDetailsFormData } from './system-details-form-types';
import { EMPTY_DEVICE_TEMPLATE } from './system-details-helpers';
import { removeEVCharger } from './systemDetailsSlice';

export type EVChargerFieldArrayProps = {
  evChargerFields: FieldArrayWithId<SystemDetailsFormData, 'evChargers', 'id'>[];
  appendEVCharger: UseFieldArrayAppend<SystemDetailsFormData, 'evChargers'>;
  removeEVCharger: (index?: number | number[] | undefined) => void;
};

type EVChargerFormProps = CommonFieldListProps & EVChargerFieldArrayProps;

export default function EVChargerForm(props: EVChargerFormProps) {
  const {
    control,
    register,
    setValue,
    getValues,
    errors,
    evChargerFields: fields,
    appendEVCharger: append,
    removeEVCharger: remove,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasEVCharger } = useWatch({ control });
  const site = useSelector(selectSite);
  const [currentlyEditingIndex, setCurrentlyEditingIndex] = useState(0);
  const counterValue = hasEVCharger ? fields.length : 0;
  const dispatch = useAppDispatch();
  const dividerColor = useColorModeValue('#0000001A', 'dusk100.400');

  async function handleDeleteEVCharger(deviceId: number | null, index: number) {
    if (window.confirm('Are you sure you want to delete this EV charger?')) {
      if (deviceId) {
        await del<InstalledDevice>(`/fleet/sites/${site.clipsal_solar_id}/devices/${deviceId}`, {});
        dispatch(removeEVCharger(deviceId));
      }
      remove(index);
    }
  }

  const handleAddEVCharger = () => {
    if (!hasEVCharger) setValue('hasEVCharger', true);
    append(EMPTY_DEVICE_TEMPLATE);
    setCurrentlyEditingIndex(getValues().evChargers.length - 1);
  };

  const handleRemoveEVCharger = async (index: number) => {
    const { deviceId } = fields[index];

    if (fields.length === 1) setValue('hasEVCharger', false);

    await handleDeleteEVCharger(deviceId, index);
  };

  return (
    <Box mt={8}>
      <Flex align="center">
        <Heading size={'lg'} mb={2} ml={[4, 6]}>
          EV / EV Charger
        </Heading>
      </Flex>
      <Box ml={[4, 6]} mb={4}>
        <Text size="sm">
          NOTE: This flow currently only supports Schneider Electric EV chargers! You can still associate a CT with any
          other EV Charger but there is no need to fill out this section.
        </Text>
      </Box>

      <Divider color={dividerColor} />
      <Flex align="center" justify="space-between" py={3}>
        <Input type="hidden" {...register('hasEVCharger' as const)} />
        <Text pl={3} pr={[8, 4]}>
          How many EV Chargers do you want to add in this site?
        </Text>
        <Counter
          dataTestId="add-remove-ev-charger-counter"
          value={counterValue}
          incrementAriaLabel="Add EV Charger"
          decrementAriaLabel="Remove EV Charger"
          onIncrement={handleAddEVCharger}
          onDecrement={() => handleRemoveEVCharger(fields.length - 1)}
          isDecrementDisabled={fields.length === 0 || !hasEVCharger}
        />
      </Flex>

      <Collapse in={hasEVCharger}>
        <Accordion
          index={currentlyEditingIndex}
          onChange={(index: number) => setCurrentlyEditingIndex(index)}
          allowToggle
        >
          {fields.map((field, index) => (
            <AccordionItem data-testid="ev-charger-form" key={`${field.id}-${index}`}>
              <AccordionButton data-testid={`ev-charger-${index}`} role={'button'} as={Box}>
                <Flex w={'100%'} justify={'space-between'} align={'center'}>
                  <Heading size={'md'}>EV Charger {index + 1}</Heading>
                  <Flex align={'center'}>
                    {/* Display an error notification post-submission on this accordion button. */}
                    {!!errors?.evChargers?.[index] && <WarningIcon mr={2} color={'red.500'} w={5} h={5} />}

                    <Button
                      data-testid={`ev-charger-${index}-removeBtn`}
                      variant={'ghost'}
                      onClick={() => handleRemoveEVCharger(index)}
                      size={'xs'}
                      ml={2}
                      colorScheme="red"
                      aria-label="Delete EV Charger"
                    >
                      Remove
                    </Button>
                    <AccordionIcon />
                  </Flex>
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4} px={[4, 6]}>
                <ModelManufacturerFieldPair
                  {...{ ...props, index, field, deviceType: 'EV_CHARGER', fieldKey: 'evChargers' }}
                />
                <Text
                  onClick={onOpen}
                  cursor={'pointer'}
                  fontWeight={'bold'}
                  textDecoration={'underline'}
                  mt={2}
                  fontSize={'sm'}
                  color={'customLinkBlue.500'}
                  data-testid={`missing-ev-charger-model-button-${index}`}
                >
                  Can't find your EV Charger?
                </Text>
                <FormControl
                  isInvalid={!!errors?.evChargers?.[index]?.serialNumber}
                  mt={3}
                  id={`${field.id}_serialNumber`}
                >
                  <FormLabel>Serial Number</FormLabel>
                  <Input
                    data-testid={`evChargers-${index}-serialNumber`}
                    placeholder={'######'}
                    {...register(`evChargers.${index}.serialNumber` as const)}
                    type="text"
                  />
                  <FormErrorMessage>{errors?.evChargers?.[index]?.serialNumber?.message}</FormErrorMessage>
                </FormControl>
                {getValues().evChargers?.[index]?.manufacturer === SCHNEIDER_ELECTRIC_MANUFACTURER_ID && (
                  <FormControl
                    isInvalid={!!errors?.evChargers?.[index]?.siteIdentifier}
                    mt={3}
                    id={`${field.id}_siteIdentifier`}
                  >
                    <FormLabel>Charger ID</FormLabel>
                    <Input
                      data-testid={`evChargers-${index}-charger-id`}
                      placeholder={'######'}
                      {...register(`evChargers.${index}.siteIdentifier` as const)}
                      type="text"
                    />
                    <FormErrorMessage data-testid={`evChargers-${index}-charger-id-error`}>
                      {errors?.evChargers?.[index]?.siteIdentifier?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Collapse>

      <MissingModelDrawer
        index={currentlyEditingIndex}
        setValue={setValue}
        isOpen={isOpen}
        onClose={onClose}
        modelType={'EV_CHARGER'}
      />
    </Box>
  );
}
