import { useEffect, useRef } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useToast } from '@chakra-ui/react';

import { IS_NATIVE } from 'clipsal-cortex-utils/src/constants/common-constants';
import { useServiceWorker } from 'clipsal-cortex-utils/src/providers/service-worker-provider';

import { post } from '../../api/api-helpers';
import { VersionCheckResult } from '../../api/api-version-check-result';
import { ToastAppUpdate } from '../components/ToastAppUpdate';
import { APP_VERSION } from '../constants';

export const useAppUpdate = () => {
  const { isUpdateRequired, onReloadPage } = useServiceWorker();
  const toast = useToast();
  let { current } = useRef<string | number>(0);

  useEffect(() => {
    if (current) return;

    async function showNotificationIfUpdateRequired() {
      const { update_required } = await post<VersionCheckResult>('/v1/version_check', {
        platform: Capacitor.getPlatform().toUpperCase(),
        version: APP_VERSION,
        app_name: 'FLEET',
      });

      if (update_required) {
        current = toast({
          duration: null,
          render: () => ToastAppUpdate(),
        }) as string | number;
      }
    }

    if (IS_NATIVE) {
      showNotificationIfUpdateRequired();
      CapacitorApp.addListener('appStateChange', (state) => {
        if (state.isActive && !current) {
          showNotificationIfUpdateRequired();
        }
      });
    } else if (isUpdateRequired) {
      onReloadPage();
    }
  }, []);
};
