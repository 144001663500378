import React, { useCallback, useState } from 'react';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { decode, encode } from 'base64-arraybuffer';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { get } from '../../../api/api-helpers';
import { useAppDispatch } from '../../../app/hooks';
import siteImageUploadImg from '../../../assets/images/onboarding_upload_site_image.svg';
import { selectSite } from '../siteSlice';
import { siteUsersApi, useDeleteSiteImageMutation, useSiteImages } from './siteUsersApi';

const INITIAL_MODAL_CONFIG = { isModalOpen: false, imageUrl: '', isImageLoaded: false };

export default function UploadSiteImage() {
  const [state, setState] = useState(INITIAL_MODAL_CONFIG);
  const { siteImages } = useSiteImages();
  const [deleteImage] = useDeleteSiteImageMutation();
  const { clipsal_solar_id } = useSelector(selectSite);
  const toast = useToast();
  const { iconColor, iconBackgroundColor } = useColorModeValue(
    { iconColor: 'black', iconBackgroundColor: 'white' },
    { iconColor: 'white', iconBackgroundColor: 'black' }
  );
  const dispatch = useAppDispatch();

  async function onUploadImage(fileData: ArrayBuffer, extension: string) {
    // add new image and show loading state
    dispatch(
      siteUsersApi.util.updateQueryData('getSiteImages', clipsal_solar_id || 0, (draft) => {
        draft.push({
          fileName: 'New File',
          url: `data:image/${extension};base64,${encode(fileData)}`,
          isLoading: true,
        });
      })
    );

    try {
      // Get presigned URLs for each image
      const { url } = await get<{ url: string }>(
        `/fleet/sites/${clipsal_solar_id}/image_upload_url?file_extension=${extension}`
      );

      // Save to each presigned url
      await fetch(url, {
        method: 'PUT',
        body: fileData,
      });

      // A bit hacky, but grab the file name from the presigned URL.
      const fileName = url.split('?')[0].split('/').pop() || '';

      // update filename so that we can delete it later
      // also remove loading state
      dispatch(
        siteUsersApi.util.updateQueryData('getSiteImages', clipsal_solar_id || 0, (draft) => {
          const indexToUpdate = draft.length - 1;
          const prevFileName = draft[indexToUpdate].fileName;
          draft[indexToUpdate].fileName = fileName || prevFileName;
          draft[indexToUpdate].isLoading = false;
        })
      );
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error saving site commissioning images.',
        description: 'Please try again. If this persists, contact an administrator.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  const handleFileUpload = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading was aborted');
        };
        reader.onerror = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading has failed');
        };
        reader.onload = async () => {
          const fileData = reader.result as ArrayBuffer;
          const extension = file.name.split('.').pop() as string;
          onUploadImage(fileData, extension);
        };

        reader.readAsArrayBuffer(file);
      });
    },
    [toast, onUploadImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: 'image/*',
    multiple: true,
  });

  function getConditionalRootProps() {
    if (!Capacitor.isNativePlatform()) return getRootProps();

    // If on a native platform, let the user take or select a photo themselves.
    return {
      onClick: async () => {
        const { format, base64String } = await Camera.getPhoto({
          allowEditing: true,
          resultType: CameraResultType.Base64,
        });

        const fileData = decode(base64String as string);
        onUploadImage(fileData, format);
      },
    };
  }

  const noFilesContents = (
    <Center
      my={2}
      justifySelf={'center'}
      flexDirection="column"
      border="2px"
      borderStyle="dashed"
      rounded={5}
      borderColor="day.500"
      bg="rgba(134, 181, 209, 0.2)"
      h={['200px', '200px', '250px']}
      data-testid="no-files-contents"
      {...getConditionalRootProps()}
    >
      {!Capacitor.isNativePlatform() && <input data-testid="image-file-upload-when-no-content" {...getInputProps()} />}
      <Image mb={2} src={siteImageUploadImg} alt="Upload icon" w={['20%', '20%', '10%']} maxW={85} />
      <Text px={2} textAlign="center" mb={2} fontSize="sm">
        Please upload photos of the wiring or the board. They will help us in providing better assistance to you if
        needed.
      </Text>
    </Center>
  );

  const existingFilesContents = (
    <>
      <Text mb={2} fontSize="sm">
        Please upload photos of the wiring or the board. They will help us in providing better assistance to you if
        needed.
      </Text>

      <Modal
        isOpen={state.isModalOpen}
        onClose={() => {
          setState(INITIAL_MODAL_CONFIG);
        }}
        allowPinchZoom
        isCentered
      >
        <ModalOverlay />
        <ModalContent rounded={10} overflow="hidden">
          <ModalCloseButton bg={iconBackgroundColor} color={iconColor} rounded={20} data-testid="close-image-viewer" />
          <ModalBody p={0}>
            <Center minH="60vh" data-testid="image-viewer">
              {!state.isImageLoaded && (
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
              )}
              <Image
                display={state.isImageLoaded ? 'initial' : 'none'}
                w="100%"
                h="100%"
                objectFit={'cover'}
                src={state.imageUrl}
                alt={'file'}
                onLoad={() => setState((prevProps) => ({ ...prevProps, isImageLoaded: true }))}
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex flexWrap="wrap" data-testid="image-files">
        {siteImages.map(({ url, isLoading, fileName }, index) => {
          return (
            <Center w="100px" h="100px" position="relative" key={index}>
              <Center
                as="button"
                type="button"
                opacity={isLoading ? 0.5 : 1}
                data-testid={`delete-image-${index}`}
                onClick={async () => {
                  if (window.confirm('Are you sure you want to delete this image?')) {
                    await deleteImage({ clipsal_solar_id, fileName, index });
                  }
                }}
                h="25px"
                w="25px"
                bg="black"
                rounded={100}
                position="absolute"
                top={0}
                right={0}
                zIndex={1}
              >
                {isLoading ? <Spinner size={'xs'} emptyColor="gray.200" /> : <CloseIcon fontSize="xs" color="white" />}
              </Center>
              <Image
                data-testid={`image-${index}`}
                cursor={'pointer'}
                opacity={isLoading ? 0.5 : 1}
                rounded={15}
                w="80px"
                h="80px"
                objectFit={'cover'}
                src={url}
                alt={'file'}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isModalOpen: true,
                    imageUrl: url,
                    isImageLoaded: false,
                  }))
                }
              />
            </Center>
          );
        })}

        <Center w="100px" h="100px">
          <Center
            rounded={15}
            justifySelf={'center'}
            flexDirection="column"
            border="2px"
            borderStyle="dashed"
            borderColor="day.500"
            bg="rgba(134, 181, 209, 0.2)"
            w="80px"
            h="80px"
            {...getConditionalRootProps()}
          >
            {!Capacitor.isNativePlatform() && (
              <input data-testid="image-file-upload-when-content" {...getInputProps()} />
            )}
            <Image mb={2} src={siteImageUploadImg} alt="Upload icon" w="50%" />{' '}
          </Center>
        </Center>
      </Flex>
    </>
  );

  return siteImages.length ? existingFilesContents : noFilesContents;
}
