import { toZonedTime } from 'date-fns-tz';

import { FleetDataV1, FleetSystemHealthStatus } from '../../api/api-fleet-data';
import { MONTHS, WEEKDAYS } from '../../common/constants';
import {
  HouseFaultIcon,
  HouseNormalIcon,
  HouseOfflineDarkIcon,
  HouseOfflineIcon,
  OfflineIcon,
  ZeroProductionIcon,
} from '../../styles/custom-icons';
import { formatDate, formatFull24TimeTo12Hours, formatTime } from '../../utils/datetime-utils';
import { getOrdinalSuffix } from '../../utils/number-formatting';
import { DateRangeType } from './DateRangeTypePicker';

export const SYSTEM_HEALTH_STATUS_CONFIG = {
  NORMAL: {
    title: 'Normal',
    icon: HouseNormalIcon,
    darkIcon: HouseNormalIcon,
    alternateIcon: HouseNormalIcon,
    color: 'primaryBranding.500',
    alertDetails: '',
    troubleshootingSteps: [] as string[],
  },
  FAULT: {
    title: 'Fault',
    icon: HouseFaultIcon,
    darkIcon: HouseFaultIcon,
    alternateIcon: ZeroProductionIcon,
    color: 'customRed.500',
    alertDetails: `Zero Production alert is shown when the solar inverter is detected producing no energy. Some common factors, including but not limited to: weather events, tripped circuit breaker, inverter fault, and other system device faults.`,
    troubleshootingSteps: [
      `Look for the error code on the inverter monitoring portal and contact the inverter manufacturer for guidance on the next steps.`,
      'Connect with the homeowner and consider conducting basic troubleshooting steps.',
      'Plan and schedule a site visit to conduct a more in-depth diagnosis of the situation.',
    ],
  },
  OFFLINE: {
    title: 'Offline',
    icon: HouseOfflineIcon,
    darkIcon: HouseOfflineDarkIcon,
    alternateIcon: OfflineIcon,
    color: 'fixedCostGrey.500',
    alertDetails: 'The meter is not communicating with the Clipsal Cortex servers.',
    troubleshootingSteps: [],
  },
};

export const FIRST_YEAR_OF_DATA = 2019;

export const DEFAULT_SYSTEM_HEALTH_STATUS: FleetSystemHealthStatus = {
  NORMAL: 0,
  FAULT: 0,
  OFFLINE: 0,
};

export const DEFAULT_FLEET_DATA: FleetDataV1 = {
  consumed: {
    name: 'consumed',
    data: [],
    total: 0,
  },
  produced: {
    name: 'produced',
    data: [],
    total: 0,
  },
};

export const DEFAULT_ENVIRONMENTAL_IMPACT = {
  treesPlanted: 0,
  netImpact: 0,
  emittedCo2: 0,
  offsetCo2: 0,
};

export const FLEET_DASHBOARD_CHART_HEIGHT_PIXELS = 400;

export type Aggregation = 'DAY' | 'MONTH' | 'YEAR';

export const getDefaultFleetDataOptions = () => {
  const nowUserTimezone = new Date();

  // Set to midnight user's time
  nowUserTimezone.setHours(0, 0, 0, 0);
  const nextDay = new Date(nowUserTimezone.getTime());
  nextDay.setDate(nextDay.getDate() + 1);

  return {
    selectedDateRangeType: DateRangeType.Day,
    startDate: nowUserTimezone.toISOString(),
    endDate: nextDay.toISOString(),
    aggregation: 'DAY' as Aggregation,
    selectedPeriod: formatDate(new Date()),
  };
};

export function getDisplayedDateTimeString(lastUpdatedUTC: string | null) {
  if (!lastUpdatedUTC) return 'N/A';

  const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedTime = toZonedTime(new Date(lastUpdatedUTC), userTZ);

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday =
    zonedTime.getFullYear() === today.getFullYear() &&
    zonedTime.getMonth() === today.getMonth() &&
    zonedTime.getDate() === today.getDate();
  const isYesterday =
    zonedTime.getFullYear() === yesterday.getFullYear() &&
    zonedTime.getMonth() === yesterday.getMonth() &&
    zonedTime.getDate() === yesterday.getDate();

  let timeString = '';

  // Add formatted date information
  if (isToday) {
    timeString = 'today at';
  } else if (isYesterday) {
    timeString = 'yesterday at';
  } else {
    timeString = `${WEEKDAYS[zonedTime.getDay()]}, ${zonedTime.getDate()}${getOrdinalSuffix(zonedTime.getDate())} ${
      MONTHS[zonedTime.getMonth()]
    } at`;
  }

  // Add time and timezone
  timeString += ` ${formatFull24TimeTo12Hours(formatTime(zonedTime))} (${userTZ})`;
  return timeString;
}
