import * as yup from 'yup';

import {
  SCHNEIDER_ELECTRIC_MANUFACTURER_ID,
  TESLA_MANUFACTURER_ID,
} from 'clipsal-cortex-utils/src/constants/common-constants';

import { InverterFormData } from './system-details-form-types';
import { MANUFACTURERS_WITH_SERIAL } from './system-details-helpers';

const orientationSchemaRequired = yup.object().shape({
  numOfModules: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'This should be greater than 0.')
    .required('This field is required.'),
  tilt: yup
    .string()
    .oneOf(['flat', 'pitched'])
    .typeError('This field is required.')
    .required('This field is required.'),
  cardinality: yup.string().when('tilt', {
    is: 'pitched',
    then: yup
      .string()
      .oneOf(['NW', 'N', 'NE', 'W', 'E', 'SW', 'S', 'SE'], 'This field is required.')
      .required('This field is required.'),
  }),
  panelPower: yup
    .number()
    .required('This field is required.')
    .typeError('This field is required.')
    .moreThan(249, 'This should be between 250 and 550.')
    .lessThan(551, 'This should be between 250 and 550.')
    .test('panelPower', 'Needs to be incremental of 5', (val) => !!val && val % 5 === 0),
});

const inverterSchemaRequired = yup.object().shape({
  manufacturer: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'This field is required.')
    .required('This field is required.'),
  model: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'This field is required.')
    .required('This field is required.'),
  serialNumber: yup
    .string()
    .nullable()
    .when('manufacturer', {
      is: (val: number) => {
        return MANUFACTURERS_WITH_SERIAL.includes(val);
      },
      then: yup.string().required('This field is required.'),
    }),
  orientations: yup.array().of(orientationSchemaRequired),
});

const orientationSchema = yup.object().shape({
  numOfModules: yup.number(),
  cardinality: yup.string(),
  tilt: yup.string(),
});

const inverterSchema = yup.object().shape({
  manufacturer: yup.number(),
  model: yup.number(),
  serialNumber: yup.string().nullable(),
  siteIdentifier: yup.string().nullable(),
  apiKey: yup.string(),
  orientations: yup.array().of(orientationSchema),
});

const batterySchema = yup.object().shape({
  manufacturer: yup.number(),
  model: yup.number(),
  serialNumber: yup.string().nullable(),
});

const batterySchemaRequired = yup.object().shape({
  manufacturer: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'This field is required.')
    .required('This field is required.'),
  model: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'This field is required.')
    .required('This field is required.'),
  /* Only required for tesla powerwall */
  serialNumber: yup
    .string()
    .nullable()
    .when('manufacturer', {
      is: (val: number) => val === TESLA_MANUFACTURER_ID,
      then: yup.string().required('This field is required.'),
    }),
});

const evChargerSchema = yup.object().shape({
  manufacturer: yup.number(),
  model: yup.number(),
  serialNumber: yup.string().nullable(),
  siteIdentifier: yup.string().nullable(),
});

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const evChargerSchemaRequired = yup.object().shape({
  manufacturer: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'This field is required.')
    .required('This field is required.'),
  model: yup
    .number()
    .typeError('This field is required.')
    .moreThan(0, 'This field is required.')
    .required('This field is required.'),
  serialNumber: yup.string().nullable(),
  siteIdentifier: yup
    .string()
    .nullable()
    .when('manufacturer', {
      is: (val: number) => val === SCHNEIDER_ELECTRIC_MANUFACTURER_ID,
      then: yup
        .string()
        .required('This field is required. See below for assistance.')
        .matches(
          uuidRegex,
          'Should follow UUID format e.g. abcd1234-ab12-4ab1-aab1-abcdef123456. See below for assistance.'
        ),
    }),
});

/**
 * Inverter/orientations field validation depends on whether the customer has a solar system.
 * Battery field validation depends on whether the customer has a battery.
 */
export const schema = yup.object().shape({
  inverters: yup
    .array()
    .of(inverterSchema)
    .when('hasSolarSystem', {
      is: true,
      then: yup.array().of(inverterSchemaRequired),
    }),
  hasBattery: yup.boolean(),
  batteries: yup
    .array()
    .of(batterySchema)
    .when('hasBattery', {
      is: true,
      then: yup.array().of(batterySchemaRequired),
    })
    .test(
      'Checking if hybrid inverters have a battery each',
      'Please add a battery for each hybrid inverter',
      function (batteries) {
        // yuck but seems the only way to do this. No proper solutions in lib yet
        // https://github.com/jquense/yup/pull/201
        const inverters = (this as any).from[0].value.inverters as InverterFormData[];
        const totalDCCoupledInverters = inverters.reduce((acc, inverter) => {
          if (inverter.hasDCCoupledBattery) acc += 1;
          return acc;
        }, 0);
        const totalBatteries = batteries?.length || 0;
        if (totalDCCoupledInverters > 0) return totalBatteries >= totalDCCoupledInverters;
        return true;
      }
    ),
  hasEVCharger: yup.boolean(),
  evChargers: yup
    .array()
    .of(evChargerSchema)
    .when('hasEVCharger', {
      is: true,
      then: yup.array().of(evChargerSchemaRequired),
    }),
});
