import { Amplify, API } from 'aws-amplify';

import { CLIENT_ID, ENDPOINT, USER_POOL_ID } from '../common/constants';
import { AWS_REGION } from '../region-type';

/**
 * Configures the AWS Amplify end-point setup for this repo.
 *
 * Note that we need to separately configure the `API` class, as for some reason in this monorepo architecture it does
 * not correctly initialize itself.
 *
 * see: https://github.com/aws-amplify/amplify-js/issues/5756
 */
export function setupAPI() {
  const apiConfig = {
    endpoints: [
      {
        name: 'site',
        endpoint: ENDPOINT,
        region: AWS_REGION,
      },
    ],
  };

  Amplify.configure({
    Auth: {
      region: AWS_REGION,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: CLIENT_ID,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    API: apiConfig,
  });

  API.configure(apiConfig);
}
