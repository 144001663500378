import React from 'react';
import { createIcon } from '@chakra-ui/react';

const EMPTY_PATH_COLOR = '#a7a7a7';

// eslint-disable max-len
export const createCustomWifiIcon = (signalLevel = 0, signalIconColor: string) => {
  return createIcon({
    displayName: 'WifiIcon',
    path: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="meter-comms-icon-wifi"
      >
        <path
          d="M14.094 18.0009L12.0001 20.2977L9.92163 18.0009C10.1706 17.6867 10.4874 17.4329 10.8483 17.2584C11.2092 17.084 11.6048 16.9933 12.0057 16.9933C12.4065 16.9933 12.8022 17.084 13.1631 17.2584C13.524 17.4329 13.8408 17.6867 14.0898 18.0009H14.094Z"
          fill={signalLevel > 0 ? signalIconColor : EMPTY_PATH_COLOR}
        />
        <path
          d="M15.5949 15.0005C14.6081 14.1234 13.3338 13.639 12.0136 13.639C10.6934 13.639 9.41912 14.1234 8.43237 15.0005"
          stroke={signalLevel > 1 ? signalIconColor : EMPTY_PATH_COLOR}
          strokeWidth="2.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M18.4565 12.1408C16.7088 10.5067 14.4056 9.5976 12.013 9.5976C9.62041 9.5976 7.31719 10.5067 5.56958 12.1408"
          stroke={signalLevel > 2 ? signalIconColor : EMPTY_PATH_COLOR}
          strokeWidth="2.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M21.0001 8.97834C18.5267 6.77181 15.3279 5.55237 12.0132 5.55237C8.69861 5.55237 5.49982 6.77181 3.02637 8.97834"
          stroke={signalLevel > 3 ? signalIconColor : EMPTY_PATH_COLOR}
          strokeWidth="2.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  });
};

export const createCustomCellularIcon = (signalLevel = 0, signalIconColor: string) => {
  return createIcon({
    displayName: 'CellularIcon',
    path: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="meter-comms-icon-cellular"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M22.125 20.2491H19.875C19.5766 20.2491 19.2905 20.1306 19.0795 19.9196C18.8685 19.7087 18.75 19.4225 18.75 19.1241V4.87415C18.75 4.57578 18.8685 4.28963 19.0795 4.07865C19.2905 3.86767 19.5766 3.74915 19.875 3.74915H22.125C22.4234 3.74915 22.7095 3.86767 22.9205 4.07865C23.1315 4.28963 23.25 4.57578 23.25 4.87415V19.1241C23.25 19.4225 23.1315 19.7087 22.9205 19.9196C22.7095 20.1306 22.4234 20.2491 22.125 20.2491Z"
            fill={signalLevel > 3 ? signalIconColor : EMPTY_PATH_COLOR}
          />
          <path
            d="M16.125 20.2494H13.875C13.5766 20.2494 13.2905 20.1309 13.0795 19.9199C12.8685 19.709 12.75 19.4228 12.75 19.1244V8.62445C12.75 8.32608 12.8685 8.03993 13.0795 7.82896C13.2905 7.61798 13.5766 7.49945 13.875 7.49945H16.125C16.4234 7.49945 16.7095 7.61798 16.9205 7.82896C17.1315 8.03993 17.25 8.32608 17.25 8.62445V19.1244C17.25 19.4228 17.1315 19.709 16.9205 19.9199C16.7095 20.1309 16.4234 20.2494 16.125 20.2494Z"
            fill={signalLevel > 2 ? signalIconColor : EMPTY_PATH_COLOR}
          />
          <path
            d="M10.125 20.2491H7.875C7.57663 20.2491 7.29048 20.1306 7.0795 19.9196C6.86853 19.7087 6.75 19.4225 6.75 19.1241V11.6241C6.75 11.3258 6.86853 11.0396 7.0795 10.8287C7.29048 10.6177 7.57663 10.4991 7.875 10.4991H10.125C10.4234 10.4991 10.7095 10.6177 10.9205 10.8287C11.1315 11.0396 11.25 11.3258 11.25 11.6241V19.1241C11.25 19.4225 11.1315 19.7087 10.9205 19.9196C10.7095 20.1306 10.4234 20.2491 10.125 20.2491Z"
            fill={signalLevel > 1 ? signalIconColor : EMPTY_PATH_COLOR}
          />
          <path
            d="M4.125 20.2499H1.875C1.57663 20.2499 1.29048 20.1313 1.0795 19.9204C0.868526 19.7094 0.75 19.4232 0.75 19.1249V14.6249C0.75 14.3265 0.868526 14.0404 1.0795 13.8294C1.29048 13.6184 1.57663 13.4999 1.875 13.4999H4.125C4.42337 13.4999 4.70952 13.6184 4.92049 13.8294C5.13147 14.0404 5.25 14.3265 5.25 14.6249V19.1249C5.25 19.4232 5.13147 19.7094 4.92049 19.9204C4.70952 20.1313 4.42337 20.2499 4.125 20.2499Z"
            fill={signalLevel > 0 ? signalIconColor : EMPTY_PATH_COLOR}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    viewBox: '0 0 24 24',
  });
};
