enum WebSocketReadyState {
  CONNECTING,
  OPEN,
  CLOSED,
  CLOSING,
}

/**
 * Wrapper class to provide extended functionality on top of the base WebSocket MDN interface.
 */
export default class CustomWebSocket extends WebSocket {
  isConnected = () => {
    return this.readyState === WebSocketReadyState.OPEN;
  };

  sendJsonMessage = <T = object>(message: T) => this.send(JSON.stringify(message));
}
