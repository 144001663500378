import React from 'react';
import { Box, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import { OriginalChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { COLOURS } from '../../../../../common/constants';
import { LONG_DATA_TYPE_TO_TITLE_MAP } from '../meter-config-constants';
import { LongPowerDataType, LongPowerDataTypeToSeries } from '../meter-config-types';

type LongEnergyTableProps = {
  channels: OriginalChannelData[];
  onToggleLine: (channelIndex: number) => void;
  hiddenIndexes: number[];
  longChartData: LongPowerDataTypeToSeries;
};

const LongEnergyTable = ({ channels, longChartData, onToggleLine, hiddenIndexes }: LongEnergyTableProps) => {
  return (
    <TableContainer
      bg={'white'}
      _dark={{ bg: 'gray.900' }}
      w="100%"
      border={'1px solid'}
      borderColor={'gray.200'}
      borderRadius={8}
      data-testid="long-energy-table"
    >
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Channel</Th>
            {Object.values(LONG_DATA_TYPE_TO_TITLE_MAP).map((label) => (
              <Th key={label} textAlign={'center'}>
                {label}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {channels.map((channel, index) => {
            return (
              <Tr
                cursor="pointer"
                _hover={{ bg: 'gray.100' }}
                _dark={{ _hover: { bg: 'gray.700' } }}
                opacity={hiddenIndexes.includes(index) ? 0.3 : 1}
                onClick={() => onToggleLine(index)}
                key={`live-table-row-${channel.id}`}
              >
                <Td py={2}>
                  <Flex align="center">
                    <Box background={COLOURS[index]} h={4} w={4} rounded={4} />
                    <Text ml={2}>
                      {channel.label} (CH {index + 1})
                    </Text>
                  </Flex>
                </Td>

                {Object.keys(LONG_DATA_TYPE_TO_TITLE_MAP).map((key) => {
                  const latestData = longChartData[key as LongPowerDataType].data[index].data;
                  const value = latestData[latestData.length - 1]?.y ?? 0;
                  return (
                    <Td key={key} textAlign={'center'} py={2}>
                      {value.toFixed(2)}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default LongEnergyTable;
