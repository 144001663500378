import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';

import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { Appliance } from '../../../../api/api-appliance';
import { useAppDispatch } from '../../../../app/hooks';
import { selectSite } from '../../siteSlice';
import {
  selectTestStatusV2,
  updateMeterStatusFromWebSocketV2,
  updateMeterStatusFromWebSocketV2IgnoreSkipped,
} from '../meterSetupSlice';
import { selectWebSocket } from '../webSocketSlice';
import {
  SourceType,
  WebSocketMessage,
  WebsocketStatusResponseV2,
  WebSocketTestType,
} from './circuit-status-websocket-types';
import { CircuitTestStatus, TestTypeV2 } from './test-types';

export const TYPE_TO_TEST_TYPE: Record<SourceType, WebSocketTestType> = {
  grid: 'GRID',
  solar: 'SOLAR',
  loads: 'APPLIANCE',
  hybrid: 'HYBRID',
  battery: 'BATTERY',
};

export default function useMeterTestHandlers(circuits: ChannelData[], type: SourceType, appliance?: Appliance) {
  const dispatch = useAppDispatch();
  const testStatus = useSelector(selectTestStatusV2);
  const webSocket = useSelector(selectWebSocket);
  const site = useSelector(selectSite);

  function getStatusToUpdate(testStatus: WebsocketStatusResponseV2) {
    if (type !== 'loads') {
      const testResult = testStatus[type];
      if (!testResult) {
        return {
          [TestTypeV2.Polarity]: {
            circuits: [],
            status: CircuitTestStatus.Uninitialized,
          },
          [TestTypeV2.PowerFactor]: {
            circuits: [],
            status: CircuitTestStatus.Uninitialized,
          },
          last_updated_utc: 0,
          status: CircuitTestStatus.Uninitialized,
        };
      }
      return testResult;
    }

    if (!appliance) throw new Error(`Appliance must be supplied in the case of a load test.`);

    let loadResult = testStatus.loads?.find((load) => load.appliance_id === appliance.appliance_id);
    // Create an empty test result in the case of a load with no test results yet.
    if (!loadResult) {
      loadResult = {
        appliance_id: appliance.appliance_id,
        appliance_type: appliance.appliance_type,
        [TestTypeV2.Polarity]: {
          circuits: [],
          status: CircuitTestStatus.Uninitialized,
        },
        [TestTypeV2.PowerFactor]: {
          circuits: [],
          status: CircuitTestStatus.Uninitialized,
        },
        last_updated_utc: 0,
        status: CircuitTestStatus.Uninitialized,
      };
    }

    return loadResult;
  }

  function handleStartTest() {
    const newStatus = cloneDeep(testStatus);
    const statusToUpdate = getStatusToUpdate(newStatus);

    statusToUpdate.status = CircuitTestStatus.InProgress;
    statusToUpdate[TestTypeV2.Polarity].status = CircuitTestStatus.InProgress;
    statusToUpdate[TestTypeV2.PowerFactor].status = CircuitTestStatus.InProgress;

    if (statusToUpdate[TestTypeV2.Polarity].circuits.length) {
      statusToUpdate[TestTypeV2.Polarity].circuits.forEach(
        (c) => (c.polarity_test_status = CircuitTestStatus.InProgress)
      );
    } else {
      // No initialized circuits yet, create some empty ones and set them to be in progress
      statusToUpdate[TestTypeV2.Polarity].circuits = circuits.map((c) => {
        return {
          polarity_test_status: CircuitTestStatus.InProgress,
          power_factor: 0,
          current_a: 0,
          circuit_id: c.ww_circuit_id,
          power_kw: 0,
          id: c.clipsal_circuit_id,
          clipsal_monitors: c.clipsal_monitors,
        };
      });
    }

    if (statusToUpdate[TestTypeV2.PowerFactor].circuits.length) {
      statusToUpdate[TestTypeV2.PowerFactor].circuits.forEach(
        (c) => (c.power_factor_test_status = CircuitTestStatus.InProgress)
      );
    } else {
      // No initialized circuits yet, create some empty ones and set them to be in progress
      statusToUpdate[TestTypeV2.PowerFactor].circuits = circuits.map((c) => {
        return {
          power_factor_test_status: CircuitTestStatus.InProgress,
          power_factor: 0,
          current_a: 0,
          circuit_id: c.ww_circuit_id,
          power_kw: 0,
          id: c.clipsal_circuit_id,
          clipsal_monitors: c.clipsal_monitors,
        };
      });
    }

    dispatch(
      updateMeterStatusFromWebSocketV2IgnoreSkipped({
        data: newStatus,
        typeToIgnoreSkipped: type,
        applianceIdToIgnoreSkipped: appliance ? appliance.appliance_id : undefined,
      })
    );

    const message: WebSocketMessage = {
      action: 'runTests',
      clipsal_solar_id: site.clipsal_solar_id,
      test_types: [TYPE_TO_TEST_TYPE[type]],
    };

    if (type === 'loads' && appliance) {
      message.appliance_ids = [appliance.appliance_id];
    }

    webSocket?.sendJsonMessage<WebSocketMessage>(message);
  }

  function handleSkipTest() {
    const newStatus = cloneDeep(testStatus);
    const statusToUpdate = getStatusToUpdate(newStatus);

    statusToUpdate.status = CircuitTestStatus.Skipped;
    statusToUpdate[TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
    statusToUpdate[TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;

    if (statusToUpdate[TestTypeV2.Polarity].circuits.length) {
      statusToUpdate[TestTypeV2.Polarity].circuits.forEach((c) => (c.polarity_test_status = CircuitTestStatus.Skipped));
    } else {
      // No initialized circuits yet, create some empty ones and set them to be in progress
      statusToUpdate[TestTypeV2.Polarity].circuits = circuits.map((c) => {
        return {
          polarity_test_status: CircuitTestStatus.Skipped,
          power_factor: 0,
          current_a: 0,
          circuit_id: c.ww_circuit_id,
          power_kw: 0,
          id: c.clipsal_circuit_id,
          clipsal_monitors: c.clipsal_monitors,
        };
      });
    }

    if (statusToUpdate[TestTypeV2.PowerFactor].circuits.length) {
      statusToUpdate[TestTypeV2.PowerFactor].circuits.forEach(
        (c) => (c.power_factor_test_status = CircuitTestStatus.Skipped)
      );
    } else {
      // No initialized circuits yet, create some empty ones and set them to be in progress
      statusToUpdate[TestTypeV2.PowerFactor].circuits = circuits.map((c) => {
        return {
          power_factor_test_status: CircuitTestStatus.Skipped,
          power_factor: 0,
          current_a: 0,
          circuit_id: c.ww_circuit_id,
          power_kw: 0,
          id: c.clipsal_circuit_id,
          clipsal_monitors: c.clipsal_monitors,
        };
      });
    }

    dispatch(updateMeterStatusFromWebSocketV2(newStatus));
  }

  return [handleStartTest, handleSkipTest];
}

/**
 * This function adjusts test status based on polarity confirmation
 * @param isPolarityConfirmed - boolean
 * @param testStatus - CircuitTestStatus
 * @returns CircuitTestStatus
 */
export function getTestStatusBasedOnPolarityConfirmation(
  isPolarityConfirmed: boolean,
  testStatus = CircuitTestStatus.Uninitialized
) {
  // if polarity has been confirmed, return the test status
  if (isPolarityConfirmed) return testStatus;

  // if tests have outcomes but polarity not confirmed, send need confirmation as status
  if ([CircuitTestStatus.Failed, CircuitTestStatus.Success].includes(testStatus)) {
    return CircuitTestStatus.NeedConfirmation;
  }

  return testStatus;
}
