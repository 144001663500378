import React from 'react';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ChannelData, CTRating, Polarity, VoltageReference } from 'clipsal-cortex-types/src/api/api-ww-meter';
import Card from 'clipsal-cortex-ui/src/components/card/Card';

import MultiToggleSwitch from '../../../../common/components/MultiToggleSwitch';
import { PencilIcon } from '../../../../styles/custom-icons';
import { selectSite } from '../../siteSlice';
import { DISPLAYED_VOLTAGE_REF_TYPES, POLARITY_TYPES } from '../circuit-tests/test-types';
import { MeterSetupPollingSubRouteProps } from '../MeterSetupPollingWrapper';
import { selectRawMeters } from '../meterSetupSlice';
import { CTConfigFormData, CustomSwitchValue } from './ct-configuration-form-types';

export const CTRatings = [60, 120, 200, 400, 600] as CTRating[];
type FormSource =
  | `grid`
  | `${'solar' | 'load' | 'battery' | 'evCharger'}.${number}.circuits`
  | `hybrid.${'inverter' | 'backup'}`;

interface SelectedCircuitsCardProps {
  field: FieldArrayField;
  index: number;
  register: UseFormRegister<CTConfigFormData>;
  onDismiss: () => void;
  iconComponent: React.ReactElement<any, any>;
  onCircuitChange: MeterSetupPollingSubRouteProps['onCircuitChange'];
  polarityValue: CustomSwitchValue<Polarity>;
  voltageRefValue: CustomSwitchValue<VoltageReference>;
  ctRating: CustomSwitchValue<CTRating>;
  source: FormSource;
}

interface FieldArrayField extends ChannelData {
  id: string;
}

const SelectedCircuitsCard = ({
  field,
  index,
  register,
  onDismiss,
  iconComponent,
  source,
  onCircuitChange,
  polarityValue,
  voltageRefValue,
  ctRating,
}: SelectedCircuitsCardProps) => {
  const rawMeters = useSelector(selectRawMeters);
  const meterId = field.ww_circuit_id?.split('_')[0];
  const meterNumber = Object.keys(rawMeters).indexOf(meterId) + 1;
  const ctNumber = field.ww_circuit_id?.charAt(field.ww_circuit_id.length - 1);
  const { electrical_config } = useSelector(selectSite);
  const phaseOptions = DISPLAYED_VOLTAGE_REF_TYPES[electrical_config];
  const phase = phaseOptions?.find((phaseOption) => phaseOption?.value === voltageRefValue?.value);
  const circuitLabel = `Meter ${meterNumber}, CT ${ctNumber}, Phase ${phase?.label}`;
  const isMobileViewport = useBreakpointValue({
    base: window.innerWidth < 768,
    md: false,
  });
  const { shadowColor, switchClassName } = useColorModeValue(
    {
      shadowColor: 'rgba(0, 0, 0, 0.25)',
      switchClassName: 'light-mode-toggle-switch',
    },
    {
      shadowColor: 'rgba(255, 255, 255, 0.25)',
      switchClassName: 'dark-mode-toggle-switch-2',
    }
  );

  return (
    <>
      <Card
        isDismissable
        onDismiss={onDismiss}
        shadow={`0px 0px 4px ${shadowColor}`}
        mb={4}
        p={[3, 5]}
        data-testid={`${source}-${index}-card`}
      >
        <Flex mb={5} key={field.id}>
          {iconComponent}
          <Flex direction="column" pl={2}>
            <InputGroup>
              <Input
                variant="unstyled"
                data-testid="circuit-name"
                defaultValue={field.circuit_name}
                {...register(`${source}.${index}.circuit_name` as `${FormSource}.${number}.circuit_name`)}
              />
              <InputRightElement pr={12} pb={4} pointerEvents="none">
                <PencilIcon />
              </InputRightElement>
            </InputGroup>
            <Text color="grey" fontSize="sm" display="block">
              {circuitLabel}
            </Text>
          </Flex>
        </Flex>

        <Flex justify="space-between">
          <Flex direction="column" align="center" h="100%">
            <Select
              data-testid="ct-rating-select"
              h={isMobileViewport ? 8 : 35}
              cursor={'pointer'}
              bg="#f5f5f5"
              _dark={{ bg: '#1c2230' }}
              border="none"
              rounded={25}
              value={ctRating?.value}
              disabled={ctRating?.isPending}
              onChange={async (e) => {
                const newCTRating = (Number(e.target.value) || 60) as CTRating;
                await onCircuitChange(field.ww_circuit_id, newCTRating, 'ctRating');
              }}
            >
              {CTRatings.map((rating) => (
                <option value={rating} key={rating}>
                  {rating}
                </option>
              ))}
            </Select>
            {ctRating?.isPending ? (
              <Flex justify="center" py={1} align="center" fontSize={'xs'}>
                <Spinner emptyColor="gray.200" color="blue.500" size="sm" />
                <Text fontWeight="normal" color="gray.500" ml={1}>
                  Updating...
                </Text>
              </Flex>
            ) : (
              <Text fontSize="xs" mt={1}>
                CT Rating (A)
              </Text>
            )}
          </Flex>
          {/* Phasing switch */}
          <Box
            width={isMobileViewport ? '45%' : '40%'}
            maxWidth="150px"
            fontSize="xs"
            mx={2}
            fontWeight="bold"
            className={switchClassName}
          >
            <MultiToggleSwitch
              switchOptions={phaseOptions}
              fontSize="xs"
              value={voltageRefValue?.value}
              onChange={async (value) => await onCircuitChange(field.ww_circuit_id, value, 'voltageReference')}
              disabled={voltageRefValue?.isPending}
              enableBottomLabel
              setValueAsBottomLabel={false}
              label="Phase"
              customClassName={`container-height-${isMobileViewport ? 'small' : 'large'}`}
              data-testid="voltage-ref-toggle"
            />
          </Box>

          {/* Polarity switch */}
          <Box width="35%" maxWidth="150px" fontSize="xs" fontWeight="bold" className={switchClassName}>
            <MultiToggleSwitch
              switchOptions={POLARITY_TYPES}
              fontSize="xs"
              value={polarityValue?.value}
              onChange={async (value) => await onCircuitChange(field.ww_circuit_id, value, 'polarity')}
              disabled={polarityValue?.isPending}
              enableBottomLabel
              setValueAsBottomLabel={false}
              label="Polarity"
              customClassName={`container-height-${isMobileViewport ? 'small' : 'large'}`}
              data-testid="polarity-toggle"
            />
          </Box>
        </Flex>
      </Card>
    </>
  );
};

export default SelectedCircuitsCard;

export const EmptySelectedCircuitsCard = ({ iconComponent }: { iconComponent: React.ReactElement<any, any> }) => {
  const shadowColor = useColorModeValue('rgba(0, 0, 0, 0.25)', 'rgba(255, 255, 255, 0.25)');
  return (
    <Card shadow={`0px 0px 4px ${shadowColor}`} minW="100%">
      <Flex mb={0}>{iconComponent}</Flex>
      <Box minH={['96px', '90px']}>
        <Flex direction="column" px={6} align="center">
          <Text textAlign="center" mb={2} color="dusk050.500" fontSize="md">
            No CT's Added
          </Text>
          <Text textAlign="center" color="dusk050.500" fontSize="sm">
            You should first select the CT’s from the Meters above
          </Text>
        </Flex>
      </Box>
    </Card>
  );
};
