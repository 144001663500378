import React, { useState } from 'react';
import { Text, useBreakpointValue } from '@chakra-ui/react';
import { Column } from 'react-table';

import { BatteryUpgradeOpportunity } from '../../api/api-battery-upsell';
import { MOBILE_COLUMN_DISPLAY_TYPES } from './battery-upsell-helpers';
import BatteryUpsellActionMenu from './BatteryUpsellActionMenu';

// eslint-disable-next-line react-refresh/only-export-components
const COLUMNS: Array<Column<BatteryUpgradeOpportunity>> = [
  {
    Header: 'Site Name',
    id: 'clipsal_solar__site_name',
    accessor: 'site_name',
    Cell: ({ row }: { row: any }) => {
      return (
        <Text maxWidth={[24, 200]} noOfLines={2}>
          {row.original.site_name}
        </Text>
      );
    },
  },
  {
    Header: 'Estimated Annual Battery Savings',
    id: 'best_simulation__savings_yearly_dollars',
    accessor: (row) => {
      return '$' + row.best_simulation.metrics_yearly.savings_yearly_dollars.toFixed(0);
    },
  },
  {
    Header: 'Avg. Solar Export (kWh/day)',
    id: 'best_simulation__solar_export_daily_before_kwh',
    accessor: (row) => {
      return row.best_simulation.metrics_daily.solar_export_daily_before_kwh?.toFixed(1) || 0;
    },
  },
  {
    Header: 'Avg. Grid import (kWh/day)',
    id: 'best_simulation__grid_import_daily_before_kwh',
    accessor: (row) => {
      return row.best_simulation.metrics_daily.grid_import_daily_before_kwh?.toFixed(1) || 0;
    },
  },
  {
    Header: 'Usable Battery (kWh)',
    id: 'best_simulation__battery_usable_kwh',
    accessor: (row) => {
      return row.best_simulation.battery_usable_kwh?.toFixed(1) || 0;
    },
  },
  {
    Header: '',
    id: 'action',
    accessor: (row) => {
      return row.best_simulation.battery_simulation_id;
    },
    disableSortBy: true,
    Cell: ({ row }: { row: any }) => (
      <BatteryUpsellActionMenu siteData={row.original as BatteryUpgradeOpportunity} dataIndex={row.index} />
    ),
  },
];

/**
 * Returns the columns to be displayed in the table, according to the viewport type, as well as the state and state
 * setter for the column display type (mobile only).
 */
export default function useTableColumns(): [Column[], [string, React.Dispatch<React.SetStateAction<string>>]] {
  // This state value only applies on mobile and is used to control which column to display.
  const [selectedColumnDisplayType, setSelectedColumnDisplayType] = useState('best_simulation__battery_usable_kwh');
  const columns = useBreakpointValue(
    {
      base: COLUMNS.filter((col) => {
        const columnsToBeFiltered = MOBILE_COLUMN_DISPLAY_TYPES.filter(
          (column) => column.value !== selectedColumnDisplayType
        ).map((column) => column.value);

        return !columnsToBeFiltered.includes(col.id as string);
      }),
      lg: COLUMNS,
    },
    { ssr: false }
  ) as Column[];

  return [columns ?? [], [selectedColumnDisplayType, setSelectedColumnDisplayType]];
}
