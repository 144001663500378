import React from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Heading,
  IconButton,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  UnorderedList,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

import MobileTopNav from '../../common/components/MobileTopNav';
import { UpsellIcon } from '../../styles/custom-icons';
import FleetDashboardProfileInfo from '../fleet-dashboard/FleetDashboardProfileInfo';
import BatteryUpsellSitesTable from './BatteryUpsellSitesTable';

const BatteryUpsellDashboard = () => {
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    { ssr: false }
  );

  const { onOpen, onClose, isOpen } = useDisclosure();

  const infoPopover = (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={isMobileViewport ? 'bottom' : 'right'}
      closeOnBlur={false}
      isLazy
      arrowSize={18}
    >
      <PopoverTrigger>
        <IconButton
          size="sm"
          aria-label="info icon"
          data-testid="battery-upsell-popover-icon"
          icon={<InfoOutlineIcon h={5} w={5} color="customLinkBlue.500" />}
          bg="transparent"
        />
      </PopoverTrigger>
      <PopoverContent p={4} bg="#5041F9" color="white" w={isMobileViewport ? 280 : 380} rounded={12}>
        <PopoverArrow bg="#5041F9" />
        <PopoverCloseButton />
        <Flex direction="column" data-testid="battery-upsell-popover-contents">
          <Flex align="center">
            <UpsellIcon h={6} w={6} />
            <Heading ml={1} size="sm" fontWeight={500}>
              Upsell
            </Heading>
          </Flex>
          <Text my={2}>
            We estimate how much a homeowner could save with battery given their existing solar PV installation and
            consumption profile. Eligibility is based on homeowners:
          </Text>
          <UnorderedList>
            <ListItem>having solar,</ListItem>
            <ListItem>to have been monitored for at least 3 months,</ListItem>
            <ListItem>to not have a battery already installed.</ListItem>
          </UnorderedList>

          <Button
            ml={'auto'}
            variant={'ghost'}
            color="white"
            _hover={{ bg: '#6557ff' }}
            _focus={{ bg: '#6557ff' }}
            onClick={onClose}
            data-testid="battery-upsell-popover-close"
          >
            Got it!
          </Button>
        </Flex>
      </PopoverContent>
    </Popover>
  );

  return (
    <Flex direction="column" minHeight="80vh" minWidth="100%">
      {isMobileViewport ? (
        <MobileTopNav title={'Upsells'} backURL="">
          {infoPopover}
        </MobileTopNav>
      ) : (
        <Flex mt={5} align={'center'} px={8}>
          <Flex align={'center'}>
            <Heading mr={1} ml={4} size={'md'}>
              Upsells
            </Heading>
            {infoPopover}
          </Flex>

          <Flex align="center" ml={'auto'} mr={2}>
            <FleetDashboardProfileInfo />
          </Flex>
        </Flex>
      )}

      <BatteryUpsellSitesTable />
    </Flex>
  );
};

export default BatteryUpsellDashboard;
