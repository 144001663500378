import React, { useState } from 'react';
import { Box, Button, Center, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import CustomButton from '../../../../common/components/CustomButton';
import { COMPONENT_MIN_HEIGHT } from '../../../../common/constants';
import { MeterSetupPollingSubRouteProps } from '../MeterSetupPollingWrapper';
import { selectAllCircuits, selectRawMeters, selectSolarAppliances, selectTestStatusV2 } from '../meterSetupSlice';
import SubRouteTopNav from '../SubRouteTopNav';
import { CircuitInformationBottomDrawer } from './CircuitInformationBottomDrawer';
import CircuitStatusCard from './CircuitStatusCard';
import CircuitTestChart from './CircuitTestChart';
import SkipTestBottomDrawer from './SkipTestBottomDrawer';
import { CircuitTestStatus, TestTypeV2 } from './test-types';
import useMeterTestHandlers from './use-meter-test-handlers';

export default function SolarTest() {
  const { onCircuitChange, circuitState } = useOutletContext<MeterSetupPollingSubRouteProps>();
  const navigate = useNavigate();
  const solarAppliances = useSelector(selectSolarAppliances);
  const allCircuits = useSelector(selectAllCircuits);
  const testStatus = useSelector(selectTestStatusV2);
  const solarCircuits = allCircuits.filter((c) =>
    solarAppliances.some((a) => a.circuits.includes(c.clipsal_circuit_id))
  );
  const { isOpen: isSkipDialogOpen, onOpen: onOpenSkipDialog, onClose: onCloseSkipDialog } = useDisclosure();
  const {
    isOpen: isCircuitInfoDrawerOpen,
    onOpen: onOpenCircuitInfoDrawer,
    onClose: onCloseCircuitInfoDrawer,
  } = useDisclosure();
  const [handleStartTest, handleSkipTest] = useMeterTestHandlers(solarCircuits, 'solar');
  const rawMeters = useSelector(selectRawMeters);
  const [circuitWithCurrentlyOpenDrawer, setCircuitWithCurrentlyOpenDrawer] = useState<{
    circuit: ChannelData | null;
    testType: TestTypeV2 | null;
  }>({ circuit: null, testType: null });
  const { buttonTextColor, secondaryButtonTextColor } = useColorModeValue(
    { buttonTextColor: 'white', secondaryButtonTextColor: 'customBlack.500' },
    { buttonTextColor: 'black', secondaryButtonTextColor: 'dusk100.200' }
  );

  if (!solarCircuits.length) {
    return <Box>There is no solar appliance. You should not be able to get to this page.</Box>;
  }

  function getStatusForOpenCircuit() {
    const { circuit, testType } = circuitWithCurrentlyOpenDrawer;
    if (!circuit || !testType) throw new Error(`Tried to open circuit status drawer without values in state.`);

    const result = testStatus.solar?.[testType].circuits.find((c) => c.circuit_id === circuit.ww_circuit_id);

    if (!result) return CircuitTestStatus.Uninitialized;

    return testType === TestTypeV2.PowerFactor
      ? (result.power_factor_test_status as CircuitTestStatus)
      : (result.polarity_test_status as CircuitTestStatus);
  }

  return (
    <Box minHeight={COMPONENT_MIN_HEIGHT}>
      <SubRouteTopNav onGoBack={() => navigate(-1)} title="Solar Test" />

      <CircuitTestChart source="solar" testType={TestTypeV2.Polarity} />

      <Center flexDirection="column">
        {solarCircuits.map((c) => {
          // Get the status for each test type for this circuit.
          // Note that if it is not found, the test is considered uninitialized.
          const circuitPolarityResult = testStatus.solar?.[TestTypeV2.Polarity].circuits.find(
            (circuitTestResult) => circuitTestResult.circuit_id === c.ww_circuit_id
          );
          const circuitPowerFactorResult = testStatus.solar?.[TestTypeV2.PowerFactor].circuits.find(
            (circuitTestResult) => circuitTestResult.circuit_id === c.ww_circuit_id
          );

          const circuitMeterId = c.ww_circuit_id.split('_')[0];
          const meterForCircuit = rawMeters[circuitMeterId];
          const meterNameForCircuit =
            meterForCircuit?.label ?? `Meter ${Object.values(rawMeters).indexOf(meterForCircuit) + 1}`;

          const circuitPolarityPhaseValues = circuitState.values.find(({ circuitId }) => circuitId === c.ww_circuit_id);
          if (!circuitPolarityPhaseValues)
            throw new Error(`Unable to find circuit phase and polarity value for ID ${c.ww_circuit_id}`);

          const { polarity, voltageReference, ctRating } = circuitPolarityPhaseValues;
          let powerFactorResult = circuitPowerFactorResult?.power_factor_test_status ?? CircuitTestStatus.Uninitialized;
          let polarityResult = circuitPolarityResult?.polarity_test_status ?? CircuitTestStatus.Uninitialized;

          // If the top level is skipped, the circuits are skipped too.
          if (testStatus.solar?.status === CircuitTestStatus.Skipped) {
            powerFactorResult = CircuitTestStatus.Skipped;
            polarityResult = CircuitTestStatus.Skipped;
          }

          return (
            <Box w={['100%', '100%', '100%', '70%']} px={5} py={2} key={c.ww_circuit_id}>
              <CircuitStatusCard
                onOpenDrawer={(circuit, testType) => {
                  setCircuitWithCurrentlyOpenDrawer({ circuit, testType });
                  onOpenCircuitInfoDrawer();
                }}
                meterName={meterNameForCircuit}
                circuit={c}
                isPhaseValuePending={voltageReference.isPending}
                phaseValue={voltageReference.value}
                onChangePhaseValue={async (value) => await onCircuitChange(c.ww_circuit_id, value, 'voltageReference')}
                isPolarityValuePending={polarity.isPending}
                polarityValue={polarity.value}
                onChangePolarityValue={async (value) => await onCircuitChange(c.ww_circuit_id, value, 'polarity')}
                ctRatingValue={ctRating.value}
                isCtRatingValuePending={ctRating.isPending}
                onChangeCtRatingValue={async (value) => await onCircuitChange(c.ww_circuit_id, value, 'ctRating')}
                powerFactorResult={powerFactorResult}
                polarityResult={polarityResult}
                testedPowerFactorValue={circuitPowerFactorResult?.power_factor ?? 0}
                testedPowerValue={circuitPolarityResult?.power_kw ?? 0}
              />
            </Box>
          );
        })}
      </Center>

      <Center py={5} flexDirection="column">
        <CustomButton color={buttonTextColor} data-testid="run-test" onClick={handleStartTest} my={2}>
          Run Solar CT Test
        </CustomButton>

        <Center w={'100%'}>
          <Button
            data-testid="skip-test"
            w={['75%', '75%', '40%']}
            onClick={onOpenSkipDialog}
            color={secondaryButtonTextColor}
            variant={'ghost'}
          >
            Skip all
          </Button>
        </Center>
      </Center>

      <SkipTestBottomDrawer
        isOpen={isSkipDialogOpen}
        onClose={(isConfirmed) => {
          if (isConfirmed) handleSkipTest();
          onCloseSkipDialog();
        }}
      />

      {circuitWithCurrentlyOpenDrawer.circuit && circuitWithCurrentlyOpenDrawer.testType && (
        <CircuitInformationBottomDrawer
          circuit={circuitWithCurrentlyOpenDrawer.circuit}
          status={getStatusForOpenCircuit()}
          isOpen={isCircuitInfoDrawerOpen}
          onClose={() => {
            setCircuitWithCurrentlyOpenDrawer({ circuit: null, testType: null });
            onCloseCircuitInfoDrawer();
          }}
          testType={circuitWithCurrentlyOpenDrawer.testType}
        />
      )}
    </Box>
  );
}
