import React, { useEffect, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { encode } from 'base64-arraybuffer';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useForm } from 'react-hook-form';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { AddressPinIcon } from 'clipsal-cortex-icons/src/custom-icons';

import { del, put } from '../../../api/api-helpers';
import { Tenant } from '../../../api/api-tenant';
import { useAppDispatch } from '../../../app/hooks';
import CustomButton from '../../../common/components/CustomButton';
import MobileTopNav from '../../../common/components/MobileTopNav';
import { BOTTOM_NAV_HEIGHT } from '../../../common/constants';
import ManualAddressDialog, { ManualAddressFormData } from '../../site/site-details/ManualAddressDialog';
import { addUserDetails, selectUser } from '../userSlice';
import { BusinessData, mapBusinessApiDataToForm, mapBusinessFormDataToAPI } from './business-details-helpers';
import UploadBusinessLogo, { UploadedFile } from './UploadBusinessLogo';

import AutocompletePrediction = google.maps.places.AutocompletePrediction;

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY as string;

const schema = yup.object().shape({
  name: yup.string().trim().required('*Name is Required'),
  address: yup.string().trim().required('*Address Name is Required'),
  size: yup.string().trim(),
  city: yup.string(),
  state: yup.string(),
  postCode: yup.string(),
  country: yup.string(),
});

export default function BusinessDetails() {
  const {
    register,
    handleSubmit: handleFormSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = useForm<BusinessData>({
    resolver: yupResolver(schema),
  });
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });

  const { predictionHoverColor, background } = useColorModeValue(
    { predictionHoverColor: 'gray.50', background: 'white' },
    {
      predictionHoverColor: 'gray.700',
      background: 'gray.900',
    }
  );
  const [{ isFormFilled, isLoading }, setState] = useState({
    isFormFilled: false,
    isLoading: false,
  });
  const [{ logoFile, isUploading, isDeleting, isDeleteModalOpen }, setLogoFile] = useState({
    logoFile: null as UploadedFile | null,
    isUploading: false,
    isDeleteModalOpen: false,
    isDeleting: false,
  });
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [shouldDisplayAutocompleteOptions, setShouldDisplayAutocompleteOptions] = useState(false);
  const user = useSelector(selectUser);
  const toast = useToast({ isClosable: true });
  const dispatch = useAppDispatch();
  const {
    isOpen: isManualAddressDialogOpen,
    onOpen: onOpenManualAddressDialog,
    onClose: onCloseManualAddressDialog,
  } = useDisclosure();

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: [],
      componentRestrictions: { country: ['AU', 'NZ'] },
    } as any,
  });

  useEffect(() => {
    const tenantDetails = user.tenant;

    reset(mapBusinessApiDataToForm(tenantDetails));
    if (tenantDetails.logo_url) {
      setLogoFile((prevState) => ({
        ...prevState,
        logoFile: {
          url: tenantDetails.logo_url || '',
          fileData: null,
          extension: '',
          isPersisted: true,
        },
      }));
    }

    // Check if all fields are filled and enable save details button.
    // Using subscription so that we do not rerender whole component on value changes

    const subscription = watch((values) => {
      const isFormFilled = Object.entries(values).every(([key, value]) => {
        // size is optional
        if (key === 'size') return true;
        return !!value;
      });
      setState((prevState) => ({ ...prevState, isFormFilled }));
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleCloseDeleteModal = () => {
    setLogoFile((prevState) => ({
      ...prevState,
      isDeleteModalOpen: false,
    }));
  };

  async function handleSubmit(values: BusinessData) {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const updatedTenant = await put<Tenant>(`/fleet/tenants/${user.tenant_id}`, mapBusinessFormDataToAPI(values));
      reset(mapBusinessApiDataToForm(updatedTenant));
      toast({
        title: 'Successfully updated business details.',
        status: 'success',
      });
      dispatch(addUserDetails({ tenant: updatedTenant }));
    } catch (error) {
      toast({
        title: 'Something went wrong updating business details.',
        description: 'Please try again. If this continues, please contact support.',
        status: 'error',
      });
    }

    setState((prevState) => ({ ...prevState, isLoading: false }));
  }

  async function handleSelectLocation(location: AutocompletePrediction) {
    const currentValues = getValues();

    placesService?.getDetails({ placeId: location.place_id }, async (details) => {
      const locationData: Record<string, string | undefined> = {};

      // iterate over address components to populate location data
      details?.address_components?.forEach((component) => {
        if (component.types.includes('locality')) {
          locationData.city = component?.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          locationData.state = component?.short_name;
        } else if (component.types.includes('country')) {
          locationData.country = component?.long_name;
        } else if (component.types.includes('postal_code')) {
          locationData.postCode = component?.long_name;
        }
      });

      const termsLength = location.terms.length;

      reset(
        {
          ...currentValues,
          address: location.description,
          city: locationData.city || location.terms[termsLength - 3]?.value,
          state: locationData.state || location.terms[termsLength - 2]?.value,
          country: locationData.country || location.terms[termsLength - 1]?.value,
          postCode: locationData.postCode,
        },
        { keepDirty: true }
      );

      setShouldDisplayAutocompleteOptions(false);
    });
  }

  function handleSubmitManualAddress({ country, streetAddress, unit, suburb, state, postcode }: ManualAddressFormData) {
    reset(
      {
        ...getValues(),
        address: `${unit ? unit + '/' : ''}${streetAddress}, ${suburb}, ${state}`,
        city: suburb,
        state,
        country,
        postCode: postcode.toString(),
      },
      { keepDirty: true }
    );

    onCloseManualAddressDialog();
    setShouldDisplayAutocompleteOptions(false);
  }

  function PredictionsList() {
    return placePredictions.length ? (
      <Box width={'100%'} mt={2} py={3}>
        <Heading mb={1} size={'md'}>
          Results
        </Heading>
        {placePredictions.map((location, i) => (
          <Box
            data-testid={`business-address-prediction-${i}`}
            className={'business-address-prediction-list'}
            borderBottom={i === placePredictions.length - 1 ? '1px solid grey' : undefined}
            borderTop={'1px solid grey'}
            key={`google-maps-location-${i}`}
            _hover={{ background: predictionHoverColor }}
            onClick={async () => handleSelectLocation(location)}
            cursor={'pointer'}
            py={2}
          >
            <Flex align={'center'} as={'button'} type={'button'}>
              <Box mr={1}>
                <FaMapMarkerAlt />
              </Box>
              <Text fontWeight={600} fontSize={'sm'}>
                {location.description}
              </Text>
            </Flex>
          </Box>
        ))}
        <Text mt={2} fontSize={'xs'}>
          Powered by Google
        </Text>
      </Box>
    ) : null;
  }

  return (
    <>
      <Box px={[0, 0, 10]}>
        {isMobileViewport ? (
          <MobileTopNav title={'Business Details'} backURL={'/account/home'} />
        ) : (
          <Button as={Link} to={'/account/home'} mt={3}>
            Back
          </Button>
        )}
        <Center mx={isMobileViewport ? 0 : 10}>
          <Box
            w={isMobileViewport ? '100%' : '50%'}
            minH={isMobileViewport ? '90vh' : '100%'}
            bg={background}
            borderRadius={isMobileViewport ? 0 : 10}
            overflow="hidden"
          >
            {isMobileViewport || (
              <Heading mb={5} size={'lg'} paddingLeft={isMobileViewport ? 0 : 6} paddingTop={isMobileViewport ? 0 : 6}>
                Business Details
              </Heading>
            )}
            <Divider />

            <Box onSubmit={handleFormSubmit(handleSubmit)} as={'form'} px={4} data-testid="business-details-form">
              <FormControl mt={4} isInvalid={!!errors.name}>
                <FormLabel>Business name</FormLabel>
                <Input {...register('name')} placeholder="Enter business name" data-testid="business-name" />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.address} mt={4}>
                <FormLabel>Business address</FormLabel>
                <InputGroup>
                  <Input
                    {...register('address')}
                    onChange={(e) => {
                      getPlacePredictions({ input: e.currentTarget.value });
                      setValue('address', e.currentTarget.value);
                      setShouldDisplayAutocompleteOptions(true);
                    }}
                    placeholder={'12/22 George Street, Sydney, NSW'}
                    type="text"
                    data-private
                    autoComplete="off"
                    data-testid="business-address"
                  />
                  <InputRightElement>
                    <AddressPinIcon w={5} h={5} color="customBlue.500" />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
              </FormControl>

              <Box
                onClick={onOpenManualAddressDialog}
                as="button"
                type="button"
                mt={1}
                fontSize="md"
                color="customBlue.500"
                data-testid="manual-address-button"
              >
                Enter address manually
              </Box>

              {/* Site address autocomplete */}
              <Box w={'100%'}>
                {!isPlacePredictionsLoading ? (
                  shouldDisplayAutocompleteOptions && <PredictionsList />
                ) : (
                  <Flex direction={'column'} justify={'center'} align={'center'}>
                    <Spinner size={'lg'} />
                    <Text fontSize={'sm'}>Loading suggestions...</Text>
                  </Flex>
                )}
              </Box>

              {isManualAddressDialogOpen && (
                <ManualAddressDialog
                  currentValues={{
                    country: getValues().country,
                    address: getValues().address,
                    postCode: getValues().postCode,
                  }}
                  isOpen={isManualAddressDialogOpen}
                  onClose={onCloseManualAddressDialog}
                  onSubmitManualAddress={handleSubmitManualAddress}
                />
              )}

              {/* Temporarily hidden until implemented in api */}
              {/* <FormControl isInvalid={!!errors.size} mt={3}>
                <FormLabel>Company Size (Optional)</FormLabel>
                <Select {...register('size')}>
                  <option hidden disabled value="">
                    Select option
                  </option>
                  <option value="5-10">5-10</option>
                  <option value="10-20">10-20</option>
                  <option value="20-50">20-50</option>
                  <option value="50-100">50-100</option>
                  <option value="100+">100+</option>
                </Select>
                <FormErrorMessage>{errors.size?.message}</FormErrorMessage>
              </FormControl> */}

              <FormControl my={4} data-testid="business-logo">
                <FormLabel>Upload logo (Optional)</FormLabel>
                <Text>You logo will be displayed in various parts of the app for you & your team members</Text>

                <UploadBusinessLogo
                  isUploading={isUploading || isDeleting}
                  onDeleteFile={async (file: UploadedFile) => {
                    if (file.isPersisted) {
                      setLogoFile((prevState) => ({ ...prevState, isDeleteModalOpen: true }));
                    } else {
                      setLogoFile((prevState) => ({ ...prevState, logoFile: null }));
                    }
                  }}
                  logoFile={logoFile}
                  onUploadImage={async (fileData: ArrayBuffer, extension: string) => {
                    const base64ImageData = encode(fileData);
                    const imageSizeInKB = Math.ceil((base64ImageData.length * 6) / 8 / 1024);

                    // Limit image size to 2MB
                    if (imageSizeInKB > 2048) {
                      toast({
                        title: 'Image Too Large',
                        description: 'Please try again. Maximum upload size is 2MB.',
                        status: 'error',
                      });
                      return;
                    }

                    setLogoFile((prevState) => ({
                      ...prevState,
                      logoFile: {
                        url: `data:image/${extension};base64,${base64ImageData}`,
                        fileData,
                        extension,
                        isPersisted: false,
                      },
                      isUploading: true,
                    }));

                    try {
                      const updatedTenant = await put<Tenant>(`/fleet/tenants/${user.tenant_id}/logo`, {
                        file_base64: encode(fileData),
                      });
                      setLogoFile((prevState) => ({
                        ...prevState,
                        isUploading: false,
                        /*
                          Since the logo url will not change as image uuid is not changed on update.
                          We need to add timestamp to invalidate browser cache.
                          https://stackoverflow.com/a/13067981
                        */
                        logoFile: {
                          url: `${updatedTenant.logo_url || ''}?current_time=${new Date().getTime()}`,
                          fileData: null,
                          extension: '',
                          isPersisted: true,
                        },
                      }));
                      dispatch(addUserDetails({ tenant: updatedTenant }));
                      toast({
                        title: 'Successfully uploaded logo!',
                        status: 'success',
                      });
                    } catch (error) {
                      toast({
                        title: 'Something went wrong uploading image.',
                        description: 'Please try again. If this continues, please contact support.',
                        status: 'error',
                      });
                      setLogoFile((prevState) => ({
                        ...prevState,
                        isUploading: false,
                        logoFile: null,
                      }));
                    }
                  }}
                />
              </FormControl>

              <CustomButton
                isLoading={isLoading}
                loadingText={'Saving details...'}
                data-testid="save-details-button"
                w="100%"
                rounded={3}
                my={0}
                mt={8}
                mb={BOTTOM_NAV_HEIGHT}
                isDisabled={!isFormFilled || !isDirty}
              >
                Save
              </CustomButton>
            </Box>
          </Box>
        </Center>

        <AlertDialog
          isOpen={isDeleteModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCloseDeleteModal}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent mx={4} data-testid={`delete-dialog`}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Logo
              </AlertDialogHeader>

              <AlertDialogBody>Are you sure you want to delete current logo?</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCloseDeleteModal} data-testid={`delete-cancel-button`}>
                  Cancel
                </Button>
                <Button
                  data-testid={`delete-confirm-button`}
                  colorScheme="red"
                  isLoading={isDeleting}
                  onClick={async () => {
                    setLogoFile((prevState) => ({ ...prevState, isDeleting: true }));

                    try {
                      await del<Tenant>(`/fleet/tenants/${user.tenant_id}/logo`, {});
                      setLogoFile((prevState) => ({
                        ...prevState,
                        isDeleting: false,
                        logoFile: null,
                        isDeleteModalOpen: false,
                      }));
                      dispatch(addUserDetails({ tenant: { ...user.tenant, logo_url: null } }));
                      toast({
                        title: 'Successfully deleted logo!',
                        status: 'success',
                      });
                    } catch (error) {
                      toast({
                        title: 'Something went wrong deleting image.',
                        description: 'Please try again. If this continues, please contact support.',
                        status: 'error',
                      });
                      setLogoFile((prevState) => ({
                        ...prevState,
                        isDeleting: false,
                        isDeleteModalOpen: false,
                      }));
                    }
                  }}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </>
  );
}
