import React from 'react';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Center } from '@chakra-ui/react';
import ReactDOM from 'react-dom';

export default function BarcodeScannerOverlay() {
  return ReactDOM.createPortal(<BarcodeScannerOverlayUI />, document.body);
}

/**
 * UI displayed on top of the camera, when opened to scan a barcode.
 * @constructor
 */
function BarcodeScannerOverlayUI() {
  async function handleClose() {
    await BarcodeScanner.stopScan();
    document.body.classList.remove('qrscanner');
  }

  return (
    <Box className={'scanner-ui'}>
      <Center h={'100%'} w={'100%'} flexDirection={'column'}>
        <Box
          top={'env(safe-area-inset-top)'}
          alignSelf={'flex-start'}
          justifySelf={'flex-start'}
          ml={2}
          mt={2}
          color={'white'}
          position={'absolute'}
          onClick={handleClose}
          as={'button'}
        >
          <ChevronLeftIcon w={'26px'} h={'26px'} />
        </Box>
        <Box
          opacity={1}
          h={'40vh'}
          w={'90vw'}
          rounded={10}
          background={'transparent'}
          boxShadow={'0 0 0 99999px rgba(0, 0, 0, 0.6)'}
          border={'1px solid black'}
        />
      </Center>
    </Box>
  );
}
