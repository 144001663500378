import { OriginalWattwatchersMeter, WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { LONG_DATA_TYPE_TO_TITLE_MAP, SHORT_DATA_TYPE_TO_TITLE_MAP } from './meter-config-constants';
import {
  LongEnergyData,
  LongPowerDataType,
  LongPowerDataTypeToSeries,
  SeriesData,
  ShortEnergyData,
  ShortPowerDataType,
  ShortPowerDataTypeToSeries,
} from './meter-config-types';

/**
 * Converts a Wattwatchers meter object to a Clipsal meter object.
 * @meter The Wattwatchers meter object to convert.
 * @returns The Clipsal meter object.
 */
export function convertOriginalWWResponseToClipsalWWResponse(meter: OriginalWattwatchersMeter): WattwatchersMeter {
  const { comms, id, timezone, model, firmwareVersion } = meter;
  return {
    comms,
    timezone,
    model,
    firmwareVersion,
    ww_device_id: id,
    installed_device_id: null,
    circuits: [],
  };
}

// Empty channel data for the chart
export function buildEmptyChannelData(): SeriesData[] {
  return [
    { name: '', data: [] },
    { name: '', data: [] },
    { name: '', data: [] },
    { name: '', data: [] },
    { name: '', data: [] },
    { name: '', data: [] },
  ];
}

/**
 * Builds the chart data for short power data.
 * @param data The short power data to build the chart data from.
 * @param channels The channels of the meter.
 * @returns The chart data for short power data.
 */
export function buildShortChartData(
  data: ShortEnergyData[],
  channels: OriginalWattwatchersMeter['channels']
): ShortPowerDataTypeToSeries {
  // Mapping for each short power data type to its series data
  const chartData: ShortPowerDataTypeToSeries = Object.entries(SHORT_DATA_TYPE_TO_TITLE_MAP).reduce(
    (acc, [key, name]) => {
      acc[key as ShortPowerDataType] = {
        name,
        data: buildEmptyChannelData(),
      };
      return acc;
    },
    {} as ShortPowerDataTypeToSeries
  );

  // For each data point, add the data to the correct series
  data.forEach((data) => {
    (Object.keys(SHORT_DATA_TYPE_TO_TITLE_MAP) as ShortPowerDataType[]).forEach((key) => {
      // data[key] is a tuple and each of these tuples is indexed by channel index (0 - 5)
      data[key]?.forEach((value, channelIndex) => {
        const series = chartData[key].data[channelIndex];

        if (!series.name) {
          const channel = channels[channelIndex];
          series.name = `${channel.label} (CH ${channelIndex + 1})`;
        }

        series.data.push({
          x: data.timestamp * 1000,
          y: value,
        });
      });
    });
  });

  return chartData;
}

/**
 * Builds the chart data for long power data.
 * @param data The long power data to build the chart data from.
 * @param channels The channels of the meter.
 * @returns The chart data for long power data.
 */
export function buildLongChartData(
  data: LongEnergyData[],
  channels: OriginalWattwatchersMeter['channels']
): LongPowerDataTypeToSeries {
  // Mapping for each long power data type to its series data
  const chartData: LongPowerDataTypeToSeries = Object.entries(LONG_DATA_TYPE_TO_TITLE_MAP).reduce(
    (acc, [key, name]) => {
      acc[key as LongPowerDataType] = {
        name,
        data: buildEmptyChannelData(),
      };
      return acc;
    },
    {} as LongPowerDataTypeToSeries
  );

  // For each data point, add the data to the correct series
  data.forEach((data) => {
    (Object.keys(LONG_DATA_TYPE_TO_TITLE_MAP) as LongPowerDataType[]).forEach((key) => {
      // data[key] is a tuple and each of these tuples is indexed by channel index (0 - 5)
      data[key]?.forEach((value, channelIndex) => {
        const series = chartData[key].data[channelIndex];

        if (!series.name) {
          const channel = channels[channelIndex];
          series.name = `${channel.label} (CH ${channelIndex + 1})`;
        }

        series.data.push({
          x: data.timestamp * 1000,
          y: value,
        });
      });
    });
  });

  return chartData;
}
