import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import packageJSON from '../../package.json';
import { ENDPOINT } from '../common/constants';

/**
 * Configures the Sentry setup for this repo.
 */
export function setupSentry() {
  Sentry.init({
    release: `fleet(${packageJSON.version})@` + import.meta.env.VITE_SENTRY_RELEASE,
    dsn: import.meta.env['VITE_SENTRY_DSN'] as string,
    beforeSend(event) {
      // To pass the session URL with a timestamp to the moment that an error is caught by Sentry,
      // we include the LogRocket URL in Sentry's beforeSend() action
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        if (!event.extra) event.extra = {};
        event.extra['LogRocket'] = logRocketSession;
        return event;
      } else {
        return event;
      }
    },
    // This sets the sample rate to be 5%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.05,
    // If the entire session is not sampled, use the below sample
    // rate to sample sessions when an error occurs
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: [ENDPOINT, 'https://fleet.clipsalcortex.com'],
        beforeNavigate: (context) => {
          return {
            ...context,
            name: window.location.pathname.replace(/\d+/g, ':id').replace(/[a-f0-9]{32}/g, '<hash>'),
          };
        },
      }),
      new Sentry.Replay(),
    ],
    environment: import.meta.env['MODE'],

    // Setting sample rate to 5% for finer control
    tracesSampleRate: 0.05,
  });
}
