export const INVITATION_STATUS_CONFIG = {
  NOT_INVITED: { label: 'Not invited', color: 'gray', iconColor: 'dusk100.300' },
  PENDING: { label: 'Invited', color: 'customDarkOrange', iconColor: 'customDarkOrange.500' },
  CONFIRMED: { label: 'Confirmed', color: 'customGreen', iconColor: 'customGreen.500' },
};

// system owners will be invited from customer details page
export const INVITATION_USER_ROLES = ['SUPER_ADMIN', 'ADMIN', 'STAFF'] as const;

export const USER_ROLES = [...INVITATION_USER_ROLES, 'SYSTEM_OWNER'] as const;
export type UserRole = (typeof USER_ROLES)[number];

export const USER_ROLE_MAP: Record<string, string> = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  STAFF: 'Staff',
  SYSTEM_OWNER: 'System Owner',
};
