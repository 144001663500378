export const MIN_PASSWORD_LENGTH = 8;

export interface PasswordRules {
  minCharsMet: boolean;
  oneLowerCase: boolean;
  oneUpperCase: boolean;
  oneNumber: boolean;
  oneSpecial: boolean;
}

export const RULES_TO_TEXT: Record<keyof PasswordRules, string> = {
  minCharsMet: 'Minimum length 8 characters',
  oneNumber: 'Must contain a number',
  oneSpecial: 'Must contain a special character',
  oneUpperCase: 'Must contain an upper case letter',
  oneLowerCase: 'Must contain a lower case letter',
};
