import { InvitationStatus } from '../../../api/api-user';

export type InviteStatusConfig = {
  label: string;
  color: string;
  iconColor: string;
};

export type InviteStatusConfigMap = Record<InvitationStatus, InviteStatusConfig>;

export const INVITATION_STATUS_CONFIG: InviteStatusConfigMap = {
  UNINVITED: { label: 'Not invited', color: 'gray', iconColor: 'dusk100.300' },
  INVITED: { label: 'Invited', color: 'customDarkOrange', iconColor: 'customDarkOrange.500' },
  CONFIRMED: { label: 'Confirmed', color: 'customGreen', iconColor: 'customGreen.500' },
};

export const EMPTY_CUSTOMER_TEMPLATE = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};
