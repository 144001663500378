import { cloneDeep } from 'lodash';

import { VoltageReference, WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { ElectricalConfiguration } from '../../../api/api-site';

const availableVoltagesOnSitePhase: Record<ElectricalConfiguration, VoltageReference[]> = {
  '1ph': ['P1'],
  '2ph': ['P1', 'P2'],
  '3ph': ['P1', 'P2', 'P3'],
};

export function checkMeterPhasing(sitePhasing: ElectricalConfiguration, meters: WattwatchersMeter[]) {
  let isCorrectlyPhased = true;

  // 3 phase sites can have any voltage values, so ignore it
  if (sitePhasing === '3ph') return { isCorrectlyPhased, updatedMeters: meters };

  const availableVoltages = availableVoltagesOnSitePhase[sitePhasing];

  const updatedMeters = meters.map((rawMeter) => {
    const meter = cloneDeep(rawMeter);

    meter.circuits.forEach((circuit) => {
      const circuitVoltageReference = circuit.pending?.voltageReference || circuit.voltage_reference;
      const isValidPhasing = availableVoltages.includes(circuitVoltageReference);
      if (!isValidPhasing) {
        circuit.voltage_reference = 'P1';
        isCorrectlyPhased = false;
      }
    });

    return meter;
  });

  return { isCorrectlyPhased, updatedMeters };
}
