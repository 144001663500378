import { addMinutes, endOfDay, format, isBefore, parse } from 'date-fns';

import { formatSnakeCaseToTitleCase } from '../../../../utils/string-formatting,';

export type DataCorrectionType = 'energyUsage' | 'costs' | 'savings' | 'batteryData';

export const DATA_CORRECTION_TYPE_OPTIONS: { value: DataCorrectionType; label: string }[] = [
  { value: 'energyUsage', label: 'Energy Usage' },
  { value: 'costs', label: 'Costs' },
  { value: 'savings', label: 'Savings' },
  { value: 'batteryData', label: 'Battery' },
];

export type DataCorrectionState = {
  siteId: number;
  type: DataCorrectionType;
  startDate: string;
  endDate: string;
};

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

// API response format - using this format to be more flexible
export type ChartData = Record<string, string | number>[];

// Helper function to clean the reading_datetime and remove the invalid "_XXX" suffix
function cleanDateTimeString(dateTime: string): string {
  return dateTime.replace(/_\d+$/, ''); // Remove any '_XXX' at the end of the string
}

// This function ensures that the data is available till the end of the day
// when the date range is the same day and the data is not available for the entire day
function ensureDataTillEndOfDay(data: ChartData, dateKey: string): ChartData {
  // If there's no data, return an empty array
  if (data.length === 0) return data;

  const chartData = [...data];

  // Create a default data object with all keys set to 0
  const defaultData = { ...chartData[0] };
  for (const key in defaultData) {
    defaultData[key] = key === dateKey ? '' : 0;
  }

  // Parse the first date to find the day we're working with
  const firstDate = parse(cleanDateTimeString(chartData[0][dateKey] as string), DATE_FORMAT, new Date());
  const lastEntryDate = endOfDay(firstDate);

  // Create a map for quick lookup of existing data
  const existingDates = new Set(chartData.map((entry) => cleanDateTimeString(entry[dateKey] as string)));

  let currentDate = firstDate;

  // Loop through 5 minutes interval till the end of the day and add missing entries
  while (isBefore(currentDate, lastEntryDate)) {
    const formattedDate = format(currentDate, DATE_FORMAT);

    // If the date doesn't exist in chartData, push a default entry
    if (!existingDates.has(formattedDate)) {
      chartData.push({
        ...defaultData,
        [dateKey]: formattedDate,
      });
    }

    // Add 5 minutes to the current date
    currentDate = addMinutes(currentDate, 5);
  }

  return chartData;
}

// Convert the API response to a format that Highcharts can understand
export const transformChartData = (chartData: ChartData, { startDate, endDate, type }: DataCorrectionState) => {
  const dates: string[] = [];

  // dateKey is not consistent in the API response
  const firstChartData = chartData[0];
  let dateKey = 'reading_datetime';
  if (firstChartData && 'reading_date' in firstChartData) dateKey = 'reading_date';
  if (firstChartData && 'reading_datetime_device_id' in firstChartData) dateKey = 'reading_datetime_device_id';

  const shouldAdjustData = (type === 'batteryData' || type === 'energyUsage') && startDate === endDate;
  const adjustedChartData = shouldAdjustData ? ensureDataTillEndOfDay(chartData, dateKey) : chartData;

  const transformedChartData = adjustedChartData.reduce((acc, curValue) => {
    dates.push(curValue[dateKey] as string);

    Object.entries(curValue).forEach(([key, value]) => {
      // Skip the reading_datetime key or clipsal_solar_id key
      if (key === dateKey || key === 'clipsal_solar_id') return;

      // Format the key to be more human-readable
      const formattedKey = formatSnakeCaseToTitleCase(key);
      if (!acc[formattedKey]) acc[formattedKey] = [];
      acc[formattedKey].push(value);
    });
    return acc;
  }, {} as Record<string, (string | number)[]>);

  const seriesData = Object.entries(transformedChartData).reduce((acc, [key, value]) => {
    acc.push({ name: key, data: value, type: 'column' });
    return acc;
  }, [] as Highcharts.SeriesColumnOptions[]);

  return { dates, seriesData };
};
