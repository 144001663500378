import 'yup-phone';
import 'react-phone-input-2/lib/bootstrap.css';
import '../../../styles/react-phone-input-2.css';

import React from 'react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Heading, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { ReactComponent as BubbleBackground } from '../../../assets/images/bubble_bg.svg';
import { UsersIcon } from '../../../styles/custom-icons';
import { WizardSubRouteCardWrapper } from '../../wizard/WizardSubRouteCardWrapper';
import { selectSite } from '../siteSlice';
import InviteCustomerModal from './InviteCustomerModal';
import { useInvitedSiteUsers, useSiteImages, useSiteUsers } from './siteUsersApi';
import UploadSiteImage from './UploadSiteImage';
import UserDetails from './UserDetails';

const INITIAL_STATE = {
  isLoaded: false,
  isEditMode: false,
  hasNewUsers: false,
  hasNewImages: false,
  isUploading: false,
};

export type State = typeof INITIAL_STATE;

export default function CustomerDetails() {
  const site = useSelector(selectSite);
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const { users, isLoading: isLoadingUsers } = useSiteUsers();
  const { invitedUsers, isLoading: isLoadingInvitedUsers } = useInvitedSiteUsers();
  const totalUsers = users.length + invitedUsers.length;
  const hasInvitedUsers = totalUsers > 0;
  const { siteImages, isLoading: isLoadingSiteImages } = useSiteImages();
  const hasUploadedImages = siteImages.length > 0;
  const isLoaded = site.isLoaded && !isLoadingUsers && !isLoadingInvitedUsers && !isLoadingSiteImages;

  const getUploadImagesStatusIcon = () => {
    if (hasUploadedImages) return <CheckCircleIcon mr={2} color={'primaryBranding.500'} w={5} h={5} />;
    return hasInvitedUsers ? (
      <WarningIcon mr={2} color={'red.500'} w={6} h={6} />
    ) : (
      <Box w={6} h={6} rounded={50} border="3px solid" borderColor="gray.500" />
    );
  };

  return (
    <WizardSubRouteCardWrapper
      backgroundColor={!isLoaded ? backgroundColor : 'transparent'}
      p={[0]}
      borderTopRadius={!isLoaded ? 20 : [0, 0, 20]}
    >
      {!isLoaded ? (
        <CenteredLoader />
      ) : (
        <Box data-testid="customer-details-form" className="customer-details-form" minHeight={'100%'} pb={2}>
          <Box mb={4}>
            <Flex bg={backgroundColor} p={3} mb={2} justify="space-between" align="center">
              <Heading size={'md'}>Upload Photos</Heading>
              {getUploadImagesStatusIcon()}
            </Flex>
            <UploadSiteImage />
          </Box>

          <Box opacity={hasUploadedImages ? 1 : 0.6}>
            <Flex bg={backgroundColor} p={3} mb={2} justify="space-between" align="center">
              <Heading size={'md'}>Invite Customers</Heading>
              <InviteCustomerModal isDisabled={!hasUploadedImages} />
            </Flex>
            <Box>
              {totalUsers ? (
                <Heading size={'sm'} mb={2} mx={2}>
                  Customers that you have invited:
                </Heading>
              ) : (
                <Center flexDirection="column" my={4}>
                  <Box position={'relative'}>
                    <UsersIcon
                      w={70}
                      h={70}
                      color="dusk100.300"
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%,-50%)"
                      z={2}
                    />
                    <Icon as={BubbleBackground} h={120} w={120} />
                  </Box>

                  <Center flexDirection="column" mt={2}>
                    <Heading size={'sm'}>No invited customers yet!</Heading>
                    <Text color="dusk100.400" mt={1} mb={2} textAlign="center">
                      Invited customers will be listed here.
                    </Text>
                  </Center>
                </Center>
              )}

              {users.map(({ user }, index) => {
                const { user_email, user_first_name, user_last_name, user_full_name, user_id, user_name } = user;
                return (
                  <UserDetails
                    key={index}
                    userId={user_id}
                    username={user_name}
                    email={user_email}
                    firstName={user_first_name}
                    lastName={user_last_name}
                    fullName={user_full_name}
                    invitiationStatus="CONFIRMED"
                    index={index}
                    isDisabled={!hasUploadedImages}
                  />
                );
              })}

              {invitedUsers.map(({ user_email, user_first_name, user_last_name, user_name }, index) => (
                <UserDetails
                  key={index}
                  username={user_name}
                  email={user_email}
                  firstName={user_first_name}
                  lastName={user_last_name}
                  invitiationStatus="INVITED"
                  index={index}
                  isDisabled={!hasUploadedImages}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </WizardSubRouteCardWrapper>
  );
}
