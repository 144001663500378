import { useFormContext } from 'react-hook-form';

import { OriginalWattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

/*  Simplify using for context */
export const useMeterFormContext = () => {
  const methods = useFormContext<OriginalWattwatchersMeter>();

  // when in cypress tests isDirty is not updated for some reason
  // as per react-hook-form, when default values and form values
  // are different, form should be dirty. But it is not dirty in cypress tests.
  // It is dirty when testing in browser manually.
  // So it's better to check if there are any dirty fields to check if form is dirty
  const isDirtyForm = !!Object.keys(methods.formState.dirtyFields).length;

  return { ...methods, errors: methods.formState.errors, isDirtyForm };
};
