import '../fleet-dashboard/date-range-type-picker.css';

import React from 'react';
import { Box, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

import MultiToggleSwitch from '../../common/components/MultiToggleSwitch';

enum BatteryDataType {
  Before = 'BEFORE',
  After = 'AFTER',
}

export { BatteryDataType };

const DATE_RANGE_OPTIONS = [
  {
    label: 'Before Battery',
    value: BatteryDataType.Before,
  },
  {
    label: 'After battery',
    value: BatteryDataType.After,
  },
];

type Props = {
  selectedBatteryDataType: BatteryDataType;
  setSelectedBatteryDataType: (newValue: BatteryDataType) => void;
};

export default function BatteryDataTypePicker({ selectedBatteryDataType, setSelectedBatteryDataType }: Props) {
  const isDesktopViewport = useBreakpointValue({
    base: false,
    lg: true,
  });
  const switchClassName = useColorModeValue(
    `light-mode-toggle-switch${isDesktopViewport ? '' : '-1'}`,
    `dark-mode-toggle-switch${isDesktopViewport ? '' : '-1'}`
  );

  return (
    <Box className={switchClassName} w="100%">
      <MultiToggleSwitch
        switchOptions={DATE_RANGE_OPTIONS}
        onChange={(newValue) => setSelectedBatteryDataType(newValue as BatteryDataType)}
        value={selectedBatteryDataType}
      />
    </Box>
  );
}
