import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import { PrintIcon } from 'clipsal-cortex-icons/src/custom-icons';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import AlertsNotificationIconButton from '../../common/components/AlertsNotificationIconButton';
import MobileTopNav from '../../common/components/MobileTopNav';
import { BOTTOM_NAV_HEIGHT } from '../../common/constants';
import FleetDashboardProfileInfo from '../fleet-dashboard/FleetDashboardProfileInfo';
import AverageDailyEnergyChart from './AverageDailyEnergyChart';
import { useBatterySimulations, useGetSite, useLoadProfiles } from './batteryUpsellApi';
import EstimatedBillsWidget from './EstimatedBillsWidget';
import PrintPdfButton from './PrintPdfButton';
import SelfPoweredWidget from './SelfPoweredWidget';
import SiteStats from './SiteStats';

const BatteryUpsellSimulations = () => {
  const { id } = useParams<{ id: string }>();
  const { site } = useGetSite(Number(id));
  const { simulations, isLoading } = useBatterySimulations();
  const { loadProfiles } = useLoadProfiles(simulations.map((simulation) => simulation.battery_simulation_id));
  const isLoaded = !isLoading;
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const { iconBackground } = useColorModeValue(
    {
      textColor: theme.colors.gray[900],
      textHoverColor: 'black',
      iconBackground: 'white',
      background: 'white',
      chartLabelColor: theme.colors.gray[500],
    },
    {
      textColor: theme.colors.dusk100[50],
      textHoverColor: 'white',
      iconBackground: 'whiteAlpha.300',
      background: 'gray.900',
      chartLabelColor: theme.colors.gray[400],
    }
  );

  return (
    <Box
      px={[1, 1, 5]}
      pt={isMobileViewport ? 0 : 3}
      pb={3}
      mb={isMobileViewport ? BOTTOM_NAV_HEIGHT : undefined}
      data-testid="battery-upsell-site-simulations"
      className="battery-upsell-site-dashboard"
    >
      {isMobileViewport && site ? (
        <MobileTopNav title={site.site_name || 'Batteries Overview'} onGoBack={() => navigate(-1)}>
          <PrintPdfButton
            {...{ isLoaded, simulations, loadProfiles, site }}
            shadow={'none'}
            right={4}
            top={1}
            position={'absolute'}
            loadingText=""
            bg="transparent"
            color="black"
            _dark={{ bg: 'transparent', color: 'white' }}
          >
            <PrintIcon w={5} h={5} />
          </PrintPdfButton>
        </MobileTopNav>
      ) : (
        <>
          <Flex mt={5} align={'center'} data-testid="desktop-top-nav">
            <Box>
              <Heading mr={5} size={'md'}>
                Batteries Overview
              </Heading>
              <Flex fontWeight={500} mt={4}>
                <Text
                  color="customLinkBlue.500"
                  _hover={{ textDecoration: 'underline' }}
                  cursor={'pointer'}
                  onClick={() => navigate(-1)}
                  data-testid="battery-ready-site-link-go-back"
                >
                  Battery ready sites
                </Text>
                <Text ml={2}>{isLoaded ? `> ${site?.site_name}` : ''}</Text>
              </Flex>
            </Box>

            <Flex align="center" ml={'auto'} mr={2}>
              {site && <PrintPdfButton {...{ isLoaded, simulations, loadProfiles, site }} />}
              <Box ml={4} borderRadius={100} background={iconBackground} shadow="lg">
                <AlertsNotificationIconButton />
              </Box>
              <Box ml={4}>
                <FleetDashboardProfileInfo />
              </Box>
            </Flex>
          </Flex>
        </>
      )}

      <Box data-testid="battery-upsell-simulation-accordion">
        {!isLoaded ? (
          <Box h={'50vh'}>
            <CenteredLoader />
          </Box>
        ) : (
          <Accordion defaultIndex={0} allowToggle mt={isMobileViewport ? 0 : 4}>
            {simulations.map((simulation, index) => {
              const idString = simulation.battery_simulation_id.toString();
              const loadProfile = loadProfiles[idString];
              return (
                <AccordionItem
                  data-testid={`simulation-${index}`}
                  key={`simulation-${index}`}
                  border="none"
                  mt={isMobileViewport ? 0 : 2}
                >
                  <AccordionButton
                    data-testid={`simulation-battery-${index}`}
                    role={'button'}
                    as={Box}
                    borderBottom="1px solid"
                    borderColor="gray.300"
                    pl={0}
                  >
                    <Flex w={'100%'} justify={'space-between'} align={'center'}>
                      <Flex>
                        <Heading data-testid={`simulation-battery-model`} size={'md'} fontWeight={500}>
                          {simulation.battery_model}
                        </Heading>
                      </Flex>

                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel pb={4} px={0}>
                    <Flex width="100%" flexDirection={'column'}>
                      <Flex w="100%" mb={4} mt={2} columnGap={4} rowGap={4} wrap={'wrap'}>
                        <SiteStats {...{ isMobileViewport, simulation }} />
                      </Flex>

                      <AverageDailyEnergyChart {...{ isMobileViewport, simulation, loadProfile }} />

                      <Flex width={'100%'} mt={4} wrap={'wrap'}>
                        <SelfPoweredWidget {...{ isMobileViewport, simulation }} />
                        {/* Will visit later after initial release */}
                        {/* <BatteryBackupWidget {...{ isMobileViewport }} /> */}
                        <EstimatedBillsWidget {...{ isMobileViewport, simulation }} />
                      </Flex>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        )}
      </Box>
    </Box>
  );
};

export default BatteryUpsellSimulations;
