import React from 'react';

import { Children } from '../common/types/types';
import AlertDrawerContextProvider from '../features/dashboard/alert-drawer/AlertDrawerContextProvider';
import SupportDrawerContextProvider from './SupportDrawerContext';

const CombinedContextProvider = ({ children }: Children) => {
  return (
    <SupportDrawerContextProvider>
      <AlertDrawerContextProvider>{children}</AlertDrawerContextProvider>
    </SupportDrawerContextProvider>
  );
};

export default CombinedContextProvider;
