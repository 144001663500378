import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useOutsideClick,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import { debounceEvent } from '../../../../utils/component-helpers';
import { useSearchSitesQuery } from '../meter-configuration/meterConfigApi';

type SiteSearchInputProps = {
  siteId: number;
  onUpdateSiteId: (siteId: number, searchLabel: string) => void;
};

export const SiteSearchInput = ({ siteId, onUpdateSiteId }: SiteSearchInputProps) => {
  const [searchParams] = useSearchParams();
  const [{ searchTerm, isResultMenuOpen }, setState] = useState({
    searchTerm: (Number(searchParams.get('siteId')) || '').toString(),
    isResultMenuOpen: false,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);
  useOutsideClick({
    ref: tableRef,
    handler: () => setState((prevState) => ({ ...prevState, isResultMenuOpen: false })),
  });
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const { isLoading, isFetching, data } = useSearchSitesQuery(searchTerm, { skip: !searchTerm });
  const searchResults = data?.data ?? [];
  const isSearching = isLoading || isFetching;

  useEffect(() => {
    const urlSearchLabel = searchParams.get('searchLabel') ?? '';
    if (urlSearchLabel && inputRef?.current) inputRef.current.value = urlSearchLabel;
  }, []);

  const handleSearch = React.useMemo(
    () =>
      debounceEvent(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value.trim();
        setState((prevState) => ({ ...prevState, searchTerm, isResultMenuOpen: !!searchTerm }));
      }, 600),
    []
  );

  return (
    <FormControl zIndex={2} maxW={isMobileViewport ? undefined : 600} minW={isMobileViewport ? undefined : 312}>
      <FormLabel>Search site by id, name or address</FormLabel>
      <InputGroup size="md" position="relative" ref={tableRef}>
        <Input
          data-testid={'site-search-input'}
          ref={inputRef}
          onFocus={() => setState((prevState) => ({ ...prevState, isResultMenuOpen: !!searchResults.length }))}
          onChange={handleSearch}
          py={'20px'}
          placeholder={`Enter id, name or address of the site`}
        />
        <InputRightElement height="100%">
          {isSearching ? (
            <Spinner my="auto" color="gray.500" size="sm" />
          ) : (
            isResultMenuOpen && (
              <IconButton
                ref={closeButtonRef}
                aria-label="Reset Search"
                data-testid="reset-search-button"
                variant={'ghost'}
                icon={<CloseIcon w={2} h={2} />}
                onClick={() => {
                  if (inputRef?.current) inputRef.current.value = '';
                  setState((prevState) => ({ ...prevState, searchTerm: '', isResultMenuOpen: false }));
                  onUpdateSiteId(0, '');
                }}
              />
            )
          )}
        </InputRightElement>

        {isResultMenuOpen && searchTerm && (
          <TableContainer
            shadow=" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            bg={'white'}
            _dark={{ bg: 'gray.900' }}
            borderRadius="0px 0px 10px 10px"
            position="absolute"
            top={43}
            left={0}
            zIndex={1}
            w={'100%'}
            data-testid="search-results-table"
          >
            {!searchResults.length && (
              <Text py={12} align="center">
                {isSearching ? 'Searching...' : 'No search results found. Please try again.'}
              </Text>
            )}

            {!!searchResults.length && (
              <Table variant="simple" size="sm" colorScheme="blue">
                <Thead>
                  <Tr>
                    <Th border={'0px !important'} fontSize="13px" pt={2}>
                      ID
                    </Th>
                    <Th border={'0px !important'} fontSize="13px">
                      Name
                    </Th>
                    <Th border={'0px !important'} fontSize="13px">
                      Address
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {searchResults.map((data, idx) => {
                    const isSelected = data.clipsal_solar_id === siteId;

                    return (
                      <Tr
                        data-testid={`search-result-tr-${idx}`}
                        key={(data.site_name ?? 'site-') + idx}
                        position="relative"
                        bg={isSelected ? 'green.100' : undefined}
                        _hover={{
                          bg: 'blue.50',
                          cursor: 'pointer',
                        }}
                        _dark={{
                          bg: isSelected ? 'green.900' : undefined,
                          _hover: {
                            bg: 'blue.900',
                          },
                        }}
                        onClick={() => {
                          setState((prevState) => ({ ...prevState, isResultMenuOpen: false }));
                          const searchLabel = `${data.clipsal_solar_id}: ${data.site_name}`;
                          if (inputRef?.current) inputRef.current.value = searchLabel;
                          onUpdateSiteId(data.clipsal_solar_id, searchLabel);
                        }}
                      >
                        <Td border={'0px !important'}>{data.clipsal_solar_id || 'N/A'}</Td>
                        <Td border={'0px !important'} maxW="120px" isTruncated>
                          {data.site_name || 'N/A'}
                        </Td>
                        <Td border={'0px !important'} maxW={isMobileViewport ? 180 : 250} isTruncated>
                          {data.site_address_line1 || 'N/A'}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
          </TableContainer>
        )}
      </InputGroup>
    </FormControl>
  );
};
