import React, { useEffect, useState } from 'react';
import { CheckIcon, EditIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Spinner,
} from '@chakra-ui/react';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import { MdAutoGraph } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { BatteryUpgradeOpportunity, BatteryUpsellInvitationStatus } from '../../api/api-battery-upsell';
import { EyeIcon } from '../../styles/custom-icons';
import { openCortexAppAtRoute } from '../dashboard/dashboard-helpers';
import { usePatchSiteInvitationStatusMutation } from './batteryUpsellApi';
import ContactCustomerModal from './ContactCustomerModal';

const COMMON_BUTTON_PROPS = {
  _hover: { bg: 'gray.100' },
  _dark: {
    _hover: {
      bg: 'gray.900',
    },
  },
};

const COMMON_ICON_PROPS = {
  w: 5,
  h: 5,
};

const UPSELL_INVITATION_STATUS_CONFIG: { label: string; value: BatteryUpsellInvitationStatus }[] = [
  { label: 'Interested', value: 'interested' },
  { label: 'Not Interested', value: 'not_interested' },
  { label: 'Not Contacted', value: 'not_set' },
];

type BatteryUpsellActionMenuProps = { siteData: BatteryUpgradeOpportunity; dataIndex: number };

const BatteryUpsellActionMenu = ({ siteData, dataIndex }: BatteryUpsellActionMenuProps) => {
  const [patchSiteInvitationsStatus, { isLoading }] = usePatchSiteInvitationStatusMutation();
  const [{ isContactModalOpen, invitationStatus }, setState] = useState({
    isContactModalOpen: false,
    invitationStatus: siteData.invitation_status,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setState((prevState) => ({ ...prevState, invitationStatus: siteData.invitation_status }));
  }, [siteData]);

  return (
    <Menu closeOnSelect={false} isLazy>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon as={IoEllipsisVerticalSharp} />}
        variant="unstyled"
        p={1}
        rounded={20}
        minW={7}
        h={7}
        data-testid={`battery-upsell-action-menu-button-${dataIndex}`}
        {...COMMON_BUTTON_PROPS}
        _dark={{
          _hover: {
            bg: 'gray.700',
          },
        }}
      />
      <Portal>
        <MenuList zIndex={10} border="2px solid" borderColor="gray.200" py={0} data-testid="action-menu-list">
          <MenuItem
            icon={<PhoneIcon w={3.5} h={3.5} />}
            {...COMMON_BUTTON_PROPS}
            data-testid="open-contact-customer-modal-button"
            onClick={() => setState((prevState) => ({ ...prevState, isContactModalOpen: true, contactType: 'phone' }))}
          >
            Contact Information
          </MenuItem>
          <MenuDivider my={0} />
          <MenuItem
            icon={<Icon as={MdAutoGraph} {...COMMON_ICON_PROPS} />}
            {...COMMON_BUTTON_PROPS}
            data-testid="view-simulations-button"
            onClick={() => navigate(`/upsells/site/${siteData.clipsal_solar_id}/simulations`)}
          >
            View Simulations
          </MenuItem>
          <MenuDivider my={0} />
          <MenuItem
            icon={<EditIcon w={4} h={4} />}
            {...COMMON_BUTTON_PROPS}
            data-testid="edit-site-in-fleet-button"
            onClick={() => navigate(`/site/${siteData.clipsal_solar_id}/start?redirect=true`)}
          >
            Edit Site on Fleet
          </MenuItem>
          <MenuDivider my={0} />
          <MenuItem
            icon={<EyeIcon {...COMMON_ICON_PROPS} />}
            {...COMMON_BUTTON_PROPS}
            data-testid="view-site-in-cortex-button"
            onClick={() => openCortexAppAtRoute(siteData.clipsal_solar_id)}
          >
            View Site in Cortex
          </MenuItem>
          <MenuDivider my={0} />
          <MenuOptionGroup
            value={invitationStatus}
            title="Interest in Battery Upgrade"
            type="radio"
            onChange={(value) => {
              setState((prevState) => ({ ...prevState, invitationStatus: value as BatteryUpsellInvitationStatus }));
              patchSiteInvitationsStatus({
                invitationStatus: value as BatteryUpsellInvitationStatus,
                siteId: siteData.clipsal_solar_id,
              });
            }}
          >
            {UPSELL_INVITATION_STATUS_CONFIG.map(({ label, value }, index) => {
              const isSelected = invitationStatus === value;
              return (
                <MenuItemOption
                  key={`${value}-${index}`}
                  value={value}
                  {...COMMON_BUTTON_PROPS}
                  isDisabled={isLoading}
                  data-testid={`select_${value}`}
                  aria-selected={isSelected}
                  icon={isLoading && isSelected ? <Spinner size="xs" /> : <CheckIcon w={3} />}
                >
                  {label}
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
      {isContactModalOpen && (
        <ContactCustomerModal
          {...{
            isOpen: isContactModalOpen,
            onClose: () => setState((prevState) => ({ ...prevState, isContactModalOpen: false })),
            site: siteData,
          }}
        />
      )}
    </Menu>
  );
};

export default BatteryUpsellActionMenu;
