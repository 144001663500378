import React from 'react';
import { Box, Button, Center, useColorModeValue } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/hooks';
import CustomButton from '../../../../common/components/CustomButton';
import { COMPONENT_MIN_HEIGHT } from '../../../../common/constants';
import { selectSite } from '../../siteSlice';
import { GRID_CIRCUIT_TYPES } from '../meter-setup-helpers';
import {
  selectAllCircuits,
  selectAppliances,
  selectTestStatusV2,
  updateMeterStatusFromWebSocketV2,
  updateMeterStatusFromWebSocketV2IgnoreSkipped,
} from '../meterSetupSlice';
import SubRouteTopNav from '../SubRouteTopNav';
import { selectWebSocket } from '../webSocketSlice';
import ApplianceStatusCard from './ApplianceStatusCard';
import { ApplianceLoadResult, WebSocketMessage } from './circuit-status-websocket-types';
import { CircuitTestStatus, TestTypeV2 } from './test-types';
import { TYPE_TO_TEST_TYPE } from './use-meter-test-handlers';

export default function LoadAppliances() {
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const webSocket = useSelector(selectWebSocket);
  const testStatus = useSelector(selectTestStatusV2);
  const appliances = useSelector(selectAppliances);
  const loadAppliances = appliances.filter(
    (a) => a.appliance_type.includes('load_') && !GRID_CIRCUIT_TYPES.includes(a.appliance_type)
  );
  const allCircuits = useSelector(selectAllCircuits);
  const dispatch = useAppDispatch();
  const { buttonTextColor, secondaryButtonTextColor } = useColorModeValue(
    { buttonTextColor: 'white', secondaryButtonTextColor: 'customBlack.500' },
    { buttonTextColor: 'black', secondaryButtonTextColor: 'dusk100.200' }
  );

  function runAllLoadTest() {
    const newTestStatus = cloneDeep(testStatus);
    const loadCircuitIds = loadAppliances.reduce((acc, appliance) => [...acc, ...appliance.circuits], [] as number[]);
    const loadApplianceIds = loadAppliances.reduce(
      (acc, appliance) => [...acc, appliance.appliance_id],
      [] as number[]
    );
    const circuits = allCircuits.filter((c) => loadCircuitIds.includes(c.clipsal_circuit_id));

    loadAppliances?.forEach((loadAppliance) => {
      let loadTestAppliance = newTestStatus.loads?.find(
        (loadTestApplianceTest) => loadTestApplianceTest.appliance_id === loadAppliance.appliance_id
      );

      if (!loadTestAppliance) {
        // Create an empty test result in the case of a load with no test results yet.

        newTestStatus.loads?.push({
          appliance_id: loadAppliance.appliance_id,
          appliance_type: loadAppliance.appliance_type,
          [TestTypeV2.Polarity]: {
            circuits: [],
            status: CircuitTestStatus.Uninitialized,
          },
          [TestTypeV2.PowerFactor]: {
            circuits: [],
            status: CircuitTestStatus.Uninitialized,
          },
          last_updated_utc: 0,
          status: CircuitTestStatus.Uninitialized,
        });

        loadTestAppliance = newTestStatus.loads?.[newTestStatus.loads.length - 1] as ApplianceLoadResult;
      }

      loadTestAppliance.status = CircuitTestStatus.InProgress;
      loadTestAppliance[TestTypeV2.Polarity].status = CircuitTestStatus.InProgress;
      loadTestAppliance[TestTypeV2.PowerFactor].status = CircuitTestStatus.InProgress;

      if (loadTestAppliance[TestTypeV2.Polarity].circuits.length) {
        loadTestAppliance[TestTypeV2.Polarity].circuits.forEach(
          (c) => (c.polarity_test_status = CircuitTestStatus.InProgress)
        );
      } else {
        // No initialized circuits yet, create some empty ones and set them to be in progress
        loadTestAppliance[TestTypeV2.Polarity].circuits = circuits.map((c) => {
          return {
            polarity_test_status: CircuitTestStatus.InProgress,
            power_factor: 0,
            current_a: 0,
            circuit_id: c.ww_circuit_id,
            power_kw: 0,
            id: c.clipsal_circuit_id,
            clipsal_monitors: c.clipsal_monitors,
          };
        });
      }

      if (loadTestAppliance[TestTypeV2.PowerFactor].circuits.length) {
        loadTestAppliance[TestTypeV2.PowerFactor].circuits.forEach(
          (c) => (c.power_factor_test_status = CircuitTestStatus.InProgress)
        );
      } else {
        // No initialized circuits yet, create some empty ones and set them to be in progress
        loadTestAppliance[TestTypeV2.PowerFactor].circuits = circuits.map((c) => {
          return {
            power_factor_test_status: CircuitTestStatus.InProgress,
            power_factor: 0,
            current_a: 0,
            circuit_id: c.ww_circuit_id,
            power_kw: 0,
            id: c.clipsal_circuit_id,
            clipsal_monitors: c.clipsal_monitors,
          };
        });
      }
    });
    dispatch(
      updateMeterStatusFromWebSocketV2IgnoreSkipped({
        data: newTestStatus,
        typeToIgnoreSkipped: 'loads',
        applianceIdToIgnoreSkipped: undefined,
      })
    );
    const message: WebSocketMessage = {
      action: 'runTests',
      clipsal_solar_id: site.clipsal_solar_id,
      test_types: [TYPE_TO_TEST_TYPE['loads']],
      appliance_ids: loadApplianceIds,
    };

    webSocket?.sendJsonMessage<WebSocketMessage>(message);
  }

  function skipAllLoadTests() {
    const newTestStatus = cloneDeep(testStatus);
    const loadCircuitIds = loadAppliances.reduce((acc, appliance) => [...acc, ...appliance.circuits], [] as number[]);
    const circuits = allCircuits.filter((c) => loadCircuitIds.includes(c.clipsal_circuit_id));

    loadAppliances?.forEach((loadAppliance) => {
      let loadTestAppliance = newTestStatus.loads?.find(
        (loadTestApplianceTest) => loadTestApplianceTest.appliance_id === loadAppliance.appliance_id
      );

      if (!loadTestAppliance) {
        // Create an empty test result in the case of a load with no test results yet.
        newTestStatus.loads?.push({
          appliance_id: loadAppliance.appliance_id,
          appliance_type: loadAppliance.appliance_type,
          [TestTypeV2.Polarity]: {
            circuits: [],
            status: CircuitTestStatus.Skipped,
          },
          [TestTypeV2.PowerFactor]: {
            circuits: [],
            status: CircuitTestStatus.Skipped,
          },
          last_updated_utc: 0,
          status: CircuitTestStatus.Skipped,
        });

        loadTestAppliance = newTestStatus.loads?.[newTestStatus.loads.length] as ApplianceLoadResult;
      }

      loadTestAppliance.status = CircuitTestStatus.Skipped;
      loadTestAppliance[TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
      loadTestAppliance[TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;

      if (loadTestAppliance[TestTypeV2.Polarity].circuits.length) {
        loadTestAppliance[TestTypeV2.Polarity].circuits.forEach(
          (c) => (c.polarity_test_status = CircuitTestStatus.Skipped)
        );
      } else {
        // No initialized circuits yet, create some empty ones and set them to be in progress
        loadTestAppliance[TestTypeV2.Polarity].circuits = circuits.map((c) => {
          return {
            polarity_test_status: CircuitTestStatus.Skipped,
            power_factor: 0,
            current_a: 0,
            circuit_id: c.ww_circuit_id,
            power_kw: 0,
            id: c.clipsal_circuit_id,
            clipsal_monitors: c.clipsal_monitors,
          };
        });
      }

      if (loadTestAppliance[TestTypeV2.PowerFactor].circuits.length) {
        loadTestAppliance[TestTypeV2.PowerFactor].circuits.forEach(
          (c) => (c.power_factor_test_status = CircuitTestStatus.Skipped)
        );
      } else {
        // No initialized circuits yet, create some empty ones and set them to be in progress
        loadTestAppliance[TestTypeV2.PowerFactor].circuits = circuits.map((c) => {
          return {
            power_factor_test_status: CircuitTestStatus.Skipped,
            power_factor: 0,
            current_a: 0,
            circuit_id: c.ww_circuit_id,
            power_kw: 0,
            id: c.clipsal_circuit_id,
            clipsal_monitors: c.clipsal_monitors,
          };
        });
      }
    });
    dispatch(updateMeterStatusFromWebSocketV2(newTestStatus));
  }

  return (
    <Box data-testid="appliance-loads-list" minHeight={COMPONENT_MIN_HEIGHT}>
      <SubRouteTopNav onGoBack={() => navigate(-1)} title="Load CT Test" />

      <Center flexDir="column" w="100%" px={[4, 6]}>
        {loadAppliances.map((appliance, i) => (
          <Box w={['100%', '100%', '80%', '70%', '50%']} key={i} my={3}>
            <ApplianceStatusCard appliance={appliance} />
          </Box>
        ))}
      </Center>

      <Center py={5} flexDirection="column">
        <CustomButton color={buttonTextColor} data-testid="run-test" onClick={runAllLoadTest} my={2}>
          Run all load tests
        </CustomButton>

        <Center w={'100%'}>
          <Button
            data-testid="skip-test"
            w={['75%', '75%', '40%']}
            onClick={skipAllLoadTests}
            color={secondaryButtonTextColor}
            variant={'ghost'}
          >
            Skip all load tests
          </Button>
        </Center>
      </Center>
    </Box>
  );
}
