import React from 'react';
import { Avatar, Box, Center, Flex, Heading, Icon, Text } from '@chakra-ui/react';

import { InvitationStatus, UserData } from '../../../../api/api-user';
import { ReactComponent as BubbleBackground } from '../../../../assets/images/bubble_bg.svg';
import { UsersIcon } from '../../../../styles/custom-icons';
import { USER_ROLE_MAP } from '../account-helpers';
import UserListPagination from './UserListPagination';
import UserMenu from './UserMenu';

export type UserListProps = {
  users: UserData[];
  isLoaded: boolean;
  isFetching: boolean;
  pageIndex: number;
  totalPages: number;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  onPageIndexChange: (pageIndex: number) => void;
  status: InvitationStatus;
  searchTerm: string;
};

export default function UserList({
  users,
  isLoaded,
  isFetching,
  pageIndex,
  totalPages,
  pageSize,
  status,
  searchTerm,
  onPageIndexChange,
  onPageSizeChange,
}: UserListProps) {
  return (
    <Flex flexDirection="column" flex={1} px={4} w="100%" h="100%" data-testid="users-list">
      {/* Empty state UI */}
      {isLoaded && !users.length && (
        <Center flexDirection="column" mt={8} mb={4}>
          <Box position={'relative'}>
            <UsersIcon
              w={70}
              h={70}
              color="dusk100.300"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%,-50%)"
              z={2}
            />
            <Icon as={BubbleBackground} h={150} w={150} />
          </Box>

          <Center flexDirection="column" mt={2}>
            <Heading size={['sm', 'md']} noOfLines={1} wordBreak={'break-all'} px={4}>
              {searchTerm ? 'No users found!' : `No ${status === 'CONFIRMED' ? '' : 'invited '}teammates yet!`}
            </Heading>
            <Text color="dusk100.400" mt={1} textAlign="center">
              {searchTerm
                ? 'Search results will be listed here'
                : `${status === 'CONFIRMED' ? 'Team members' : 'Invited users'} will be listed here.`}
            </Text>
          </Center>
        </Center>
      )}

      {users.map((userDetails, index) => {
        const { user_id: id, user_email: userEmail, role, user_full_name: userFullName } = userDetails;
        return (
          <Flex
            key={`${status}-${id || 0}-${index}`}
            align="flex-start"
            my={[2, 2, 3]}
            justify="space-around"
            data-testid="user"
          >
            <Avatar name={userEmail} size={'24px'} w={10} h={10} fontSize={'sm'} color="white" mt={1} />
            <Flex direction="column" maxW={'70%'} ml={1}>
              <Text noOfLines={1}>{userEmail} </Text>
              <Flex>
                <Text fontSize={'sm'} color="gray" noOfLines={1}>
                  {userFullName ? `${userFullName} .` : ''} {USER_ROLE_MAP[role]}
                </Text>
              </Flex>
            </Flex>
            <Flex ml="auto" align="center">
              <UserMenu
                {...{
                  index,
                  userDetails,
                  status,
                }}
              />
            </Flex>
          </Flex>
        );
      })}

      <UserListPagination
        {...{
          isLoaded,
          isFetching,
          pageIndex,
          pageSize,
          totalPages,
          onPageIndexChange,
          onPageSizeChange,
        }}
      />
    </Flex>
  );
}
