// NavigationContext.tsx
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface NavigationState {
  direction: 'forward' | 'backward';
}

interface NavigationContextType {
  navigationState: NavigationState;
  setNavigationState: Dispatch<SetStateAction<NavigationState>>;
}

/*
 * Currently there is only direction but in future we can add more properties to the navigation state.
 * */
const defaultNavigationState: NavigationState = {
  direction: 'forward',
};

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

/*
 * This context provider is used to manage the navigation state of the application, before the routing is handled.
 * The main difference between useNavigate state is that it can be handled independently of url change.
 * For example it can set the navigation direction state in advance of url change.
 * */
export const NavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [navigationState, setNavigationState] = useState<NavigationState>(defaultNavigationState);

  return (
    <NavigationContext.Provider value={{ navigationState, setNavigationState }}>{children}</NavigationContext.Provider>
  );
};

/*
 * This hook is used to access the navigation state of the application.
 * */
// eslint-disable-next-line react-refresh/only-export-components
export const useNavigationState = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigationState must be used within a NavigationProvider');
  }
  return context;
};
