import React from 'react';
import { Avatar, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectUser } from '../user/userSlice';

export default function FleetDashboardProfileInfo() {
  const user = useSelector(selectUser);

  return (
    <Center data-private>
      <Avatar mr={2} size={'lg'} name={user.user_full_name} />
      <Flex flexDirection={'column'}>
        <Heading size={'md'}>{user.user_full_name}</Heading>
        <Text fontSize={'md'}>{user.tenant.tenant_name}</Text>
      </Flex>
    </Center>
  );
}
