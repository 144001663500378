import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, HStack, Spinner, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { OperatingStatus } from '../../api/api-site-alerts';
import { useAppDispatch } from '../../app/hooks';
import SiteListDesktopImg from '../../assets/images/site_list_desktop.svg';
import SiteListMobileImg from '../../assets/images/site_list_mobile.svg';
import NewUserDashboardTemplate from '../../common/components/NewUserDashboardTemplate';
import { FetchDataArgs } from '../../common/components/Table';
import { TenantSelect } from '../../common/components/TenantSelect';
import { debounceEvent } from '../../utils/component-helpers';
import { useGetSiteCountBasedOnOperatingStatus } from '../fleet-dashboard/fleetDashboardApi';
import { resetMeters } from '../site/meter-setup/meterSetupSlice';
import { resetWebSocket } from '../site/meter-setup/webSocketSlice';
import { reset as resetSite } from '../site/siteSlice';
import { selectUser } from '../user/userSlice';
import { reset as resetWizard } from '../wizard/wizardSlice';
import BadgeIcon from './BadgeIcon';
import { OPERATING_STATUS_CONFIG } from './dashboard-helpers';
import { getSites, resetDashboard, setLastVisitedSitePath, setNotLoaded } from './dashboardSlice';
import SitesTable from './SitesTable';

type State = {
  operatingStatus: OperatingStatus | 'all';
  searchTerm: string;
  isFetchingSites: boolean;
  isSearchingSites: boolean;
  pageSize: number;
  offset: number;
  orderBy: string;
  sortOrder: string; // ASC or DESC
  tenantId: number;
};

export default function SiteList() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    { ssr: false }
  );
  const defaultTablePageSize = isMobileViewport ? 5 : 10;
  const dispatch = useAppDispatch();
  const [
    { operatingStatus, searchTerm, pageSize, offset, sortOrder, orderBy, isSearchingSites, isFetchingSites, tenantId },
    setState,
  ] = useState<State>({
    operatingStatus: (urlParams.get('operatingStatus') || 'all') as OperatingStatus | 'all',
    searchTerm: '',
    isSearchingSites: false, // used to show spinner in the search input
    isFetchingSites: false, // used to disable operating status buttons while fetching sites
    pageSize: Number(urlParams.get('pageSize')) || (isMobileViewport ? 5 : 10),
    offset: Number(urlParams.get('offset')) || 0,
    orderBy: urlParams.get('orderBy') || 'clipsal_solar_id',
    sortOrder: urlParams.get('sortOrder') || 'DESC',
    tenantId: Number(urlParams.get('tenantId')) || 0,
  });

  const {
    data: sitesPerOperatingStatus,
    isLoading: isLoadingSitesPerOperatingStatus,
    isFetching: isFetchingSitesPerOperatingStatus,
  } = useGetSiteCountBasedOnOperatingStatus(tenantId);

  const isSitesPerOperatingStatusLoaded = !isLoadingSitesPerOperatingStatus && !isFetchingSitesPerOperatingStatus;

  const totalSites = useMemo(
    () => Object.values(sitesPerOperatingStatus).reduce((a, b) => a + b, 0),
    [sitesPerOperatingStatus]
  );
  const user = useSelector(selectUser);
  const isSuperAdmin = user.role === 'SUPER_ADMIN';
  const buttonColor = useColorModeValue('white', 'whiteAlpha.200');
  const buttonTextColor = useColorModeValue('black', 'white');

  const handleOperatingStatusChange = (operatingStatus: OperatingStatus | 'all') => {
    setState((state) => {
      return {
        ...state,
        operatingStatus: state.operatingStatus === operatingStatus ? 'all' : operatingStatus,
        offset: 0,
      };
    });

    dispatch(setNotLoaded());
  };

  useEffect(() => {
    // Reset any site related data in the store on load
    dispatch(resetSite());
    dispatch(resetWizard());
    dispatch(resetMeters());
    dispatch(resetDashboard());
    dispatch(resetWebSocket());
  }, [dispatch]);

  useEffect(() => {
    async function fetchSites() {
      setState((state) => ({ ...state, isFetchingSites: true }));
      const newPath =
        `/dashboard?pageSize=${pageSize}&offset=${offset}&orderBy=${orderBy}` +
        `&sortOrder=${sortOrder}&operatingStatus=${operatingStatus}&tenantId=${tenantId}`;
      navigate(newPath);
      dispatch(setLastVisitedSitePath(newPath));

      await dispatch(getSites({ pageSize, offset, orderBy, sortOrder, searchTerm, operatingStatus, tenantId }));
      setState((state) => ({ ...state, isSearchingSites: false, isFetchingSites: false }));
    }

    fetchSites();
  }, [dispatch, pageSize, offset, orderBy, sortOrder, searchTerm, operatingStatus, tenantId]);

  const handleUpdatePaginationConfig = useCallback(
    ({
      pageIndex = 0,
      pageSize = defaultTablePageSize,
      orderBy = 'clipsal_solar_id',
      sortOrder = 'DESC',
    }: FetchDataArgs) => {
      setState((prevProps) => ({
        ...prevProps,
        pageSize: pageSize,
        offset: pageIndex * pageSize,
        orderBy,
        sortOrder,
      }));
    },
    [defaultTablePageSize]
  );

  const handleSearch = useMemo(
    () =>
      debounceEvent((e: React.ChangeEvent<HTMLInputElement>) => {
        setState((state) => ({ ...state, searchTerm: e.target.value, isSearchingSites: true, offset: 0 }));
      }),
    [dispatch]
  );

  const commonTableProps = {
    pageSize,
    offset,
    orderBy,
    sortOrder,
    onFetchData: handleUpdatePaginationConfig,
    onSearch: handleSearch,
    isSearchingSites,
    searchTerm,
  };

  if (isSitesPerOperatingStatusLoaded && !totalSites && !isSuperAdmin) {
    return (
      <NewUserDashboardTemplate
        mobileImgSource={SiteListMobileImg}
        desktopImgSource={SiteListDesktopImg}
        secondaryText={
          'You can view all your sites installed with Clipsal Cortex here at a glance.' +
          ' Tap on the button below to get started whenever you’re ready.'
        }
        showGreeting={false}
      />
    );
  }

  return (
    <>
      <HStack px={isMobileViewport ? 2 : 0} wrap="wrap" columnGap={4}>
        {Object.entries(OPERATING_STATUS_CONFIG).map(([key, value]) => {
          const isSelected = key === operatingStatus;
          return (
            <Button
              key={key}
              isDisabled={isFetchingSites}
              data-testid={`view-${key}-sites-button`}
              border={'2px solid transparent'}
              color={isSelected ? 'white' : buttonTextColor}
              background={isSelected ? value.badgeColor : buttonColor}
              _hover={{ borderColor: value.badgeColor }}
              _active={{ background: isSelected ? value.badgeColor : buttonColor }}
              shadow="0px 4px 4px rgba(0, 0, 0, 0.1) !important"
              mt={4}
              px={2}
              onClick={() => handleOperatingStatusChange(key as OperatingStatus)}
            >
              <BadgeIcon color={isSelected ? 'white' : value.badgeColor} />
              <Box fontSize={['sm', 'sm', 'md']} ml={2}>
                {value.label} (
                {isSitesPerOperatingStatusLoaded ? (
                  sitesPerOperatingStatus[key as keyof typeof sitesPerOperatingStatus]
                ) : (
                  <Spinner color={'primaryBranding.500'} size={'xs'} />
                )}
                )
              </Box>
            </Button>
          );
        })}
        {user.role === 'SUPER_ADMIN' && (
          <Box mt={4} minW={250} data-testid="tenant-select">
            <TenantSelect
              value={tenantId}
              onChange={(value) => setState((state) => ({ ...state, tenantId: value as number }))}
            />
          </Box>
        )}
      </HStack>
      <Box bg="white" _dark={{ bg: 'gray.900' }} mt={4} shadow="sm" borderRadius={8} overflow="hidden" pb={2} mb={20}>
        <SitesTable {...commonTableProps} type={operatingStatus} />
      </Box>
    </>
  );
}
