import React, { useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WIFI_CONFIGURABLE_METER_MODELS } from 'clipsal-cortex-utils/src/constants/common-constants';

import { selectRawMeters } from '../meterSetupSlice';
import SubRouteTopNav from '../SubRouteTopNav';
import WifiDetailsForm from './WifiDetailsForm';
import WifiReset from './WifiReset';

const WifiConfiguration = () => {
  const navigate = useNavigate();
  const allMeters = useSelector(selectRawMeters);

  const isWifiConfigured = useMemo(() => {
    return Object.values(allMeters).some(
      (meter) => WIFI_CONFIGURABLE_METER_MODELS.includes(meter.model) && !!meter.comms.wifi?.ssid
    );
  }, [allMeters]);

  const [isEditMode, setIsEditMode] = useState(!isWifiConfigured);

  const handleToggleEditMode = () => setIsEditMode((prev) => !prev);

  return (
    <Box w="100%" data-testid="wifi-configuration">
      <SubRouteTopNav title="Wifi Configuration" onGoBack={() => navigate(-1)} />
      {isEditMode ? (
        <WifiDetailsForm onToggleEditMode={handleToggleEditMode} />
      ) : (
        <WifiReset onToggleEditMode={handleToggleEditMode} />
      )}
    </Box>
  );
};

export default WifiConfiguration;
