import { useEffect, useRef } from 'react';

/**
 * Used to debounce a timeout on an `onChange` or similar event emitter.
 *
 * @param fn - The callable which is pseudo-invoked following the delay.
 * @param delay - Time, in ms, to wait until the function is invoked.
 */

export function debounceEvent<T, R>(fn: (...fnArgs: T[]) => R, delay = 300) {
  let timeoutId: NodeJS.Timeout;

  return function (this: any, ...args: T[]) {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
}

/**
 * Returns the previous prop or state value.
 */
export const usePrevious = (value: string) => {
  const ref = useRef<string>();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
};
