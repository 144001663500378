import React, { useMemo } from 'react';
import { Box, Text, useColorModeValue, useTheme, VStack } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useMeasure from 'react-use-measure';

import { BatterySimulation } from '../../api/api-battery-upsell';

type EstimatedBillsWidgetProps = {
  isMobileViewport?: boolean;
  // JSPDF cannot render shadows properly, so we need to hide it when exporting to PDF
  hideShadow?: boolean;
  dataLabelOffset?: number;
  simulation: BatterySimulation;
};

const getFormattedNumber = (value: number) => {
  if (value >= 1000) return (value / 1000).toFixed(1) + 'K';
  return value.toFixed(1);
};

const EstimatedBillsWidget = ({
  isMobileViewport,
  hideShadow,
  dataLabelOffset = 0,
  simulation,
}: EstimatedBillsWidgetProps) => {
  const theme = useTheme();
  const { textColor, background } = useColorModeValue(
    {
      textColor: theme.colors.dusk100[500],
      background: 'white',
    },
    {
      textColor: theme.colors.dusk100[50],
      background: 'gray.900',
    }
  );

  // highcharts does not render chart within container heights for some reason.
  // Most likely it is due to cumulative layout shift. Hence providing a ref
  // to the container div and using react-use-measure to get the dimensions
  const [containerRef, { height: chartHeight, width: chartWidth }] = useMeasure();

  const billsData = useMemo(() => {
    const { total_cost_yearly_after_dollars, total_cost_yearly_before_dollars } = simulation?.metrics_yearly || {};
    const currentElectricityCost = total_cost_yearly_before_dollars;
    const afterBatteryElectricityCost = total_cost_yearly_after_dollars;

    return {
      currentElectricityCost,
      afterBatteryElectricityCost,
      differenceInCosts: currentElectricityCost - afterBatteryElectricityCost,
    };
  }, [simulation]);

  const options: Highcharts.Options = useMemo(() => {
    const { currentElectricityCost, afterBatteryElectricityCost, differenceInCosts } = billsData;
    return {
      series: [
        {
          name: 'Bill Amount',
          type: 'column',
          data: [
            {
              name: 'Current',
              color: 'rgb(255, 197, 52)',
              borderColor: 'rgb(255, 197, 52)',
              y: currentElectricityCost,
              dataLabels: { y: -27 + dataLabelOffset },
            },
            {
              name: 'After Battery',
              color: '#3DCD58',
              borderColor: '#3DCD58',
              y: afterBatteryElectricityCost,
              dataLabels: { y: (isMobileViewport ? -38 : -40) + dataLabelOffset },
            },
          ],
        },
      ],
      chart: {
        type: 'column',
        height: chartHeight,
        width: chartWidth,
        spacingTop: 15,
        backgroundColor: 'transparent',
        animation: false,
        style: {
          fontFamily: '-apple-system, sans-serif',
        },
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        visible: false,
      },
      xAxis: {
        categories: ['Current', 'After Battery'],
        labels: { useHTML: true },
      },
      title: {
        text: '',
      },
      tooltip: {
        animation: false,
        valuePrefix: '$',
        style: {
          zIndex: 100,
        },
        outside: true,
      },
      plotOptions: {
        column: {
          borderRadius: 5,
          maxPointWidth: 60,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'allow',
            useHTML: true,
            color: textColor,
            verticalAlign: 'top',
            formatter: function () {
              const isFirstLabel: boolean = this.point.index === 0;
              const multilineLabel = isFirstLabel
                ? ''
                : `<div style="color:#3DCD58;">-$${getFormattedNumber(differenceInCosts)}</div>`;

              return `<div style="font-size: ${isMobileViewport ? 12 : 15}px; height: ${
                isMobileViewport ? 15 : 18
              }px;">$${getFormattedNumber(Number(this.y))}</div> ${multilineLabel}`;
            },
          },
        },
      },
      legend: {
        enabled: false,
      },
    };
  }, [textColor, chartWidth, chartHeight, dataLabelOffset, billsData]);

  return (
    <VStack
      bg={background}
      w="100%"
      maxW={isMobileViewport ? undefined : 400}
      minH={300}
      rounded={20}
      justify="center"
      p={4}
      pb={8}
      shadow={hideShadow ? 'none' : 'md'}
      data-testid="estimated-bills-widget"
    >
      <Box w="100%" h="100%" pb={4}>
        <Text fontWeight={600} mb={4} textAlign={'center'} color={'black'} _dark={{ color: 'white' }}>
          Estimated Annual Electricity Bills
        </Text>
        <Box w="100%" h="100%" position={'relative'}>
          <Box position={'absolute'} left={0} top={0} width={'100%'} height={'100%'} ref={containerRef}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </Box>
        </Box>
      </Box>
    </VStack>
  );
};

export default EstimatedBillsWidget;
