import React, { useState } from 'react';
import { Flex, Icon, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { BsCircleFill } from 'react-icons/bs';
import { Cell, Column } from 'react-table';

import { SiteData } from '../../api/api-site';
import { BatteryCellIcon, CortexCellIcon, PulseCellDarkIcon, SolarCellIcon } from '../../styles/custom-icons';
import { MOBILE_COLUMN_DISPLAY_TYPES, OPERATING_STATUS_CONFIG } from './dashboard-helpers';
import SiteActionMenu from './SiteActionMenu';

// eslint-disable-next-line react-refresh/only-export-components
const COLUMNS = [
  {
    Header: 'Site Name',
    id: 'site_name',
    accessor: 'site_name',
    Cell: ({ row }: Cell<SiteData>) => {
      const statusColor =
        OPERATING_STATUS_CONFIG[row.original.operating_status]?.badgeColor ||
        OPERATING_STATUS_CONFIG['NORMAL'].badgeColor;

      return (
        <Flex align="center">
          <Icon as={BsCircleFill} color={statusColor || 'primaryBranding.500'} h="10px" w="10px" />{' '}
          <Text ml={1} maxWidth={[24, 200]} noOfLines={2}>
            {row.original.site_name}
          </Text>
        </Flex>
      );
    },
  },
  { Header: 'Address', accessor: 'street_address', id: 'street_address' },
  {
    Header: 'Capacity (kWp)',
    id: 'solar_capacity_kw',
    disableSortBy: true,
    accessor: ({ solar_capacity_kw }: SiteData) => {
      return solar_capacity_kw.toFixed(2);
    },
  },
  {
    Header: 'System Type',
    id: 'system_type',
    accessor: 'system',
    disableSortBy: true,
    Cell: ({ row }: Cell<SiteData>) => {
      const hasCortex = row.original.has_pulse;
      const hasSolar = row.original.has_solar;
      const hasBattery = row.original.has_battery;

      const { cortexIcon, solarIcon, batteryIcon } = useColorModeValue(
        {
          cortexIcon: <CortexCellIcon w={7} h={7} />,
          solarIcon: <SolarCellIcon color="dusk100.500" w={7} h={7} />,
          batteryIcon: <BatteryCellIcon color="dusk100.500" w={7} h={7} />,
        },
        {
          cortexIcon: <PulseCellDarkIcon w={7} h={7} />,
          solarIcon: <SolarCellIcon color="white" w={7} h={7} />,
          batteryIcon: <BatteryCellIcon color="white" w={7} h={7} />,
        }
      );

      return (
        <Flex>
          {hasCortex && cortexIcon}
          {hasSolar && solarIcon}
          {hasBattery && batteryIcon}
        </Flex>
      );
    },
  },
  { Header: 'Company', accessor: 'tenant.tenant_name', id: 'tenant__tenant_name' },
  {
    Header: '',
    id: 'action',
    disableSortBy: true,
    Cell: ({ row }: { row: any }) => <SiteActionMenu siteData={row.original as SiteData} dataIndex={row.index} />,
  },
] as Column[];

/**
 * Returns the columns to be displayed in the table, according to the viewport type, as well as the state and state
 * setter for the column display type (mobile only).
 */
export default function useTableColumns(): [Column[], [string, React.Dispatch<React.SetStateAction<string>>]] {
  // This state value only applies on mobile and is used to control which column to display.
  const [selectedColumnDisplayType, setSelectedColumnDisplayType] = useState('system_type');
  const columns = useBreakpointValue<Column[]>(
    {
      base: COLUMNS.filter((col) => {
        const columnsToBeFiltered = MOBILE_COLUMN_DISPLAY_TYPES.filter(
          (column) => column.value !== selectedColumnDisplayType
        ).map((column) => column.value);

        return !columnsToBeFiltered.includes(col.id as string);
      }),
      lg: COLUMNS,
    },
    { ssr: false }
  );

  return [columns ?? [], [selectedColumnDisplayType, setSelectedColumnDisplayType]];
}
