import React, { useMemo } from 'react';
import { Box, useColorModeValue, useTheme } from '@chakra-ui/react';
import { lastDayOfMonth } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { cloneDeep } from 'lodash';

import { FleetDataV1 } from '../../api/api-fleet-data';
import { COMMON_CHART_DATETIME_FORMATS } from '../../common/constants';
import { DateRangeType } from './DateRangeTypePicker';
import { FLEET_DASHBOARD_CHART_HEIGHT_PIXELS } from './fleet-dashboard-helpers';

type UsageChartProps = Readonly<{
  selectedDateRangeType: DateRangeType;
  selectedPeriod: string;
  fleetData: FleetDataV1;
}>;

export default function UsageChart({ selectedDateRangeType, selectedPeriod, fleetData }: UsageChartProps) {
  const theme = useTheme();
  const { textColor, textHoverColor, chartLabelColor } = useColorModeValue(
    {
      textColor: theme.colors.gray[900],
      textHoverColor: 'black',
      chartLabelColor: theme.colors.gray[500],
    },
    {
      textColor: theme.colors.dusk100[50],
      textHoverColor: 'white',
      chartLabelColor: theme.colors.gray[400],
    }
  );

  const options: Highcharts.Options = useMemo(() => {
    function getMaxTime() {
      // @TODO: hacky fix to get cypress working
      if ((window as any).Cypress) return undefined;
      const currentDate = new Date(selectedPeriod);
      const lastDayOfTheMonth = lastDayOfMonth(currentDate);

      switch (selectedDateRangeType) {
        case DateRangeType.Day:
          // This ensures chart does not go into the next day
          currentDate.setHours(23, 59, 59, 999);
          return currentDate.getTime();
        case DateRangeType.Month:
          // This ensures chart is not cut off at the end of the month
          lastDayOfTheMonth.setHours(10);
          return lastDayOfTheMonth.getTime();
        case DateRangeType.Year:
          // This ensures chart does not have Jan of next year
          currentDate.setMonth(11, 1);
          return currentDate.getTime();
        default:
          return undefined;
      }
    }

    function getMinTime() {
      // @TODO: hacky fix to get cypress working
      if ((window as any).Cypress) return undefined;

      const currentDate = new Date(selectedPeriod);
      currentDate.setHours(0, 0, 0, 0);

      switch (selectedDateRangeType) {
        case DateRangeType.Day:
          return currentDate.getTime();
        case DateRangeType.Month:
          currentDate.setDate(1);
          return currentDate.getTime();
        case DateRangeType.Year:
          currentDate.setMonth(0, 1);
          return currentDate.getTime();
        default:
          return undefined;
      }
    }

    const valueSuffix = selectedDateRangeType === 'DAY' ? ' MW' : ' MWh';

    const clonedFleetData = cloneDeep(fleetData);

    return {
      series: [
        {
          color:
            selectedDateRangeType === 'DAY'
              ? 'rgba(134, 182, 209, 1)'
              : {
                  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                  stops: [
                    [0, 'rgba(134, 182, 209, 0.5)'],
                    [1, 'rgba(134, 182, 209, 0.2)'],
                  ],
                },
          lineColor: 'rgba(134, 182, 209, 1)',
          borderColor: 'rgba(134, 182, 209, 1)',
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, 'rgba(134, 182, 209, 0.5)'],
              [1, 'rgba(134, 182, 209, 0)'],
            ],
          },
          type: selectedDateRangeType === 'DAY' ? 'area' : 'column',
          data: clonedFleetData.consumed.data,
          name: 'Consumed',
        },
        {
          color:
            selectedDateRangeType === 'DAY'
              ? 'rgba(255, 197, 52, 1)'
              : {
                  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                  stops: [
                    [0, 'rgba(255, 197, 52, 0.5)'],
                    [1, 'rgba(255, 197, 52, 0.2)'],
                  ],
                },
          lineColor: 'rgba(255, 197, 52, 1)',
          borderColor: 'rgba(255, 197, 52, 1)',
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, 'rgba(255, 197, 52, 0.5)'],
              [1, 'rgba(255, 197, 52, 0)'],
            ],
          },
          type: selectedDateRangeType === 'DAY' ? 'area' : 'column',
          data: clonedFleetData.produced.data,
          name: 'Produced',
        },
      ],
      accessibility: {
        enabled: false,
      },
      chart: {
        zoomType: 'x',
        height: FLEET_DASHBOARD_CHART_HEIGHT_PIXELS,
        marginTop: 50,
        backgroundColor: 'transparent',
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        labels: {
          style: { color: chartLabelColor },
          formatter: function () {
            return this.value + valueSuffix;
          },
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
        max: getMaxTime(),
        min: getMinTime(),
        labels: { style: { color: chartLabelColor } },
      },
      time: {
        useUTC: selectedDateRangeType !== 'DAY',
      },
      title: {
        text: '',
      },
      tooltip: {
        animation: false,
        shared: true,
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
        valueDecimals: 3,
        valueSuffix,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      legend: {
        itemStyle: { color: textColor },
        itemHoverStyle: { color: textHoverColor },
      },
    };
  }, [fleetData, selectedDateRangeType, selectedPeriod, textColor, textHoverColor, chartLabelColor]);

  return (
    <Box data-testid="fleet-dashboard-usage-chart-container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
}
