import { isEqual } from 'lodash';

import { SwitchSchedule } from 'clipsal-cortex-types/src/api/api-switch-schedule';
import { SiteSwitch, WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { SwitchIntelligentControlToSave, SwitchStateProfile } from 'clipsal-cortex-types/src/api/api-ww-switch';
import { SWITCH_CONFIGURABLE_METER_MODELS } from 'clipsal-cortex-utils/src/constants/common-constants';

import { Appliance } from '../../../../api/api-appliance';
import { Assignment } from '../../../../api/api-assignments';
import {
  IntelligentSchedulingConfig,
  PatchSiteSwitch,
  ScheduleFormData,
  SwitchConfigFormData,
} from './switch-config-form-types';

export const NOT_SET_CATEGORY_ID = 32;
export const NOT_SET_CATEGORY_LABEL = 'Not Set';
export const NOT_SET_ASSIGNMENT = 'not_set';

export const mapSwitchesToForm = (
  meters: WattwatchersMeter[],
  switches: SiteSwitch[],
  appliances: Appliance[],
  assignments: Assignment[],
  stateProfiles: SwitchStateProfile[]
): SwitchConfigFormData => {
  const metersWithSwitches = meters
    .filter((m) => SWITCH_CONFIGURABLE_METER_MODELS.includes(m.model))
    .map((m) => ({
      meterId: m.ww_device_id,
      meterName: m.label || '',
      switches:
        m.switches?.map(({ id: oemId }, switchIndex) => {
          const siteSwitch = switches.find((sw) => sw.oem_switch_id === oemId);
          if (!siteSwitch) throw new Error(`Unable to find associated site switch for OEM ID: ${oemId}`);
          const {
            ww_contactor_type,
            circuit_id,
            site_switch_label,
            active,
            pending,
            scheduling,
            id,
            intelligent_control: intelligentControl,
          } = siteSwitch;
          const stateProfile = stateProfiles.find(
            (stateProfile) => Object.keys(stateProfile.device_state.switches)[0] === oemId
          ) as SwitchStateProfile;
          const appliance = appliances.find((a) => circuit_id && a.circuits.includes(circuit_id));
          const assignment = appliance ? assignments.find((a) => a.assignment === appliance.appliance_type) : null;
          const categoryId = assignment ? assignment.id : NOT_SET_CATEGORY_ID;
          const categoryLabel = assignment ? assignment.display_name : NOT_SET_CATEGORY_LABEL;

          // These are default values for auto scheduling, replaced by what comes from the API if it's there.
          const autoSchedulingConfig = {
            isExisting: false,
            exportThreshold: 3.6,
            runTimeHours: 4,
            runTimeMinutes: 0,
          };

          if (intelligentControl) {
            autoSchedulingConfig.isExisting = true;
            autoSchedulingConfig.exportThreshold = intelligentControl.export_threshold_kw;
            autoSchedulingConfig.runTimeHours = Math.floor(intelligentControl.daily_min_runtime_minutes / 60);
            autoSchedulingConfig.runTimeMinutes = intelligentControl.daily_min_runtime_minutes % 60;
          }

          return {
            id,
            oemId,
            active,
            categoryId,
            categoryLabel,
            isPending: !!pending?.contactorType,
            contactorType: pending?.contactorType || ww_contactor_type,
            circuitId: circuit_id,
            label: active
              ? site_switch_label ?? appliance?.appliance_name ?? `Switch ${switchIndex + 1}`
              : NOT_SET_CATEGORY_LABEL,
            schedulingType: scheduling,
            fallbackState: stateProfile?.device_state.switches[oemId] ?? 'ignore',
            schedulingConfig: {
              AUTO: autoSchedulingConfig,
              // Note: this data is lazy loaded when manual scheduling is enabled for a specific switch for performance
              MANUAL: {
                deletedScheduleIds: [],
                schedules: [],
              },
            },
          };
        }) || [],
    }));

  return { meters: metersWithSwitches };
};

export const mapSwitchesToApi = (formData: SwitchConfigFormData): PatchSiteSwitch[] => {
  return formData.meters.flatMap((m) => {
    return m.switches.map(({ id, circuitId, active, contactorType, label, schedulingType }) => {
      return {
        id,
        active,
        scheduling: schedulingType,
        circuit_id: circuitId,
        ww_contactor_type: contactorType,
        site_switch_label: label,
      };
    });
  });
};

export function mapAPISchedulesToUI(apiSchedules: SwitchSchedule[]) {
  // The API's structure differs significantly from what the UI expects, so we need this grouping algorithm
  // to determine which schedules belong together.
  const schedulesGroupedByWeekdayIndexes = apiSchedules.reduce<SwitchSchedule[][]>((groupedSchedules, apiSchedule) => {
    const matchingGroupedSchedule = groupedSchedules.find((group) =>
      group.some((scheduleInGroup) => isEqual(apiSchedule.weekly_freq_interval, scheduleInGroup.weekly_freq_interval))
    );

    if (matchingGroupedSchedule) matchingGroupedSchedule.push(apiSchedule);
    else {
      groupedSchedules.push([apiSchedule]);
    }

    return groupedSchedules;
  }, []);

  // For each grouped schedule set, find the nearest matching end time for each start time, and combine them into
  // one single schedule for the UI.
  const uiSchedules: ScheduleFormData[] = [];

  schedulesGroupedByWeekdayIndexes.forEach((groupedSchedules) => {
    let closeEventsInGroup = groupedSchedules.filter((s) => s.event_action === 'closed');
    let openEventsInGroup = groupedSchedules.filter((s) => s.event_action === 'open');

    closeEventsInGroup.forEach((schedule) => {
      let closestOpenEvent: SwitchSchedule | null = null;
      let smallestTimeDelta = Infinity;
      const startTime = new Date();
      const [startTimeHours, startTimeMins] = schedule.event_time.split(':').map((value) => Number(value));
      startTime.setHours(startTimeHours);
      startTime.setMinutes(startTimeMins);

      openEventsInGroup.forEach((closeScheduledEvent) => {
        const endTime = new Date();
        const [endTimeHours, endTimeMins] = closeScheduledEvent.event_time.split(':').map((value) => Number(value));
        endTime.setHours(endTimeHours);
        endTime.setMinutes(endTimeMins);
        const timeDiff = Math.abs(endTime.getTime() - startTime.getTime());

        if (timeDiff > 0 && timeDiff < smallestTimeDelta) {
          smallestTimeDelta = timeDiff;
          closestOpenEvent = closeScheduledEvent;
        }
      });

      if (closestOpenEvent === null) {
        throw new Error('Every close event must have an open event.');
      }

      // We've got the closest close event, combine them into one.
      uiSchedules.push({
        startScheduleId: schedule.schedule_id,
        endScheduleId: (closestOpenEvent as SwitchSchedule).schedule_id,
        daysOfWeek: schedule.weekly_freq_interval,
        startTime: schedule.event_time,
        endTime: (closestOpenEvent as SwitchSchedule).event_time,
      });

      // Remove the items we just added from the previous arrays
      closeEventsInGroup = closeEventsInGroup.filter((s) => s.schedule_id !== schedule.schedule_id);
      openEventsInGroup = openEventsInGroup.filter(
        (s) => s.schedule_id !== (closestOpenEvent as SwitchSchedule).schedule_id
      );
    });
  });

  return uiSchedules;
}

export function mapIntelligentControlToAPI({
  runTimeHours,
  runTimeMinutes,
  exportThreshold,
}: IntelligentSchedulingConfig): SwitchIntelligentControlToSave {
  return {
    export_threshold_kw: exportThreshold,
    daily_min_runtime_minutes: runTimeHours * 60 + runTimeMinutes,
  };
}
