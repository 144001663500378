import React, { useCallback } from 'react';
import { Box, Center, Flex, Heading, Image, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';

import { Appliance } from '../../../../api/api-appliance';
import { useNavigationState } from '../../../../context/NavigationProvider';
import { selectSite } from '../../siteSlice';
import FieldIconWrapper from '../ct-configuration/FieldIconWrapper';
import { selectRawMeters, selectTestStatusV2 } from '../meterSetupSlice';
import { ApplianceLoadResult } from './circuit-status-websocket-types';
import { CircuitTestStatus, TEST_STATUS_TO_CONFIG } from './test-types';

type Props = {
  appliance: Appliance;
};

export default function ApplianceStatusCard({ appliance }: Props) {
  const testResults = useSelector(selectTestStatusV2);
  const rawMeters = useSelector(selectRawMeters);
  const navigate = useNavigate();
  const { setNavigationState } = useNavigationState();
  const site = useSelector(selectSite);
  const shadowColor = useColorModeValue('rgba(0, 0, 0, 0.25)', 'rgba(255, 255, 255, 0.25)');
  const Icon = CIRCUIT_TYPE_TO_ICON[appliance.appliance_type];
  const result = testResults.loads?.find((load) => load.appliance_id === appliance.appliance_id) as ApplianceLoadResult;
  let status = result?.status;
  // There might not be a result for this load yet -- it's uninitialized.
  if (!status) {
    status = CircuitTestStatus.Uninitialized;
  }
  const { color, title, icon } = TEST_STATUS_TO_CONFIG[status];

  const getMeterAndCTText = useCallback(() => {
    return Object.values(rawMeters).reduce<JSX.Element[]>((acc, meter, index) => {
      const applicableCircuits = meter.circuits.filter((c) => appliance.circuits.includes(c.clipsal_circuit_id));
      const applicableCircuitsString = applicableCircuits
        .map((c) => `CT${c.ww_circuit_id.charAt(c.ww_circuit_id.length - 1)}`)
        .join(', ');

      if (applicableCircuits.length) {
        acc.push(
          <Text key={index} color={'fixedCostGrey.500'} fontSize={'xs'}>
            {(meter.label ?? `Meter ${index + 1}`) + `, ${applicableCircuitsString}`}
          </Text>
        );
      }

      return acc;
    }, []);
  }, [rawMeters, appliance.circuits]);

  return (
    <Flex
      data-testid={`load-button-${appliance.appliance_type}`}
      cursor="pointer"
      onClick={() => {
        setNavigationState({ direction: 'forward' });
        navigate(`/site/${site.clipsal_solar_id}/meter_setup/configure/loads/${appliance.appliance_id}`);
      }}
      boxShadow={`0px 0px 4px ${shadowColor}`}
      rounded={8}
    >
      <Box
        w={'3%'}
        bg={status === CircuitTestStatus.InProgress ? 'dusk050.500' : color}
        borderTopLeftRadius={10}
        borderBottomLeftRadius={10}
      />
      <Box px={1} py={3} w={'97%'}>
        <Flex>
          <Center ml={2}>
            <FieldIconWrapper>
              <Icon w={6} h={6} />
            </FieldIconWrapper>
          </Center>

          <Box w="80%">
            <Flex justify={'space-between'} align={'flex-start'}>
              <Heading size={'sm'}>{appliance.appliance_name}</Heading>
              <Flex align={'center'}>
                {status === CircuitTestStatus.InProgress ? (
                  <Spinner size="xs" mr={1} color={'primaryBranding.500'} />
                ) : (
                  <Image mr={1} w={4} src={icon} />
                )}
                <Text color={color} fontSize={'xs'}>
                  {title}
                </Text>
              </Flex>
            </Flex>
            {getMeterAndCTText()}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
