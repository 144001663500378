import React, { useEffect, useMemo, useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text, useBreakpointValue, useColorModeValue, useTheme, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks';
import { SIDENAV_WIDTH } from '../../common/constants';
import { selectRawMeters } from '../site/meter-setup/meterSetupSlice';
import { selectWebSocketState, updateSubscribedSite } from '../site/meter-setup/webSocketSlice';
import { selectSite } from '../site/siteSlice';
import { selectStep, selectWizard, StepState } from './wizardSlice';

export default function Wizard() {
  const wizard = useSelector(selectWizard);
  const visibleSteps = useMemo(() => wizard.steps.filter((step) => step.componentName !== 'CustomerDetails'), [wizard]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const site = useSelector(selectSite);
  const { webSocket, subscribedSiteId } = useSelector(selectWebSocketState);
  const meters = useSelector(selectRawMeters);
  const theme = useTheme();

  const [isOnMeterSetupSubRoute, setIsOnMeterSetupSubRoute] = useState(/\/meter_setup\/configure\//.test(pathname));

  useEffect(() => {
    const isCurrentlyOnMeterSubRoute = /\/meter_setup\/configure\//.test(pathname);

    // Only update state if the user is moving to or from the meter setup sub route.
    if (isOnMeterSetupSubRoute !== isCurrentlyOnMeterSubRoute) {
      // Minor tweak to ensure sliding animations are smooth and not glitchy
      setTimeout(() => setIsOnMeterSetupSubRoute(isCurrentlyOnMeterSubRoute), isCurrentlyOnMeterSubRoute ? 0 : 150);
    }
  }, [pathname]);

  const { background, backgroundHover, boxShadowColor, textColor } = useColorModeValue(
    {
      background: 'customBodyBackground.500',
      backgroundHover: '#FFFFFFDB',
      boxShadowColor: theme.colors.dusk005[500],
      textColor: 'black',
    },
    {
      background: 'customBodyBackground.800',
      backgroundHover: '#FFFFFF1A',
      boxShadowColor: theme.colors.customBodyBackground[800],
      textColor: 'white',
    }
  );
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  function handleChangeStep(step: StepState, stepIndex: number) {
    // Helps prevent sliding animation bug where the user
    // presses the step button of the step that he is currently in
    if (stepIndex === wizard.currentStepIndex) return;

    // Users cannot move past the first step unless it is complete.
    const isFirstStepIncomplete = wizard.currentStepIndex === 0 && !wizard.steps[0].isComplete;
    // Users cannot progress to the the invite customer step if required steps are incomplete.
    const isWizardComplete = visibleSteps.every((step) => !step.isRequired || step.isComplete);
    const isProgressingToInviteCustomer = step.componentName === 'CustomerDetails';
    const shouldStopUserFromProgressingToInviteCustomer = !isWizardComplete && isProgressingToInviteCustomer;
    const userCannotMove = isFirstStepIncomplete || shouldStopUserFromProgressingToInviteCustomer;
    const errorDescription = isFirstStepIncomplete ? 'current' : 'unfinished';

    if (userCannotMove) {
      toast({
        title: 'Unable to move to this step.',
        description: `Complete the ${errorDescription} step first.`,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    // if moving to meter page and meters have been loaded, need to subscribe to site again
    if (step.componentName === 'MeterSetup' && Object.keys(meters).length) {
      // The websocket exists but is not subscribed to the site currently being commissioned -- update that.
      if (subscribedSiteId !== site.clipsal_solar_id) dispatch(updateSubscribedSite(site.clipsal_solar_id));

      // we want to subscribe to site while reaching meter page in order to have latest state for meter
      webSocket?.sendJsonMessage({ action: 'subscribeSite', clipsal_solar_id: site.clipsal_solar_id });
    }

    dispatch(selectStep({ stepIndex }));
    navigate(`/site/${id}/${step.route}`);
  }

  function getStepStatusBackground(step: StepState) {
    if (step.isComplete) return 'primaryBranding.500';
    return boxShadowColor;
  }

  function getStepTextColor(step: StepState) {
    if (step.isComplete) return 'white';
    return textColor;
  }

  function getStepBorder(step: StepState, stepIndex: number) {
    if (step.isComplete || stepIndex === wizard.currentStepIndex)
      return `2px solid ${theme.colors.primaryBrandingStatic[500]}`;
    return `2px solid ${theme.colors.dusk050[500]}`;
  }

  function getStepContent(step: StepState, stepIndex: number) {
    if (step.isComplete) return <CheckIcon h={4} w={4} />;
    return stepIndex + 1;
  }

  // hide wizard for sub routes of meter setup page
  // reason being users were skipping the config steps
  // and going straight to the meter setup page
  if (isOnMeterSetupSubRoute) return null;

  return (
    <Box
      as={'nav'}
      position="fixed"
      top={12}
      left={0}
      zIndex={99}
      mt="env(safe-area-inset-top)"
      w="100%"
      paddingLeft={isMobileViewport ? 0 : SIDENAV_WIDTH}
    >
      <Flex justify="space-between" maxWidth={isMobileViewport ? '100%' : '60%'} mx={'auto'} bg={background} py={2}>
        {wizard.steps.map((step: StepState, index) => (
          <Flex
            data-testid={`wizard-step-button-${index}`}
            key={`step-${index}`}
            px={3}
            py={1}
            cursor={'pointer'}
            _hover={{ background: backgroundHover }}
            borderRadius={8}
            onClick={() => handleChangeStep(step, index)}
            justify={'center'}
            align={'center'}
          >
            <Flex
              boxShadow={`0 0 0 3px ${boxShadowColor}, inset 0 0 0 2px ${boxShadowColor}`}
              fontWeight={wizard.currentStepIndex === index ? 'bolder' : 'normal'}
              justify={'center'}
              align={'center'}
              h={isMobileViewport ? 8 : 10}
              w={isMobileViewport ? 8 : 10}
              border={getStepBorder(step, index)}
              rounded={50}
              bg={getStepStatusBackground(step)}
            >
              <Center color={getStepTextColor(step)}>{getStepContent(step, index)}</Center>
            </Flex>
            <Text fontSize={'sm'} ml={1} fontWeight={wizard.currentStepIndex === index ? 'bold' : 'normal'}>
              {step.title}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
