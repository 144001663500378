import React, { useEffect } from 'react';
import { Center, Heading } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks';
import { logOut } from '../user/userSlice';

export default function Logout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function logUserOut() {
      try {
        await Auth.signOut();
        dispatch(logOut());

        setTimeout(() => {
          navigate('/');
        }, 1000);
      } catch (e) {
        console.error(e);
      }
    }

    logUserOut();
  }, [dispatch]);

  return (
    <Center flexDirection={'column'} height={'100vh'}>
      <Heading mb={2} size={'lg'}>
        Successfully logged out.
      </Heading>
      <Heading size={'md'}>Redirecting you to the login page...</Heading>
    </Center>
  );
}
