import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, ChevronRightIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/hooks';
import CustomButton from '../../../../common/components/CustomButton';
import { COMPONENT_MIN_HEIGHT } from '../../../../common/constants';
import { useNavigationState } from '../../../../context/NavigationProvider';
import { selectSite } from '../../siteSlice';
import {
  checkIfTestsAreComplete,
  getDisplayedTestsByStage,
  GRID_CIRCUIT_TYPES,
  LoadType,
  VALID_STATUSES,
} from '../meter-setup-helpers';
import {
  selectAllCircuits,
  selectAppliances,
  selectPolarityConfirmationStatus,
  selectTestStatusV2,
  setBatteryPolarityConfirmation,
  setHybridPolarityConfirmation,
  updateMeterStatusFromWebSocketV2,
  updateMeterStatusFromWebSocketV2IgnoreSkipped,
} from '../meterSetupSlice';
import SubRouteTopNav from '../SubRouteTopNav';
import { selectWebSocket } from '../webSocketSlice';
import { getCTsBySource } from './chart-helpers';
import {
  SourceType,
  WebSocketMessage,
  WebsocketStatusResponseV2,
  WebSocketTestType,
} from './circuit-status-websocket-types';
import GridHybridInfoDrawerButton, { GridInfoDrawerButton } from './GridHybridInfoDrawerButton';
import SkipTestBottomDrawer from './SkipTestBottomDrawer';
import { CircuitTestStatus, ORDERED_TEST_RESULTS_BY_IMPORTANCE, TEST_STATUS_TO_CONFIG, TestTypeV2 } from './test-types';
import { getTestStatusBasedOnPolarityConfirmation } from './use-meter-test-handlers';

export default function CircuitTests() {
  const testResults = useSelector(selectTestStatusV2);
  const navigate = useNavigate();
  const { setNavigationState } = useNavigationState();
  const site = useSelector(selectSite);
  const appliances = useSelector(selectAppliances);
  const dispatch = useAppDispatch();
  const webSocket = useSelector(selectWebSocket);
  const allCircuits = useSelector(selectAllCircuits);
  const [stageIndexToSkip, setStageIndexToSkip] = useState<number>(-1);
  const [stageTwoInfoModalType, setStageTwoInfoModalType] = useState<string | null>(null);
  const { isOpen: isSkipDialogOpen, onOpen: onOpenSkipDialog, onClose: onCloseSkipDialog } = useDisclosure();
  const polarityConfirmationStatus = useSelector(selectPolarityConfirmationStatus);
  const [expandedIndex, setExpandedIndex] = useState(0);

  const { buttonTextColor, secondaryButtonTextColor } = useColorModeValue(
    { buttonTextColor: 'white', secondaryButtonTextColor: 'customBlack.500' },
    { buttonTextColor: 'black', secondaryButtonTextColor: 'dusk100.200' }
  );

  function getStatusFromTestType(type: SourceType) {
    if (type === 'loads') {
      // If a site has no loads, the load test section is technically complete and not required.
      const siteHasNoLoads = !appliances.find(
        (a) => a.appliance_type.includes('load_') && !GRID_CIRCUIT_TYPES.includes(a.appliance_type)
      );
      if (siteHasNoLoads) return CircuitTestStatus.Success;

      if (testResults.loads) {
        const allLoadResults = testResults.loads.map((load) => load.status);
        // No load results yet -- just show uninitialized for now.
        if (!allLoadResults.length) return CircuitTestStatus.Uninitialized;

        for (const resultType of ORDERED_TEST_RESULTS_BY_IMPORTANCE) {
          if (allLoadResults.includes(resultType)) {
            return resultType;
          }
        }
      }

      console.error('Unable to find load type result in provided load results.');

      return CircuitTestStatus.Undetermined;
    } else {
      return testResults[type]?.status || CircuitTestStatus.Uninitialized;
    }
  }

  function handleRunAllTestsForStage(stage: number) {
    const testsByStage = getDisplayedTestsByStage(appliances);
    const sourcesForStage: SourceType[] = testsByStage[stage].map((t) => t.source);

    // Reset confirm polarity for hybrid and battery
    if (sourcesForStage.includes('hybrid')) {
      dispatch(setHybridPolarityConfirmation(false));
    }

    if (sourcesForStage.includes('battery')) {
      dispatch(setBatteryPolarityConfirmation(false));
    }

    const newStatus = cloneDeep<WebsocketStatusResponseV2>(testResults);

    sourcesForStage.forEach((type) => {
      if (type !== 'loads') {
        const statusToUpdate = newStatus[type];
        if (statusToUpdate) {
          statusToUpdate.status = CircuitTestStatus.InProgress;
          statusToUpdate[TestTypeV2.Polarity].status = CircuitTestStatus.InProgress;
          statusToUpdate[TestTypeV2.PowerFactor].status = CircuitTestStatus.InProgress;
        }
      } else if (newStatus.loads) {
        newStatus.loads.forEach((loadResult) => {
          loadResult.status = CircuitTestStatus.InProgress;
          loadResult[TestTypeV2.Polarity].status = CircuitTestStatus.InProgress;
          loadResult[TestTypeV2.PowerFactor].status = CircuitTestStatus.InProgress;
        });
      }
    });

    dispatch(updateMeterStatusFromWebSocketV2IgnoreSkipped({ data: newStatus, typeToIgnoreSkipped: 'all' }));

    const message: WebSocketMessage = {
      action: 'runTests',
      clipsal_solar_id: site.clipsal_solar_id,
      test_types: sourcesForStage.map((source) => source.toUpperCase() as WebSocketTestType),
    };

    webSocket?.sendJsonMessage<WebSocketMessage>(message);
  }

  function handleSkipAllTestsForStage(stage: number) {
    const testsByStage = getDisplayedTestsByStage(appliances);
    const sourcesForStage: SourceType[] = testsByStage[stage].map((t) => t.source);

    // Reset confirm polarity for hybrid and battery
    if (sourcesForStage.includes('hybrid')) {
      dispatch(setHybridPolarityConfirmation(false));
    }

    if (sourcesForStage.includes('battery')) {
      dispatch(setBatteryPolarityConfirmation(false));
    }

    const newStatus = cloneDeep<WebsocketStatusResponseV2>(testResults);

    sourcesForStage.forEach((type) => {
      if (type !== 'loads') {
        // Pass test result object by reference
        skipTestsBySourceType(type, newStatus);
      } else if (newStatus.loads) {
        newStatus.loads.forEach((loadResult) => {
          loadResult.status = CircuitTestStatus.Skipped;
          loadResult[TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
          loadResult[TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;
        });
      }
    });

    dispatch(updateMeterStatusFromWebSocketV2(newStatus));
  }

  function skipTestsBySourceType(type: Exclude<SourceType, 'loads'>, newStatus: WebsocketStatusResponseV2) {
    const statusToUpdate = newStatus[type];
    const circuits = getCTsBySource(allCircuits, type);
    if (statusToUpdate) {
      statusToUpdate.status = CircuitTestStatus.Skipped;
      statusToUpdate[TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
      statusToUpdate[TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;

      if (statusToUpdate[TestTypeV2.Polarity].circuits.length) {
        statusToUpdate[TestTypeV2.Polarity].circuits.forEach(
          (c) => (c.polarity_test_status = CircuitTestStatus.Skipped)
        );
      } else {
        // No initialized circuits yet, create some empty ones and set them to be skipped
        statusToUpdate[TestTypeV2.Polarity].circuits = circuits.map((c) => {
          return {
            polarity_test_status: CircuitTestStatus.Skipped,
            power_factor: 0,
            current_a: 0,
            circuit_id: c.ww_circuit_id,
            power_kw: 0,
            id: c.clipsal_circuit_id,
            clipsal_monitors: c.clipsal_monitors,
          };
        });
      }

      if (statusToUpdate[TestTypeV2.PowerFactor].circuits.length) {
        statusToUpdate[TestTypeV2.PowerFactor].circuits.forEach(
          (c) => (c.power_factor_test_status = CircuitTestStatus.Skipped)
        );
      } else {
        // No initialized circuits yet, create some empty ones and set them to be skipped
        statusToUpdate[TestTypeV2.PowerFactor].circuits = circuits.map((c) => {
          return {
            power_factor_test_status: CircuitTestStatus.Skipped,
            power_factor: 0,
            current_a: 0,
            circuit_id: c.ww_circuit_id,
            power_kw: 0,
            id: c.clipsal_circuit_id,
            clipsal_monitors: c.clipsal_monitors,
          };
        });
      }
    }
  }

  function getTestStatusIconByStage(stageIndex: number) {
    if (!appliances) return <></>;

    return checkIfTestsAreCompleteByStage(stageIndex) ? (
      <CheckCircleIcon mr={2} color={'primaryBranding.500'} w={5} h={5} />
    ) : (
      <WarningIcon mr={2} color={'red.500'} w={5} h={5} />
    );
  }

  function checkIfTestsAreCompleteByStage(stageIndex: number) {
    if (!appliances) return false;

    const loadTypesByStage = getDisplayedTestsByStage(appliances);
    const sourcesForStage: SourceType[] = loadTypesByStage[stageIndex].map((t) => t.source);

    // Determine whether tests for all sources are in valid states.
    if (sourcesForStage.includes('loads')) {
      // Check non-load sources
      const sourcesWithoutLoads = sourcesForStage.filter((s) => s !== 'loads') as Exclude<SourceType, 'loads'>[];
      const areLoadsValid =
        !!testResults.loads && testResults.loads.every((loadResult) => VALID_STATUSES.includes(loadResult.status));
      const areSourcesValid = sourcesWithoutLoads.every((source) => {
        const testResult = testResults[source];
        return !!testResult && VALID_STATUSES.includes(testResult.status);
      });
      return areLoadsValid && areSourcesValid;
    } else {
      return (sourcesForStage as Exclude<SourceType, 'loads'>[]).every((source) => {
        const testResult = testResults[source];
        const testStatusBasedOnPolarityConfirmation = getTestStatusBasedOnPolarityConfirmation(
          polarityConfirmationStatus[source],
          testResult?.status
        );
        return !!testResult && VALID_STATUSES.includes(testStatusBasedOnPolarityConfirmation);
      });
    }
  }

  useEffect(() => {
    const isStageOneTestComplete = checkIfTestsAreCompleteByStage(0);
    const isStageTwoTestComplete = checkIfTestsAreCompleteByStage(1);

    if (!stageTwoInfoModalType && isStageOneTestComplete && !isStageTwoTestComplete) {
      // Since stage 1 is finished, expand stage 2 for user
      setExpandedIndex(1);
      const hasBackupCircuit = !!appliances.find((appliance) => appliance.appliance_type === 'backup_circuit');
      setStageTwoInfoModalType(hasBackupCircuit ? 'hybrid' : 'grid');
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testResults]);

  function getDisplayedTests() {
    // Just display the grid test if the site only has grid monitors
    if (appliances.every((a) => GRID_CIRCUIT_TYPES.includes(a.appliance_type) || a.appliance_type === 'not_set')) {
      return (
        <TestTile
          {...{
            title: 'Grid',
            source: 'grid',
            link: '/site/:id/meter_setup/configure/:type',
            testStatus: getStatusFromTestType('grid'),
          }}
        />
      );
    } else {
      const stages = getDisplayedTestsByStage(appliances);

      return (
        <Accordion
          index={expandedIndex}
          onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}
          allowToggle
        >
          {stageTwoInfoModalType === 'hybrid' && <GridHybridInfoDrawerButton isOpenByDefault />}
          {stageTwoInfoModalType === 'grid' && <GridInfoDrawerButton isOpenByDefault />}
          {stages.map((tests, stageIndex) => {
            const stage = stageIndex + 1;

            return (
              <AccordionItem isDisabled={stageIndex === 1 && !checkIfTestsAreCompleteByStage(0)} key={stageIndex}>
                <AccordionButton data-testid={`test-stage-${stageIndex}`}>
                  <Flex w={'100%'} justify={'space-between'} align={'center'}>
                    <Flex align="center">
                      <Heading size="sm" fontWeight="500">
                        Stage {stage}
                      </Heading>
                    </Flex>
                    <Flex align={'center'}>
                      {getTestStatusIconByStage(stageIndex)}
                      <AccordionIcon />
                    </Flex>
                  </Flex>
                </AccordionButton>

                <AccordionPanel pb={4}>
                  {tests.map((t) => (
                    <TestTile key={t.source} {...t} testStatus={getStatusFromTestType(t.source)} />
                  ))}

                  <Center py={5} flexDirection="column">
                    <CustomButton
                      color={buttonTextColor}
                      data-testid="run-test"
                      onClick={() => handleRunAllTestsForStage(stageIndex)}
                      my={2}
                    >
                      Run all stage {stage} tests
                    </CustomButton>

                    <Center w={'100%'}>
                      <Button
                        data-testid="skip-test"
                        w={['75%', '75%', '40%']}
                        onClick={async () => {
                          setStageIndexToSkip(stageIndex);
                          await onOpenSkipDialog();
                        }}
                        color={secondaryButtonTextColor}
                        variant={'ghost'}
                      >
                        Skip all stage {stage} tests
                      </Button>
                    </Center>
                  </Center>
                </AccordionPanel>
              </AccordionItem>
            );
          })}

          <SkipTestBottomDrawer
            isOpen={isSkipDialogOpen}
            onClose={(isConfirmed) => {
              if (isConfirmed && stageIndexToSkip !== -1) handleSkipAllTestsForStage(stageIndexToSkip);
              setStageIndexToSkip(-1);
              onCloseSkipDialog();
            }}
          />
        </Accordion>
      );
    }
  }

  return (
    <Box data-testid="meter-test-screen" minHeight={COMPONENT_MIN_HEIGHT}>
      <SubRouteTopNav onGoBack={() => navigate(-1)} title="Source and Load Test" />

      {getDisplayedTests()}

      {checkIfTestsAreComplete(testResults, appliances, polarityConfirmationStatus) && (
        <CustomButton
          data-testid="move-back-to-meter-setup-home"
          onClick={() => {
            setNavigationState({ direction: 'backward' });
            navigate(`/site/${site.clipsal_solar_id}/meter_setup/meters`);
          }}
          mt={6}
        >
          Proceed to Meter Setup Summary
        </CustomButton>
      )}
    </Box>
  );
}

type TestTileProps = {
  testStatus: CircuitTestStatus;
} & LoadType;

function TestTile({ link, source, title, testStatus }: TestTileProps) {
  const { setNavigationState } = useNavigationState();
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const polarityConfirmationStatus = useSelector(selectPolarityConfirmationStatus);
  const testStatusBasedOnPolarityConfirmation = getTestStatusBasedOnPolarityConfirmation(
    polarityConfirmationStatus[source],
    testStatus
  );

  const hoverBackgroundColor = useColorModeValue('gray.50', 'gray.800');

  return (
    <Grid
      _hover={{
        bg: hoverBackgroundColor,
      }}
      cursor={'pointer'}
      onClick={() => {
        const interpolatedLink = link.replace(':id', site.clipsal_solar_id.toString()).replace(':type', source);
        setNavigationState({ direction: 'forward' });
        navigate(interpolatedLink);
      }}
      gridTemplateColumns={'0.2fr 0.8fr 30px'}
      width={'100%'}
      h={'75px'}
      className={'meter-test'}
      key={source}
      data-testid={`test-${source}`}
      role={'button'}
    >
      <Center>
        {testStatusBasedOnPolarityConfirmation !== CircuitTestStatus.InProgress ? (
          <Image w={45} src={TEST_STATUS_TO_CONFIG[testStatusBasedOnPolarityConfirmation].icon} />
        ) : (
          <Spinner size={'xl'} color={'primaryBranding.500'} />
        )}
      </Center>

      <Flex borderBottom={'1px solid rgba(43, 46, 45, 0.1)'} justify={'center'} direction={'column'}>
        <Text mt={1} fontWeight={'bold'}>
          {title}
        </Text>
        <Flex mb={1} justify={'space-between'}>
          <Text
            fontSize={['xs', 'xs', 'md']}
            data-testid={`meter-test-${source}-message`}
            color={TEST_STATUS_TO_CONFIG[testStatusBasedOnPolarityConfirmation].color}
          >
            {TEST_STATUS_TO_CONFIG[testStatusBasedOnPolarityConfirmation].title}
          </Text>
        </Flex>
      </Flex>

      <Center borderBottom={'1px solid rgba(43, 46, 45, 0.1)'}>
        <ChevronRightIcon h={25} w={25} />
      </Center>
    </Grid>
  );
}
