import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CheckCircleIcon, ChevronRightIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonProps,
  Center,
  Collapse,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import CustomSignalIcon from 'clipsal-cortex-ui/src/components/CustomSignalIcon';
import {
  ENERGY_MASTERS_TENANT_ID,
  MODBUS_CONFIGURABLE_METER_MODELS,
  SWITCH_CONFIGURABLE_METER_MODELS,
  WIFI_CONFIGURABLE_METER_MODELS,
} from 'clipsal-cortex-utils/src/constants/common-constants';
import useAppVisibility from 'clipsal-cortex-utils/src/hooks/use-app-visibility';
import useInterval from 'clipsal-cortex-utils/src/hooks/use-interval';

import { Appliance } from '../../../../api/api-appliance';
import { InstalledDevice } from '../../../../api/api-device';
import { del, ErrorResponse, get } from '../../../../api/api-helpers';
import { useAppDispatch } from '../../../../app/hooks';
import Counter from '../../../../common/components/Counter';
import { useNavigationState } from '../../../../context/NavigationProvider';
import { selectUser } from '../../../user/userSlice';
import { setIsSlidingEnabled } from '../../../wizard/wizardSlice';
import { selectSite } from '../../siteSlice';
import { selectBatteries, selectInverters } from '../../system-details/systemDetailsSlice';
import { MeterSetupFormData } from '../meter-setup-form-types';
import { checkIfAppliancesAreAssigned, checkIfTestsAreComplete, getEmptyMeterTemplate } from '../meter-setup-helpers';
import {
  addMeters,
  removeMeter,
  selectAppliances,
  selectLastActiveMeterIndex,
  selectPolarityConfirmationStatus,
  selectRawMeters,
  selectTestStatusV2,
  setAppliances,
  setLastActiveMeterIndex,
} from '../meterSetupSlice';
import { useSapnRegistration } from '../modbus-configuration/siteDeviceConnectionApi';
import { useSwitches } from '../switch-configuration/switchApi';
import MeterDetailsFieldSet from './MeterDetailsFieldSet';

type Props = Readonly<{
  reset: UseFormReset<MeterSetupFormData>;
  control: Control<MeterSetupFormData>;
  setError: UseFormSetError<MeterSetupFormData>;
  register: UseFormRegister<MeterSetupFormData>;
  errors: FieldErrors<MeterSetupFormData>;
  setValue: UseFormSetValue<MeterSetupFormData>;
  getValues: UseFormGetValues<MeterSetupFormData>;
}>;

/**
 * The top-level array of fields. Passes each field to a child component, so each set of fields (i.e. each meter) can
 * manage its own state accordingly.
 *
 */
export default function MeterDetailsFieldArray(props: Props) {
  const { control, reset } = props;
  const { setNavigationState } = useNavigationState();
  const { fields, append, remove } = useFieldArray({
    name: 'meters',
    control,
  });

  const showFormActionButtons = fields.every((field) => field.isLoaded) && !!fields.length;
  const hasSwitchConfigurableMeter =
    fields.findIndex((field) => SWITCH_CONFIGURABLE_METER_MODELS.includes(field.model)) !== -1;
  const hasWifiConfigurableMeter =
    fields.findIndex((field) => WIFI_CONFIGURABLE_METER_MODELS.includes(field.model)) !== -1;
  const hasModbusConfigurableMeter =
    fields.findIndex((field) => MODBUS_CONFIGURABLE_METER_MODELS.includes(field.model)) !== -1;
  const dispatch = useAppDispatch();
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const lastActiveMeterIndex = useSelector(selectLastActiveMeterIndex);
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const navigate = useNavigate();
  const appliances = useSelector(selectAppliances);
  const testStatuses = useSelector(selectTestStatusV2);
  const allMeters = useSelector(selectRawMeters);
  const inverters = useSelector(selectInverters);
  const batteries = useSelector(selectBatteries);
  const isWifiConfigured = useMemo(() => {
    // If one of the meters is a wifi configurable meter and has an ssid, then wifi is configured
    return Object.values(allMeters).some(
      (meter) => WIFI_CONFIGURABLE_METER_MODELS.includes(meter.model) && !!meter.comms.wifi?.ssid
    );
  }, [allMeters]);
  const { switches, isLoading: isSwitchesLoading } = useSwitches(!hasSwitchConfigurableMeter);
  const isSwitchConfigured = useMemo(() => {
    // If one of the switches is configured, then switches are configured
    if (isSwitchesLoading || !hasSwitchConfigurableMeter) return false;
    return switches.some((s) => !!s.appliance_label && s.appliance_label !== 'not_set');
  }, [switches, isSwitchesLoading, hasSwitchConfigurableMeter]);
  const isAppliancesAssigned = checkIfAppliancesAreAssigned(appliances, inverters, batteries);
  const polarityConfirmationStatus = useSelector(selectPolarityConfirmationStatus);
  useEffect(() => {
    setExpandedIndex(fields.length - 1);
  }, [fields.length]);
  const { registerSapn } = useSapnRegistration();
  const isRegisteredWithSapn = site.csip_commissioning_status === 'REGISTERED';
  const toast = useToast({ duration: 3000, isClosable: true });
  const tenantSupportsFlexibleExports = user.role === 'SUPER_ADMIN' || user.tenant_id === ENERGY_MASTERS_TENANT_ID;
  async function handleDeleteMeter(meterId: number | null, serialNumber: string, meterIdx: number) {
    if (window.confirm(`Are you sure you want to delete this meter ${meterIdx + 1}?`)) {
      const totalMetersBeforeDeletion = fields.length;
      remove(meterIdx);

      if (meterId && serialNumber) {
        await del<InstalledDevice>(`/fleet/sites/${site.clipsal_solar_id}/devices/${meterId}`, {});
        dispatch(removeMeter(serialNumber));

        // refetch appliances when the meter is deleted
        // because circuits are also deleted in appliance list
        const appliances = await get<Appliance[]>(`/fleet/sites/${site.clipsal_solar_id}/appliances`);
        dispatch(setAppliances(appliances));
      }
      // check if site has only one meter
      // we need to reset the form to empty template if this is the only meter
      if (totalMetersBeforeDeletion === 1) reset({ meters: [getEmptyMeterTemplate(`${site.site_name} - Meter 1`)] });
    }
  }

  async function handleSapnRegistration() {
    try {
      await registerSapn(site.clipsal_solar_id);
    } catch (e) {
      const errorMessage =
        (e as AxiosError<ErrorResponse>)?.response?.data?.detail ??
        `Something went wrong, check the meter modbus and site's NMI configuration.`;
      toast({
        title: 'Error with SAPN registration',
        description: errorMessage,
        status: 'error',
        isClosable: true,
      });
    }
  }

  usePollingToUpdateLastHeardAt();

  return (
    <Box>
      <Flex align="center" justify="space-between" pb={4} px={[4, 6]}>
        <Text pr={8}>How many meters do you want to add in this site?</Text>
        <Counter
          value={fields.length}
          incrementAriaLabel="Add meter"
          decrementAriaLabel="Remove Meter"
          onIncrement={() => append(getEmptyMeterTemplate(`${site.site_name} - Meter ${fields.length + 1}`))}
          onDecrement={() => {
            const lastMeterIndex = fields.length - 1;
            const { meterId, serialNumber } = fields[lastMeterIndex];
            handleDeleteMeter(meterId, serialNumber, lastMeterIndex);
          }}
          isDecrementDisabled={fields.length <= 1}
        />
      </Flex>
      <Accordion
        defaultIndex={lastActiveMeterIndex}
        allowToggle
        index={expandedIndex}
        onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}
        data-testid="meter-details-accordion"
      >
        {fields.map((field, meterIndex) => {
          const meter = allMeters[field.serialNumber];
          return (
            <AccordionItem data-testid={`meter-${meterIndex}`} key={`${field.id}-${meterIndex}`}>
              <AccordionButton
                data-testid={`meter-${meterIndex}-accordion-button`}
                py={4}
                onClick={() => dispatch(setLastActiveMeterIndex(meterIndex))}
                role={'button'}
                as={Box}
              >
                <Flex w={'100%'} justify={'space-between'} align={'center'}>
                  <Flex>
                    <Heading data-testid={`meter-${meterIndex}-heading`} size={'md'}>
                      Meter {meterIndex + 1}
                    </Heading>
                    {meter && (
                      <Flex ml={2}>
                        <CustomSignalIcon meter={meter} hideText />
                      </Flex>
                    )}
                  </Flex>
                  <Flex align={'center'}>
                    <Button
                      data-testid={`meter-${meterIndex}-remove-btn`}
                      variant={'ghost'}
                      onClick={() => handleDeleteMeter(field.meterId, field.serialNumber, meterIndex)}
                      size={'xs'}
                      ml={2}
                      colorScheme="red"
                      aria-label="Delete meter"
                      // disabled if there is only one meter and it is not assigned
                      isDisabled={fields.length === 1 && !field.meterId}
                    >
                      Remove
                    </Button>
                    <AccordionIcon />
                  </Flex>
                </Flex>
              </AccordionButton>

              <AccordionPanel pb={4} px={[4, 6]} data-testid={`meter-${meterIndex}-accordion-panel`}>
                <MeterDetailsFieldSet {...{ ...props, meterIndex, field }} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>

      <Box>
        <Collapse in={showFormActionButtons}>
          <MeterSubFormActionButton
            data-testid="channel-configuration-button"
            onClick={async () => {
              await dispatch(setIsSlidingEnabled(true));
              setNavigationState({ direction: 'forward' });
              navigate(`/site/${site.clipsal_solar_id}/meter_setup/configure/ct_configuration`);
            }}
            title="Channel Configuration"
            isComplete={isAppliancesAssigned}
          />

          {hasSwitchConfigurableMeter && (
            <MeterSubFormActionButton
              data-testid="switch-configuration-button"
              onClick={async () => {
                await dispatch(setIsSlidingEnabled(true));
                setNavigationState({ direction: 'forward' });
                navigate(`/site/${site.clipsal_solar_id}/meter_setup/configure/switch_configuration`);
              }}
              title="Switch Configuration"
              isComplete={isSwitchConfigured}
              hideIconWhenIncomplete
              isDisabled={!isAppliancesAssigned}
            />
          )}

          <MeterSubFormActionButton
            data-testid="source-load-test-button"
            onClick={async () => {
              await dispatch(setIsSlidingEnabled(true));
              setNavigationState({ direction: 'forward' });
              navigate(`/site/${site.clipsal_solar_id}/meter_setup/configure/meter_tests`);
            }}
            title="Source and Load Test"
            description="Now test sources and loads"
            isComplete={checkIfTestsAreComplete(testStatuses, appliances, polarityConfirmationStatus)}
            isDisabled={!isAppliancesAssigned}
            h={'75px'}
          />

          {hasWifiConfigurableMeter && (
            <MeterSubFormActionButton
              data-testid="wifi-configuration-button"
              onClick={async () => {
                await dispatch(setIsSlidingEnabled(true));
                setNavigationState({ direction: 'forward' });
                navigate(`/site/${site.clipsal_solar_id}/meter_setup/configure/wifi_configuration`);
              }}
              title="Wifi Configuration"
              isComplete={isWifiConfigured}
              hideIconWhenIncomplete
              isDisabled={!isAppliancesAssigned}
            />
          )}

          {site.should_have_flexible_exports && hasModbusConfigurableMeter && tenantSupportsFlexibleExports && (
            <MeterSubFormActionButton
              data-testid="sapn-registration-button"
              onClick={handleSapnRegistration}
              title="Register SAPN Flexible Exports"
              isComplete={isRegisteredWithSapn}
              hideIconWhenIncomplete
              mb={2}
              borderBottom={'none'}
            />
          )}
        </Collapse>
      </Box>
    </Box>
  );
}

const DEFAULT_POLLING_INTERVAL_FOR_LAST_HEARD_AT = 5_000;

function usePollingToUpdateLastHeardAt() {
  const allMeters = useSelector(selectRawMeters);
  const dispatch = useAppDispatch();
  // Used to track time the app is spent minimized
  const appMinimizedTimestamp = useRef<Date | null>(null);

  async function updateLastHeardAt() {
    // Fetch meter data from API and update Redux
    const updatedMeterResponse = await Promise.allSettled(
      Object.values(allMeters).map((meter) =>
        get<WattwatchersMeter>(`/fleet/wattwatchers/installed_meters/${meter.installed_device_id}`)
      )
    );

    // Filter out the meters that are not fulfilled and map the fulfilled meters
    const updatedMeters = updatedMeterResponse
      .filter((settledPromiseResult) => settledPromiseResult.status === 'fulfilled')
      .map((settledPromiseResult) => (settledPromiseResult as PromiseFulfilledResult<WattwatchersMeter>).value);

    dispatch(addMeters(updatedMeters));
  }

  useInterval(updateLastHeardAt, DEFAULT_POLLING_INTERVAL_FOR_LAST_HEARD_AT);

  useEffect(() => {
    // poll for last heard at when the component mounts
    updateLastHeardAt();
  }, []);

  const handleAppVisibilityChange = useCallback((isVisible: boolean) => {
    if (isVisible && appMinimizedTimestamp.current) {
      const now = new Date();
      const timeSinceMinimizedMS = now.getTime() - appMinimizedTimestamp.current.getTime();
      // If the app was minimized for more than the polling interval, update last heard at
      if (timeSinceMinimizedMS > DEFAULT_POLLING_INTERVAL_FOR_LAST_HEARD_AT) updateLastHeardAt();
      appMinimizedTimestamp.current = now;
    } else {
      // App was minimized
      appMinimizedTimestamp.current = new Date();
    }
  }, []);

  useAppVisibility(handleAppVisibilityChange);
}

type MeterSubFormActionButton = {
  isComplete: boolean;
  title: string;
  description?: string;
  hideIconWhenIncomplete?: boolean;
  'data-testid': string;
} & ButtonProps;

function MeterSubFormActionButton({
  isComplete,
  title,
  description,
  hideIconWhenIncomplete,
  ...props
}: MeterSubFormActionButton) {
  const borderColor = useColorModeValue('rgba(43, 46, 45, 0.1)', 'rgba(236, 236, 236, 0.1)');

  const completeIcon = useMemo(() => {
    if (hideIconWhenIncomplete && !isComplete) return null;
    if (isComplete)
      return (
        <CheckCircleIcon
          mr={2}
          color={'primaryBranding.500'}
          w={5}
          h={5}
          data-testid={`${props['data-testid']}-complete-icon`}
        />
      );
    return <WarningIcon mr={2} color={'red.500'} w={5} h={5} data-testid={`${props['data-testid']}-incomplete-icon`} />;
  }, [hideIconWhenIncomplete, isComplete]);

  return (
    <Button
      variant="ghost"
      width={'100%'}
      h={'60px'}
      borderBottom={`1px solid ${borderColor}`}
      px={0}
      rounded={0}
      {...props}
    >
      <Flex w="100%" align="center" justify="space-between">
        <Flex ml={2} flexDir={'column'} justify={'center'} align={'flex-start'}>
          <Heading size={'sm'} fontWeight="500">
            {title}
          </Heading>
          {description && (
            <Text fontWeight="400" fontSize={['sm', 'sm', 'md']} mt={2}>
              {description}
            </Text>
          )}
        </Flex>
        <Center>
          {completeIcon}

          <ChevronRightIcon h={25} w={25} />
        </Center>
      </Flex>
    </Button>
  );
}
