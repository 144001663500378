import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from '../api/api-helpers';

// All tagTypes of injected endpoints must be added to the tagTypes array
const SWITCH_TAG_TYPES = ['Switches', 'SwitchIntelligentControl', 'SwitchStateProfile', 'SwitchSchedules'] as const;
const SITE_USERS_TAG_TYPES = ['SiteUsers', 'InvitedSiteUsers', 'SiteImages'] as const;
const MANAGE_USERS_TAG_TYPES = ['Users', 'User'] as const;
const BATTERY_UPSELL_TAG_TYPES = [
  'BatteryUpgradeOpportunities',
  'BatterySimulations',
  'BatteryUpgradeSummary',
] as const;
const ALERT_TAG_TYPES = ['SiteAlerts', 'TenantAlerts'] as const;
const SITE_TAGS = ['Site', 'NearmapImage'] as const;
const DASHBOARD_TAGS = ['Sites', 'Tenants'] as const;
const FLEET_DASHBOARD_TAGS = [
  'LastUpdated',
  'SiteCountBasedOnOperatingStatus',
  'SiteCountBasedOnCommissioningStatus',
  'FleetEnergy',
  'FleetPower',
  'EnvironmentalImpact',
] as const;
const WATTWATCHER_TAGS = [
  'MeterSearch',
  'ChannelCategories',
  'ShortEnergy',
  'LongEnergy',
  'Logs',
  'FirstEnergyTimestamp',
  'OriginalWattWatchersMeter',
  'WattWatchersMeter',
] as const;
const DATA_CORRECTION_TAGS = ['DataCorrectionChartData'] as const;

/* 
RTK Query makes it possible to trim down your initial bundle size by allowing 
you to inject additional endpoints after you've set up your initial service 
definition. This can be very beneficial for larger applications that may have 
many endpoints. A typical approach would be to have one empty central API 
slice definition. Then, in each feature slice, you would import the central
API slice and add your endpoints to it. This way, you only import the endpoints 
you need for each feature slice, and your initial bundle size is smaller.
*/
export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: [
    ...SWITCH_TAG_TYPES,
    ...SITE_USERS_TAG_TYPES,
    ...MANAGE_USERS_TAG_TYPES,
    ...ALERT_TAG_TYPES,
    ...BATTERY_UPSELL_TAG_TYPES,
    ...SITE_TAGS,
    ...DASHBOARD_TAGS,
    ...FLEET_DASHBOARD_TAGS,
    ...WATTWATCHER_TAGS,
    ...DATA_CORRECTION_TAGS,
    'SiteDeviceConnections',
  ],
  baseQuery: customBaseQuery(),
  endpoints: () => ({}),
});
