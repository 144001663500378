import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { Appliance } from '../../../api/api-appliance';
import { RootState } from '../../../app/store';
import { SourceType, WebsocketStatusResponseV2 } from './circuit-tests/circuit-status-websocket-types';
import { CircuitTestStatus, TestTypeV2 } from './circuit-tests/test-types';
import { GRID_CIRCUIT_TYPES, SOLAR_CIRCUIT_TYPES } from './meter-setup-helpers';

export type MeterSetupState = {
  // Maps the test type to the results for that test type
  testResultsV2: WebsocketStatusResponseV2;
  // Mapped by serial number, used to interact with the WW API
  rawMeterData: Record<string, WattwatchersMeter>;
  lastActiveMeterIndex: number;
  appliances: Appliance[];
  polarityConfirmationStatus: Record<SourceType, boolean>;
};

export const initialState: MeterSetupState = {
  rawMeterData: {},
  testResultsV2: {} as WebsocketStatusResponseV2,
  appliances: [],
  lastActiveMeterIndex: 0,
  polarityConfirmationStatus: {
    hybrid: false,
    battery: false,

    // grid, solar and loads don't require polarity confirmation
    //so always true
    solar: true,
    grid: true,
    loads: true,
  },
};

export const meterSetupSlice = createSlice({
  name: 'meterSetup',
  initialState,
  reducers: {
    setAppliances: (state, action: PayloadAction<Appliance[]>) => {
      return { ...state, appliances: action.payload };
    },
    // Add a meter for configuration, by ID. Initialises status maps for device tests.
    addMeter: (state, action: PayloadAction<WattwatchersMeter>) => {
      state.rawMeterData[action.payload.ww_device_id] = action.payload;
    },
    // Remove a meter for configuration, by ID
    removeMeter: (state, action: PayloadAction<string>) => {
      const serialNumber = action.payload;
      delete state.rawMeterData[serialNumber];
    },
    addMeters: (state, action: PayloadAction<WattwatchersMeter[]>) => {
      // Add multiple meters. Initialises status maps for device tests.
      const rawMetersMap = action.payload.reduce<Record<string, WattwatchersMeter>>((meterMap, rawMeter) => {
        meterMap[rawMeter.ww_device_id] = rawMeter;
        return meterMap;
      }, {});

      state.rawMeterData = { ...state.rawMeterData, ...rawMetersMap };
    },
    updateMeterStatusFromWebSocketV2IgnoreSkipped: (
      state,
      action: PayloadAction<{
        data: WebsocketStatusResponseV2;
        typeToIgnoreSkipped: SourceType | 'all';
        applianceIdToIgnoreSkipped?: number;
      }>
    ) => {
      // @FIXME: Terrible implementation but done at the last minute. Absolutely reconsider this hot steaming garbage.
      const newValue = action.payload.data;
      const typeToIgnoreSkipped = action.payload.typeToIgnoreSkipped;
      const applianceIdToIgnoreSkipped = action.payload?.applianceIdToIgnoreSkipped;
      const oldValue = state.testResultsV2;

      // If we are ignoring all skipped statuses, don't set anything to skipped.
      if (typeToIgnoreSkipped !== 'all') {
        // Check if any of the base types are  being ignored
        (['solar', 'grid', 'hybrid', 'battery'] as Exclude<SourceType, 'loads'>[]).forEach((source) => {
          if (typeToIgnoreSkipped !== source) {
            // Account for skipped states when a new status is received
            const newValueSource = newValue[source];
            const oldValueSource = oldValue[source];
            if (oldValueSource && oldValueSource?.status === CircuitTestStatus.Skipped && newValueSource) {
              newValueSource.status = CircuitTestStatus.Skipped;
              newValueSource[TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
              newValueSource[TestTypeV2.Polarity].circuits.forEach(
                (c) => (c.polarity_test_status = CircuitTestStatus.Skipped)
              );
              newValueSource[TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;
              newValueSource[TestTypeV2.PowerFactor].circuits.forEach(
                (c) => (c.power_factor_test_status = CircuitTestStatus.Skipped)
              );
            }
          }
        });

        if (typeToIgnoreSkipped !== 'loads') {
          const newValueLoads = newValue.loads;
          if (oldValue.loads && newValueLoads) {
            oldValue.loads.forEach((load, i) => {
              if (load.status === CircuitTestStatus.Skipped) {
                newValueLoads[i].status = CircuitTestStatus.Skipped;
                newValueLoads[i][TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
                newValueLoads[i][TestTypeV2.Polarity].circuits.forEach(
                  (c) => (c.polarity_test_status = CircuitTestStatus.Skipped)
                );
                newValueLoads[i][TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;
                newValueLoads[i][TestTypeV2.PowerFactor].circuits.forEach(
                  (c) => (c.power_factor_test_status = CircuitTestStatus.Skipped)
                );
              }
            });
          }
        } else if (applianceIdToIgnoreSkipped) {
          // We might only be skipping a specific load, if the appliance id to skip is supplied to this reducer
          oldValue.loads?.forEach((load, i) => {
            const shouldMaintainSkippedStatus = load.appliance_id !== applianceIdToIgnoreSkipped;
            const newValueLoads = newValue.loads;

            if (shouldMaintainSkippedStatus && load.status === CircuitTestStatus.Skipped && newValueLoads) {
              newValueLoads[i].status = CircuitTestStatus.Skipped;
              newValueLoads[i][TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
              newValueLoads[i][TestTypeV2.Polarity].circuits.forEach(
                (c) => (c.polarity_test_status = CircuitTestStatus.Skipped)
              );
              newValueLoads[i][TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;
              newValueLoads[i][TestTypeV2.PowerFactor].circuits.forEach(
                (c) => (c.power_factor_test_status = CircuitTestStatus.Skipped)
              );
            }
          });
        }
      }

      state.testResultsV2 = newValue;
    },
    /**
     * Status updates for v2 meter test setup
     */
    updateMeterStatusFromWebSocketV2: (state, action: PayloadAction<WebsocketStatusResponseV2>) => {
      const newValue = action.payload;
      const oldValue = state.testResultsV2;

      (['solar', 'grid', 'hybrid', 'battery'] as Exclude<SourceType, 'loads'>[]).forEach((source) => {
        const newValueSource = newValue[source];
        const oldValueSource = oldValue[source];
        // Account for skipped states when a new status is received
        if (oldValueSource?.status === CircuitTestStatus.Skipped && newValueSource) {
          newValueSource.status = CircuitTestStatus.Skipped;
          newValueSource[TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
          newValueSource[TestTypeV2.Polarity].circuits.forEach(
            (c) => (c.polarity_test_status = CircuitTestStatus.Skipped)
          );
          newValueSource[TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;
          newValueSource[TestTypeV2.PowerFactor].circuits.forEach(
            (c) => (c.power_factor_test_status = CircuitTestStatus.Skipped)
          );
        }
      });

      if (oldValue.loads) {
        oldValue.loads.forEach((load, i) => {
          const newValueLoads = newValue.loads;
          if (load.status === CircuitTestStatus.Skipped && newValueLoads) {
            newValueLoads[i].status = CircuitTestStatus.Skipped;
            newValueLoads[i][TestTypeV2.Polarity].status = CircuitTestStatus.Skipped;
            newValueLoads[i][TestTypeV2.Polarity].circuits.forEach(
              (c) => (c.polarity_test_status = CircuitTestStatus.Skipped)
            );
            newValueLoads[i][TestTypeV2.PowerFactor].status = CircuitTestStatus.Skipped;
            newValueLoads[i][TestTypeV2.PowerFactor].circuits.forEach(
              (c) => (c.power_factor_test_status = CircuitTestStatus.Skipped)
            );
          }
        });
      }

      state.testResultsV2 = newValue;
    },
    resetMeters: () => {
      return initialState;
    },
    /**
     * End status updates for old meter test setup
     */
    setLastActiveMeterIndex: (state, action: PayloadAction<number>) => {
      state.lastActiveMeterIndex = action.payload;
    },
    setHybridPolarityConfirmation: (state, action: PayloadAction<boolean>) => {
      state.polarityConfirmationStatus.hybrid = action.payload;
    },
    setBatteryPolarityConfirmation: (state, action: PayloadAction<boolean>) => {
      state.polarityConfirmationStatus.battery = action.payload;
    },
  },
});

export const {
  addMeter,
  removeMeter,
  addMeters,
  resetMeters,
  updateMeterStatusFromWebSocketV2,
  updateMeterStatusFromWebSocketV2IgnoreSkipped,
  setAppliances,
  setLastActiveMeterIndex,
  setHybridPolarityConfirmation,
  setBatteryPolarityConfirmation,
} = meterSetupSlice.actions;

export const selectRawMeter = (serialNumber: string) => (state: RootState) =>
  state.meterSetup.rawMeterData?.[serialNumber] ?? null;

export const selectRawMeters = (state: RootState) => state.meterSetup.rawMeterData;

export const selectAllCircuits = createSelector(
  [(state: RootState) => state.meterSetup.rawMeterData],
  (rawMeterData) => {
    return Object.values(rawMeterData)
      .map((m) => m.circuits)
      .flat();
  }
);

export const selectTestStatusV2 = (state: RootState) => state.meterSetup.testResultsV2;

export const selectLastActiveMeterIndex = (state: RootState) => state.meterSetup.lastActiveMeterIndex;

export const selectLastActiveMeterNumber = (state: RootState) => state.meterSetup.lastActiveMeterIndex + 1;

export const selectAppliances = (state: RootState) => state.meterSetup.appliances;

export const selectGridAppliance = createSelector([(state: RootState) => state.meterSetup.appliances], (appliances) => {
  return appliances.find((a) => GRID_CIRCUIT_TYPES.includes(a.appliance_type));
});

export const selectHybridAppliance = createSelector(
  [(state: RootState) => state.meterSetup.appliances],
  (appliances) => {
    return appliances.find((a) => a.appliance_type === 'hybrid_inverter');
  }
);

export const selectBackupCircuitAppliance = createSelector(
  [(state: RootState) => state.meterSetup.appliances],
  (appliances) => {
    return appliances.find((a) => a.appliance_type === 'backup_circuit');
  }
);

export const selectBatteryAppliance = createSelector(
  [(state: RootState) => state.meterSetup.appliances],
  (appliances) => {
    return appliances.find((a) => a.appliance_type === 'battery_storage');
  }
);

export const selectSolarAppliances = createSelector(
  [(state: RootState) => state.meterSetup.appliances],
  (appliances) => {
    return appliances.filter((a) => SOLAR_CIRCUIT_TYPES.includes(a.appliance_type));
  }
);

export const selectLoadApplianceById = (id: number) => {
  return createSelector([(state: RootState) => state.meterSetup.appliances], (appliances) => {
    return appliances.find((a) => a.appliance_id === id);
  });
};

export const selectPolarityConfirmationStatus = (state: RootState) => state.meterSetup.polarityConfirmationStatus;

export default meterSetupSlice.reducer;
