import React from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { OperatingStatus } from '../../api/api-site-alerts';
import MultiToggleSwitch from '../../common/components/MultiToggleSwitch';
import Table, { FetchDataArgs } from '../../common/components/Table';
import { SearchIcon } from '../../styles/custom-icons';
import useTableColumns from './columns';
import { MOBILE_COLUMN_DISPLAY_TYPES } from './dashboard-helpers';
import { selectDashboardSites } from './dashboardSlice';

type Props = {
  type: OperatingStatus | 'all';
  searchTerm: string;
  isSearchingSites: boolean;
  pageSize: number;
  offset: number;
  onFetchData: (args: FetchDataArgs) => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const getTableSubHeader = (type: OperatingStatus | 'all', totalSites: number) => {
  if (!totalSites || type === 'all') return 'Create a site using the button below';
  return `Please visit again later!`;
};

export default function SitesTable({
  type,
  searchTerm,
  pageSize,
  offset,
  isSearchingSites,
  onFetchData,
  onSearch,
}: Props) {
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    { ssr: false }
  );
  const [columns, [selectedColumnDisplayType, setSelectedColumnDisplayType]] = useTableColumns();
  const { isLoaded, isFetchMoreDataLoaded, sites, sitesCount } = useSelector(selectDashboardSites);
  const switchClassName = useColorModeValue('light-mode-toggle-switch', 'dark-mode-toggle-switch');
  const totalPageCount = Math.ceil(sitesCount / pageSize);

  return (
    <>
      <Box padding="3">
        <InputGroup maxWidth="500">
          <InputLeftElement pointerEvents="none">
            {isSearchingSites ? (
              <Box>
                <Spinner size={'xs'} />
              </Box>
            ) : (
              <SearchIcon color="dusk100.500" _dark={{ color: 'dusk100.200' }} />
            )}
          </InputLeftElement>
          <Input data-testid="dashboard-search" name={'Search'} onChange={onSearch} placeholder={'Search'} />
        </InputGroup>
      </Box>

      {/* Used on mobile viewports to toggle which columns are displayed in the table. */}
      {isMobileViewport && (
        <Box className={switchClassName}>
          <MultiToggleSwitch
            mb={2}
            w="100%"
            fontWeight={500}
            switchOptions={MOBILE_COLUMN_DISPLAY_TYPES}
            onChange={(newValue) => setSelectedColumnDisplayType(newValue)}
            value={selectedColumnDisplayType}
            customClassName="container-padded"
          />
        </Box>
      )}

      {!isLoaded ? (
        <Box h={'50vh'}>
          <CenteredLoader />
        </Box>
      ) : (
        <Table
          id={`${type}-sites-table`}
          totalPageCount={totalPageCount}
          isLoaded={isLoaded}
          columns={columns}
          enablePagination
          isRemotePagination
          data={sites}
          onFetchData={onFetchData}
          searchTerm={searchTerm}
          isFetchMoreDataLoaded={isFetchMoreDataLoaded}
          enableSorting
          defaultPageNumber={offset / pageSize || 0}
          defaultPageSize={pageSize}
          emptyTableStatusConfig={{
            header: `No ${type === 'all' ? '' : type.toLowerCase()} sites found.`,
            subHeader: getTableSubHeader(type, totalPageCount),
            minHeight: '40vh',
          }}
        />
      )}
    </>
  );
}
