import * as yup from 'yup';

const circuitSchema = yup.object().shape({
  type: yup.string().required(),
  name: yup.string().required(),
  voltageReference: yup.string().required(),
});

const meterSchema = yup.object().shape({
  meterId: yup.number().typeError('Serial number may be incorrect. Please review and click Get Meter Data again.'),
  serialNumber: yup.string().required('Serial number is required.'),
});

export const circuitFormSchema = yup.object().shape({
  circuits: yup.array().of(circuitSchema),
});

export const meterFormSchema = yup.object().shape({
  meters: yup.array().of(meterSchema).min(1, 'A site needs at least one meter.'),
});
