import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';

const CORTEX_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.clipsalsolar.pulse';
const CORTEX_APP_STORE_URL = 'itms-apps://apps.apple.com/app/id1488273491';
const CORTEX_PACKAGE_NAME = 'com.clipsalsolar.pulse';

// @TODO: Do we need to include the domain in the deep link URL?
export const openCortexAppAtRoute = async (siteId: number, route = 'dashboard', params = '') => {
  const isProduction = import.meta.env['MODE'] === 'production' && localStorage.getItem('cortexEnvType') !== 'DEV';
  const cortexDomain = `app${isProduction ? '' : '-staging'}.clipsalcortex.com`;
  const cortexSiteUrl = `${cortexDomain}/site/${siteId}/${route}${params}`;
  const cortexDeepLinkUrl = `pulse://${cortexSiteUrl}`;
  const cortexWebUrl = `https://${cortexSiteUrl}`;
  const isAndroid = Capacitor.getPlatform() === 'android';
  if (!Capacitor.isNativePlatform()) {
    window.open(cortexWebUrl, '_blank');
  } else {
    const { value } = await AppLauncher.canOpenUrl({ url: isAndroid ? CORTEX_PACKAGE_NAME : cortexDeepLinkUrl });
    await AppLauncher.openUrl({
      url: value ? cortexDeepLinkUrl : isAndroid ? CORTEX_PLAY_STORE_URL : CORTEX_APP_STORE_URL,
    });
  }
};

export const MOBILE_COLUMN_DISPLAY_TYPES = [
  {
    label: 'System type',
    value: 'system_type',
  },
  {
    label: 'Capacity',
    value: 'solar_capacity_kw',
  },
  {
    label: 'Company',
    value: 'tenant__tenant_name',
  },
];

export const OPERATING_STATUS_CONFIG = {
  FAULT: { badgeColor: 'customRed.500', label: 'Fault' },
  NORMAL: { badgeColor: 'primaryBranding.500', label: 'Normal' },
  OFFLINE: { badgeColor: 'dusk050.500', label: 'Offline' },
};
