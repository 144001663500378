import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex, useBreakpointValue, useColorModeValue, useInterval, useTheme, useToast } from '@chakra-ui/react';
import { SeriesLineOptions } from 'highcharts';
import moment from 'moment';
import { useSelector } from 'react-redux';

import 'moment-timezone'; // side effect

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { get } from '../../../../api/api-helpers';
import { ShortEnergy } from '../../../../api/api-short-energy';
import HighchartContainer from '../../../../common/components/charts/HighchartContainer';
import MultiToggleSwitch from '../../../../common/components/MultiToggleSwitch';
import { POLLING_INTERVAL_MS } from '../../../../common/constants';
import { calculateRelativeMilliseconds, formatTime } from '../../../../utils/datetime-utils';
import { selectSite } from '../../siteSlice';
import { selectAllCircuits, selectRawMeters, selectTestStatusV2 } from '../meterSetupSlice';
import transformMeterData from './chart-data-transformer';
import { getCTsBySource } from './chart-helpers';
import { SourceType } from './circuit-status-websocket-types';
import CountdownTimer from './CountdownTimer';
import { TestTypeV2 } from './test-types';

const COMMON_CHART_DATETIME_FORMATS = {
  second: '%l:%M:%S %P',
  minute: '%l:%M %P',
  hour: '%l %P',
  day: '%e. %b',
  week: '%e. %b',
  month: "%b '%y",
  year: '%Y',
};

interface CircuitTestChartProps {
  testType: TestTypeV2;
  loadApplianceType?: `load_${string}`;
  source: SourceType;
}

export default function CircuitTestChart({ testType, loadApplianceType, source }: CircuitTestChartProps) {
  const toast = useToast();
  const [chartData, setChartData] = useState<Record<string, string | number>[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const chartHeight = useBreakpointValue({
    base: 250,
    md: 350,
  });
  const allMeterCircuits = useSelector(selectAllCircuits);
  const rawMeters = useSelector(selectRawMeters);
  const testStatus = useSelector(selectTestStatusV2);
  const site = useSelector(selectSite);

  const theme = useTheme();
  const { textColor, chartLabelColor, textHoverColor } = useColorModeValue(
    { textColor: theme.colors.gray[900], chartLabelColor: theme.colors.gray[500], textHoverColor: 'black' },
    { textColor: theme.colors.dusk100[50], chartLabelColor: theme.colors.gray[400], textHoverColor: 'white' }
  );

  // gets the latest test timestamp for the line chart
  const getTestTime = () => {
    if (source === 'loads') {
      return testStatus[source]?.find((load) => load.appliance_type === loadApplianceType)?.last_updated_utc as number;
    } else {
      return testStatus[source]?.last_updated_utc || 0;
    }
  };

  const latestTestTime = getTestTime();
  const lastUpdatedTimeStamp = formatTime(new Date(chartData[chartData.length - 1]?.timestamp));

  const transformedChartData = useMemo(() => {
    //Handle filtering the data when the time toggle switch is changed
    const relativeTime = calculateRelativeMilliseconds(5);
    const indexOfEntryAtRelativeTime = chartData.findIndex(({ timestamp }) => {
      if (timestamp !== null) return Number(timestamp) > relativeTime;
      return false;
    });
    const timeFilteredChartData = chartData.slice(indexOfEntryAtRelativeTime);
    const lineChartData = isToggled ? timeFilteredChartData : chartData;
    const reducedLineChartData = lineChartData.reduce((acc, { timestamp, ...rest }) => {
      Object.entries(rest).forEach(([key, value]) => {
        if (!acc[key]) {
          acc[key] = {
            name: key,
            type: 'line',
            data: [],
          };
        }
        acc?.[key]?.data?.push({ x: Number(timestamp), y: Number(value) || null });
      });
      return acc;
    }, {} as Record<string, SeriesLineOptions>);

    return Object.values(reducedLineChartData);
  }, [isToggled, chartData]);

  const getCircuits = () => {
    if (source === 'loads' && loadApplianceType) return getCTsBySource(allMeterCircuits, source, loadApplianceType);
    else return getCTsBySource(allMeterCircuits, source);
  };

  const circuits = getCircuits();

  const getUniqueMeterIdsForTest = useCallback(() => {
    return circuits.reduce<string[]>((uniqueMeterIds, circuit) => {
      const wwMeterId: string = circuit.ww_circuit_id.split('_')[0];
      const meter = rawMeters[wwMeterId];

      if (!meter) throw new Error(`No meter in store for ID ${wwMeterId} from circuit`);

      if (!uniqueMeterIds.includes(meter.installed_device_id as string))
        uniqueMeterIds.push(meter.installed_device_id as string);

      return uniqueMeterIds;
    }, []);
  }, [circuits, rawMeters]);

  const onToggleTimeSwitch = () => {
    setIsToggled(!isToggled);
  };

  const typeToConfig: Record<TestTypeV2, { fetchHandler: () => void; metric: string }> = useMemo(() => {
    return {
      [TestTypeV2.PowerFactor]: {
        metric: ' PF',
        fetchHandler: async () => {
          const uniqueMeterIdsInTest = getUniqueMeterIdsForTest();
          // Fetch data for each meter
          const dataPerMeter = await Promise.all(
            uniqueMeterIdsInTest.map((meterId) =>
              get<ShortEnergy[]>(`/fleet/wattwatchers/installed_meters/${meterId}/short_energy`)
            )
          );
          const uniqueMetersInTest = uniqueMeterIdsInTest.map(
            (id) => Object.values(rawMeters).find((m) => m.installed_device_id === id) as WattwatchersMeter
          );

          setChartData(transformMeterData(dataPerMeter, 'powerFactor', circuits, uniqueMetersInTest));
        },
      },
      [TestTypeV2.Polarity]: {
        metric: ' kW',
        fetchHandler: async () => {
          const uniqueMeterIdsInTest = getUniqueMeterIdsForTest();
          // Fetch data for each meter
          const dataPerMeter = await Promise.all(
            uniqueMeterIdsInTest.map((meterId) =>
              get<ShortEnergy[]>(`/fleet/wattwatchers/installed_meters/${meterId}/short_energy`)
            )
          );
          const uniqueMetersInTest = uniqueMeterIdsInTest.map(
            (id) => Object.values(rawMeters).find((m) => m.installed_device_id === id) as WattwatchersMeter
          );

          setChartData(transformMeterData(dataPerMeter, 'pRealKw', circuits, uniqueMetersInTest));
        },
      },
    };
  }, [circuits, getUniqueMeterIdsForTest, rawMeters]);

  useInterval(async () => {
    if (circuits) {
      try {
        await typeToConfig[testType]?.fetchHandler();
      } catch (e) {
        console.error(e);

        toast({
          title: 'Error loading circuit live data.',
          description: 'Try refreshing the page.',
          status: 'error',
          isClosable: true,
        });
      }
    }
  }, POLLING_INTERVAL_MS);

  useEffect(() => {
    async function fetchInitialData() {
      if (circuits) {
        try {
          await typeToConfig[testType]?.fetchHandler();
          setIsLoaded(true);
        } catch (e) {
          console.error(e);

          toast({
            title: 'Error loading circuit live data.',
            description: 'Try refreshing the page.',
            status: 'error',
            isClosable: true,
          });
        }
      }
    }

    if (!isLoaded) fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoaded, isLoaded]);

  if (!isLoaded) return <CenteredLoader />;

  return (
    <Box>
      {chartData.length && (
        <Box>
          <Flex justify="space-between">
            <CountdownTimer timestamp={lastUpdatedTimeStamp} />
            <TimeSwitchToggle testType={testType} isToggled={isToggled} onToggleTimeSwitch={onToggleTimeSwitch} />
          </Flex>

          <HighchartContainer
            series={transformedChartData}
            xAxis={{
              plotLines: [
                {
                  color: textColor,
                  width: 2,
                  value: latestTestTime * 1000,
                  dashStyle: 'ShortDash',
                  label: {
                    text: 'Tested ' + new Date(latestTestTime * 1000).toLocaleTimeString('en-US'),
                    style: { color: textColor },
                  },
                },
              ],
              labels: { style: { color: chartLabelColor } },
              type: 'datetime',
              dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
            }}
            yAxis={{ labels: { style: { color: chartLabelColor }, format: '{text} kW' } }}
            time={{
              moment: moment,
              timezone: site.timezone,
            }}
            chart={{ height: chartHeight, backgroundColor: 'transparent' }}
            tooltip={{ dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS, valueSuffix: ' kW' }}
            legend={{
              itemStyle: { color: textColor },
              itemHoverStyle: { color: textHoverColor },
            }}
          />
        </Box>
      )}
    </Box>
  );
}

interface TimeSwitchToggleProps {
  testType: TestTypeV2;
  isToggled: boolean;
  onToggleTimeSwitch: () => void;
}

const TimeSwitchToggle = ({ testType, isToggled, onToggleTimeSwitch }: TimeSwitchToggleProps) => {
  const switchClassName = useColorModeValue('light-mode-toggle-switch', 'dark-mode-toggle-switch-2');
  return (
    <Box fontWeight="bold" fontSize={['xx-small', 'md']} my={2} pr={2} minW={[null, 40]} className={switchClassName}>
      <MultiToggleSwitch
        data-testid={`meter-chart-time-switch-${testType}`}
        switchOptions={[
          { value: 'false', label: '30 min' },
          { value: 'true', label: '5 min' },
        ]}
        onChange={onToggleTimeSwitch}
        value={isToggled.toString()}
      />
    </Box>
  );
};
