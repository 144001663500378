import React, { useMemo } from 'react';

import { useGetTenantsQuery } from '../../features/dashboard/dashboardApi';
import SearchableDropdown from './SearchableDropdown';

type TenantSelectProps = {
  value: number;
  onChange: (value: string | number) => void;
  isDisabled?: boolean;
  includeAllTenants?: boolean;
};

export const TenantSelect = ({ value, onChange, isDisabled, includeAllTenants = true }: TenantSelectProps) => {
  const { data: tenants = [], isLoading } = useGetTenantsQuery();

  const selectOptions = useMemo(() => {
    const options = [];

    if (includeAllTenants) options.push({ value: 0, label: 'All Tenants' });

    // Add tenants to the options list
    options.push(
      ...tenants.map((tenant) => ({
        value: tenant.tenant_id,
        label: tenant.tenant_name,
      }))
    );

    return options;
  }, [tenants, includeAllTenants]);

  return (
    <SearchableDropdown
      options={selectOptions}
      onChange={onChange}
      value={value}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isOptionsSorted={false}
      placeholder="Select a tenant"
    />
  );
};
