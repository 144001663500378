/**
 * Converts temperature in Celsius to Fahrenheit
 *
 *
 * @param celsius Number in Celsius
 */
export function convertCelsiusToFahrenheit(celsius: number) {
  return Math.round(celsius * (9 / 5) + 32);
}

/**
 * Round a number to the given number of sig. figures (default 2).
 *
 * @param num - The number to be rounded.
 * @param significantFigures - The number of sig. figures.
 */
export function roundNumber(num: number, significantFigures = 2) {
  const roundOffHelperNumber = 10 ** significantFigures;
  // The Number.EPSILON property represents the difference between 1
  // and the smallest floating point number greater than 1.
  return Math.round((num + Number.EPSILON) * roundOffHelperNumber) / roundOffHelperNumber;
}
