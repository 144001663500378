import React from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, IconButton, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MobileTopNav from '../../common/components/MobileTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT, TOP_NAV_SPACING_AFFORDANCE } from '../../common/constants';
import ExperimentalFeaturesModal from './ExperimentalFeatures';

import './dashboard.css';

import AlertsNotificationIconButton from '../../common/components/AlertsNotificationIconButton';
import { selectUser } from '../user/userSlice';
import SiteList from './SiteList';

export default function Dashboard() {
  const navigate = useNavigate();
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );
  const user = useSelector(selectUser);
  const {
    isOpen: isExperimentalFeaturesModalOpen,
    onOpen: onExperimentalFeaturesModalOpen,
    onClose: onExperimentalFeaturesModalClose,
  } = useDisclosure();

  async function handleCreateSite() {
    navigate('/site/new/start');
  }

  return (
    <Box
      className="dashboard"
      px={[0, 0, 10]}
      pb={isMobileViewport ? BOTTOM_NAV_HEIGHT : 1}
      minHeight={isMobileViewport ? `calc(${COMPONENT_MIN_HEIGHT} - ${TOP_NAV_SPACING_AFFORDANCE})` : `100vh`}
    >
      {isMobileViewport ? (
        <MobileTopNav title={'Sites'} backURL={''} />
      ) : (
        <Flex justify={'space-between'} w={'100%'} alignItems="center" pt={4}>
          <Text fontWeight={400} fontSize="xl">
            Sites
          </Text>

          <Box mr={4} borderRadius={100} bg={'white'} _dark={{ bg: 'whiteAlpha.200' }} shadow="md">
            <AlertsNotificationIconButton />
          </Box>
        </Flex>
      )}

      {user.role === 'SUPER_ADMIN' && (
        <Button my={3} mx={[3, 3, 0]} onClick={onExperimentalFeaturesModalOpen}>
          Experimental Features
        </Button>
      )}

      <SiteList />

      <ExperimentalFeaturesModal isOpen={isExperimentalFeaturesModalOpen} onClose={onExperimentalFeaturesModalClose} />

      <IconButton
        rounded={50}
        p={5}
        py={7}
        boxShadow={'0px 5px 10px rgba(0, 0, 0, 0.25);'}
        colorScheme={'primaryButton'}
        zIndex={10}
        position="fixed"
        bottom={isMobileViewport ? `calc(${BOTTOM_NAV_HEIGHT} + 10px)` : '10px'}
        right="10px"
        data-testid={'create-new-site-btn'}
        aria-label="Create new site"
        icon={<AddIcon />}
        onClick={handleCreateSite}
      />
    </Box>
  );
}
