/**
 * Formats a `Date` object to a time string as `HH:MM:SS`.
 *
 * @param date
 * @returns {string}
 */
export function formatTime(date: Date) {
  const stringifyConfig = { minimumIntegerDigits: 2, useGrouping: false };
  const hours = date.getHours();
  const minutes = date.getMinutes().toLocaleString('en-US', stringifyConfig);
  const seconds = date.getSeconds().toLocaleString('en-US', stringifyConfig);
  return `${hours}:${minutes}:${seconds}`;
}

/**
 * Formats a `Date` object, according to the string structure provided from the API.
 *
 * @param date - The date object to format.
 */
export function formatDate(date: Date): string {
  return `${date.getFullYear()}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
    date
  )}-${new Intl.DateTimeFormat('en', {
    day: '2-digit',
  }).format(date)}`;
}

export enum DateStringFormatType {
  DayShortMonthYear,
  DayLongMonthYear,
}

export const dateTypeToFormatOpts: Record<DateStringFormatType, object> = {
  [DateStringFormatType.DayShortMonthYear]: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  [DateStringFormatType.DayLongMonthYear]: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
};

/**
 * Formats a `Date` object to a nice format, specified by the `format` parameter.
 *
 * @param date - The `Date` object to format.
 * @param format - The format enum type.
 * @param locales - One or more locales to format the date string with. Optional.
 */
export function formatNiceDate(
  date: Date | null,
  format: DateStringFormatType = DateStringFormatType.DayShortMonthYear,
  locales?: string | string[]
): string {
  if (!date) return '';

  return date.toLocaleDateString(locales, dateTypeToFormatOpts[format]);
}

/**
 * Calculates a time in the past relative to the current time.
 *
 * @param minutesToSubtract - The time in minutes to subtract from the current time.
 */
export function calculateRelativeMilliseconds(minutesToSubtract: number): number {
  const convertToMilliseconds = (val: number) => val * 60000;
  const currentTimeInMs = new Date().valueOf();
  const minutesToSubtractInMs = convertToMilliseconds(minutesToSubtract);
  return currentTimeInMs - minutesToSubtractInMs;
}

/**
 * Accepts a 24 hour time string formatted HH:MM:SS
 * and returns the respective 12 hour formatted string, including AM/PM.
 * Optionally includes seconds.
 *
 * @param time - The time string to convert.
 * @param includeSeconds - Whether to include seconds in the converted time string.
 */
export function formatFull24TimeTo12Hours(time: string, includeSeconds = false) {
  const regEx = /^(?:(?:([01]?\d|2[0-3]):)([0-5]\d):)?([0-5]\d)$/;
  if (!regEx.test(time)) throw Error('Invalid time format passed to formatter.');

  const [hoursString, minutes, seconds] = time.split(':');
  let hours = Number(hoursString);

  let isPM = false;
  if (hours >= 12) {
    isPM = true;
    hours -= 12;
  }
  if (hours === 0) hours = 12;

  return `${hours}:${minutes}${includeSeconds ? ':' + seconds : ''} ${isPM ? 'PM' : 'AM'}`;
}
