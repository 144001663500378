import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { CommonFieldListProps, CTConfigFormData } from './ct-configuration-form-types';

/**
 * This component watches changes in forms and composes new reservedCTs object
 * when form state changes. Also solves syncing state issues in react hook form
 * @param control control object of react hook form
 * @param batteryApplianceIndex index of the batteryAppliance
 */
export const useReservedCTsForBattery = (control: CommonFieldListProps['control'], batteryApplianceIndex: number) => {
  const watchFormValues = useWatch({ control }) as CTConfigFormData;

  return useMemo(() => {
    const { grid, solar, load, battery, hybrid, evCharger } = watchFormValues;
    return battery.reduce(
      (acc: ChannelData[], val, index) => {
        if (!val.circuits || batteryApplianceIndex === index) return acc;
        else return [...acc, ...val.circuits];
      },
      [
        ...grid,
        ...solar.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
        ...Object.values(hybrid).flat(),
        ...load.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
        ...evCharger.reduce((acc: ChannelData[], val) => [...acc, ...val.circuits], []),
      ]
    );
  }, [watchFormValues, batteryApplianceIndex]);
};
