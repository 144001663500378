import React from 'react';
import { Flex, Heading, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks';
import { setLastVisitedSitePath } from '../../features/dashboard/dashboardSlice';
import { selectUser } from '../../features/user/userSlice';
import CustomButton from './CustomButton';

interface Props {
  mobileImgSource: string;
  desktopImgSource: string;
  secondaryText: string;
  showGreeting?: boolean;
}

const NewUserDashboardTemplate = ({ mobileImgSource, desktopImgSource, secondaryText, showGreeting = true }: Props) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileViewport = useBreakpointValue({
    base: true,
    xl: false,
  });

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      minHeight="50vh"
      minWidth="100%"
      data-testid="new-user-dashboard-template"
    >
      <Image src={isMobileViewport ? mobileImgSource : desktopImgSource} w={['100%', '80%', '45%', '45%', '55%']} />
      {showGreeting && (
        <Heading size="md" px={4} mt={isMobileViewport ? 8 : 4}>
          Hi {user.user_full_name}!
        </Heading>
      )}
      <Text mt={3} px={4} textAlign="center">
        {secondaryText}
      </Text>

      <CustomButton
        rounded={4}
        w="400px"
        maxW="80%"
        mt={6}
        data-testid="create-new-site-button"
        onClick={() => {
          navigate('/site/new/start');
          dispatch(setLastVisitedSitePath('/fleet_dashboard'));
        }}
      >
        Create new site
      </CustomButton>
    </Flex>
  );
};

export default NewUserDashboardTemplate;
