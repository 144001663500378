import React from 'react';

import { Polarity, VoltageReference } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { ElectricalConfiguration } from '../../../../api/api-site';
import QuestionIcon from '../../../../assets/images/blue_question_icon.svg';
import greenTickIcon from '../../../../assets/images/green_tick_icon.svg';
import incompleteIcon from '../../../../assets/images/grey_incomplete_test_icon.svg';
import redExclamationIcon from '../../../../assets/images/red_exclamation_icon.svg';
import blackRetestIcon from '../../../../assets/images/retest_icon.svg';
import yellowSkippedIcon from '../../../../assets/images/yellow_skipped_icon.svg';
import { VOLTAGE_REFERENCE_TYPES } from '../../../../common/constants';
import { SelectOption } from '../../../../common/types/types';
import { PolarityNormalIcon, PolarityReverseIcon } from '../../../../styles/custom-icons';

export type TestStatusConfig = {
  title: string;
  icon: string;
  color: string;
  secondaryColor: string;
};

// Signal tests aren't relevant in circuit context - exclude their status types.
export enum CircuitTestStatus {
  Uninitialized = 'UNINITIALIZED',
  Undetermined = 'UNDETERMINED',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  Skipped = 'SKIPPED',
  Retest = 'RETEST',
  NeedConfirmation = 'NEED_CONFIRMATION',
}

export enum TestTypeV2 {
  PowerFactor = 'power_factor_result',
  Polarity = 'polarity_result',
}

// Ordered by importance, e.g. if at least _one_ test is failed, the status is considered failed.
export const ORDERED_TEST_RESULTS_BY_IMPORTANCE = [
  CircuitTestStatus.InProgress,
  CircuitTestStatus.NeedConfirmation,
  CircuitTestStatus.Failed,
  CircuitTestStatus.Undetermined,
  CircuitTestStatus.Uninitialized,
  CircuitTestStatus.Retest,
  CircuitTestStatus.Skipped,
  CircuitTestStatus.Success,
];

export const TEST_STATUS_TO_CONFIG: Record<CircuitTestStatus, TestStatusConfig> = {
  [CircuitTestStatus.Uninitialized]: {
    title: 'No data',
    icon: incompleteIcon,
    color: 'dusk050.500',
    secondaryColor: 'rgba(155, 160, 161, 0.3)',
  },
  [CircuitTestStatus.Undetermined]: {
    title: 'Insufficient data',
    icon: incompleteIcon,
    color: 'dusk050.500',
    secondaryColor: 'rgba(155, 160, 161, 0.3)',
  },
  [CircuitTestStatus.InProgress]: {
    title: 'Performing test...',
    icon: incompleteIcon,
    color: 'primaryBranding.500',
    secondaryColor: 'rgba(61, 205, 88, 0.3)',
  },
  [CircuitTestStatus.Failed]: {
    title: 'Needs attention',
    icon: redExclamationIcon,
    color: 'customRed.500',
    secondaryColor: 'rgba(229, 62, 62, 0.3)',
  },
  [CircuitTestStatus.Success]: {
    title: 'Test passed',
    icon: greenTickIcon,
    color: 'primaryBranding.500',
    secondaryColor: 'rgba(61, 205, 88, 0.3)',
  },
  [CircuitTestStatus.Skipped]: {
    title: 'Skipped test',
    icon: yellowSkippedIcon,
    color: 'customOrange.500',
    secondaryColor: 'rgba(255, 198, 52, 0.3)',
  },
  [CircuitTestStatus.Retest]: {
    title: 'Rerun the test',
    icon: blackRetestIcon,
    color: 'slate.500',
    secondaryColor: 'rgba(171, 186, 195, 0.3)',
  },
  [CircuitTestStatus.NeedConfirmation]: {
    title: 'Needs Confirmation',
    icon: QuestionIcon,
    color: 'customLinkBlue.500',
    secondaryColor: 'rgba(0, 143, 232, 0.3)',
  },
};

export const selectedPhaseValueToReferenceTypes: Record<ElectricalConfiguration, SelectOption<VoltageReference>[]> = {
  '1ph': VOLTAGE_REFERENCE_TYPES.slice(0, 1),
  '2ph': VOLTAGE_REFERENCE_TYPES.slice(0, 2),
  '3ph': VOLTAGE_REFERENCE_TYPES.slice(0, 3),
};

// Only display the voltage reference letter here. Mapped as a const to avoid necessity to memoize.
export const DISPLAYED_VOLTAGE_REF_TYPES: Record<ElectricalConfiguration, SelectOption<VoltageReference>[]> = {
  '1ph': selectedPhaseValueToReferenceTypes['1ph'].map((p) => ({ ...p, label: p.label[p.label.length - 1] })),
  '2ph': selectedPhaseValueToReferenceTypes['2ph'].map((p) => ({ ...p, label: p.label[p.label.length - 1] })),
  '3ph': selectedPhaseValueToReferenceTypes['3ph'].map((p) => ({ ...p, label: p.label[p.label.length - 1] })),
};

export const POLARITY_TYPES: { value: Polarity; label: JSX.Element }[] = [
  { value: 'normal', label: <PolarityNormalIcon h={5} /> },
  { value: 'reverse', label: <PolarityReverseIcon h={5} /> },
];
