import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { ShortEnergy } from '../../../../api/api-short-energy';
import { formatTime } from '../../../../utils/datetime-utils';
import { GRID_CIRCUIT_TYPES, SOLAR_CIRCUIT_TYPES } from '../meter-setup-helpers';
import { SourceType } from './circuit-status-websocket-types';
import { TestTypeV2 } from './test-types';

// Each record value is a number/string union, because the `timestamp` key is always a string.
// The plotted chart values are always numbers.
export type ShortChartData = {
  powerReal: Record<string, number | string>[];
  powerReactive: Record<string, number | string>[];
  current: Record<string, number | string>[];
  voltage: Record<string, number | string>[];
  powerFactor: Record<string, number | string>[];
};

export const CHART_METRIC_TO_UNIT: Record<keyof ShortChartData, string> = {
  current: ' A',
  voltage: ' V',
  powerReactive: ' kW',
  powerReal: ' kW',
  powerFactor: '',
};

export const METER_TEST_TYPE_TO_METRIC: Record<TestTypeV2, keyof ShortChartData | null> = {
  [TestTypeV2.Polarity]: 'powerReactive',
  [TestTypeV2.PowerFactor]: 'powerFactor',
};

/**
 * Converts the short energy data retrieved from the API into a format to be used in the chart.
 *
 * @param data - The original chart data to convert.
 * @param channels - The channels (circuits) to map the data to.
 */
export function buildShortChartData(data: ShortEnergy[], channels: ChannelData[]): ShortChartData {
  // Fixed-length 2d array of values, each top-level element representing a chart metric (e.g. voltage, current etc.).
  const powerArrayValues: Record<string, number | string>[][] = [[], [], [], [], []];

  data.forEach(
    ({
      pRealKw: powerRealValueArray,
      pReactiveKw: powerReactiveValueArray,
      iRMS: currentValueArray,
      vRMS: voltageValueArray,
      powerFactor: powerFactorValueArray,
      timestamp,
    }) => {
      const indexToValueArray = [
        powerRealValueArray,
        powerReactiveValueArray,
        currentValueArray,
        voltageValueArray,
        powerFactorValueArray,
      ];

      powerArrayValues.forEach((chartData, index) => {
        const entry: Record<string, number | string> = {
          timestamp: formatTime(new Date(timestamp * 1000)),
        };

        channels.forEach((channel, channelIndex) => {
          // For each channel, assign a new key
          entry[`${channel.circuit_name} (CH ${channelIndex + 1})`] = indexToValueArray[index][channelIndex];
        });

        chartData.push(entry);
      });
    }
  );

  return {
    powerReal: powerArrayValues[0],
    powerReactive: powerArrayValues[1],
    current: powerArrayValues[2],
    voltage: powerArrayValues[3],
    powerFactor: powerArrayValues[4],
  };
}

export const getCTsBySource = (circuits: ChannelData[], source: SourceType, loadApplianceType?: `load_${string}`) => {
  if (source === 'grid') return circuits.filter((circuit) => GRID_CIRCUIT_TYPES.includes(circuit.clipsal_monitors));
  else if (source === 'solar')
    return circuits.filter((circuit) => SOLAR_CIRCUIT_TYPES.includes(circuit.clipsal_monitors));
  else if (source === 'hybrid')
    return circuits.filter((circuit) => ['hybrid_inverter', 'backup_circuit'].includes(circuit.clipsal_monitors));
  else if (source === 'battery')
    return circuits.filter((circuit) => ['battery_storage'].includes(circuit.clipsal_monitors));
  else {
    if (!loadApplianceType) throw new Error(`Load appliance type must be supplied to getCTsBySource.`);
    return circuits.filter((circuit) => loadApplianceType.includes(circuit.clipsal_monitors));
  }
};
