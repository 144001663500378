import React, { useState } from 'react';
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';
import TimePicker from 'clipsal-cortex-ui/src/components/time-picker/TimePicker';

import CustomButton from '../../../../../common/components/CustomButton';

type EditTimeModalProps = {
  onAccept: (selectedTime: string) => void;
  initialValue: string;
} & DialogProps;

type TimeSelectState = {
  hours: number;
  minutes: number | string; // This is a string when prefixed by a `0`, e.g. any number < 9
  amOrPm: 'AM' | 'PM';
};

const MINUTES_5_MIN_INTERVAL = Array.from(Array(12)).map((_, i) => {
  const minuteNumber = i * 5;
  return minuteNumber < 10 ? `0${minuteNumber}` : minuteNumber;
});

export default function EditTimeModal({ isOpen, onClose, onAccept, initialValue }: EditTimeModalProps) {
  const initialState = getInitialState();
  const [state, setState] = useState<TimeSelectState>(initialState);
  const { hours, minutes, amOrPm } = state;
  const timePickerGradientColor = useColorModeValue('253, 253, 253', '41, 49, 51');
  const timePickerBorderColor = useColorModeValue('black', 'white');

  function getInitialState() {
    const selectedTime = initialValue;

    if (!selectedTime) {
      return {
        hours: 12,
        minutes: 0,
        amOrPm: 'AM',
      } as TimeSelectState;
    }

    // Convert saved schedule time back to 12 hour format
    const [hoursFromSchedule, minutesFromSchedule] = selectedTime.split(':');
    let hoursDefaultValue = Number(hoursFromSchedule) > 12 ? Number(hoursFromSchedule) - 12 : Number(hoursFromSchedule);
    if (hoursDefaultValue === 0) hoursDefaultValue = 12;

    return {
      hours: hoursDefaultValue,
      minutes: Number(minutesFromSchedule),
      amOrPm: Number(hoursFromSchedule) > 12 ? 'PM' : 'AM',
    } as TimeSelectState;
  }

  function handleSave() {
    const minutesPadded = minutes < 9 ? `0${minutes}` : minutes;
    let hours24hTime = amOrPm === 'PM' && hours !== 12 ? hours + 12 : hours;
    if (hours === 12 && amOrPm === 'AM') {
      hours24hTime = 0;
    }
    const result = `${hours24hTime}:${minutesPadded}`;

    onAccept(result);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid="edit-schedule-time-modal">
        <ModalHeader fontSize="md" pr={12}>
          Select Time
        </ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody display="flex" flexDirection="column" justifyContent="center" mx={5}>
          <Center as={'button'} w={'100%'} borderBottom={'1px solid'} borderColor={'#C6C6C6'} py={3}>
            <TimePicker
              minuteOptions={MINUTES_5_MIN_INTERVAL}
              sliderColumnWidth={'75px'}
              defaultHours={hours}
              defaultMinutes={minutes}
              defaultAmOrPm={amOrPm}
              onChange={(value) => {
                setState((prevState) => {
                  return { ...prevState, ...value };
                });
              }}
              gradientColor={timePickerGradientColor}
              borderColor={timePickerBorderColor}
            />
          </Center>
        </ModalBody>

        <ModalFooter display="flex" flexDirection="column" alignItems="flex-start">
          <CustomButton
            data-testid="save-schedule-time"
            type="button"
            mx="auto"
            w="fit-content"
            px={10}
            py={4}
            my={0}
            onClick={handleSave}
          >
            Save
          </CustomButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
