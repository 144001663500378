import { useCallback, useState } from 'react';
import { lastDayOfMonth } from 'date-fns';

import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { formatDate } from '../../utils/datetime-utils';
import { DateRangeType } from './DateRangeTypePicker';
import { DEFAULT_FLEET_DATA, FIRST_YEAR_OF_DATA, getDefaultFleetDataOptions } from './fleet-dashboard-helpers';
import {
  useGetFleetEnergyQuery,
  useGetFleetPowerQuery,
  useGetLastUpdatedDate,
  useGetSiteCountBasedOnCommissioningStatus,
} from './fleetDashboardApi';

export const useFleetData = (selectedTenantId: number) => {
  const [{ selectedDateRangeType, startDate, endDate, aggregation, selectedPeriod }, setState] = useState(
    getDefaultFleetDataOptions()
  );
  const { numActiveSites, numPendingSites, isLoadingSitesBasedOnCommissioningStatus } =
    useGetSiteCountBasedOnCommissioningStatus(selectedTenantId);
  const { lastUpdated, isLoading: isLoadingLastUpdatedAt } = useGetLastUpdatedDate(selectedTenantId);
  const isWidgetDataLoaded = !isLoadingSitesBasedOnCommissioningStatus && !isLoadingLastUpdatedAt;

  const handleUpdateDateRangeTypeAndPeriod = useCallback(
    (selectedDateRangeType: DateRangeType, selectedPeriod: string) => {
      // Update the selected period based on the selected date range type
      const selectedDateRangeTypeToCaller: Record<DateRangeType, () => void> = {
        [DateRangeType.Day]: () => {
          const zonedTime = new Date(selectedPeriod);
          zonedTime.setHours(0, 0, 0, 0); // Set to midnight user's time
          const startDate = zonedTime.toISOString();
          zonedTime.setDate(zonedTime.getDate() + 1);
          const endDate = zonedTime.toISOString();
          setState((prevState) => ({
            ...prevState,
            selectedDateRangeType,
            selectedPeriod,
            startDate,
            endDate,
            aggregation: 'DAY',
          }));
        },
        [DateRangeType.Week]: () => {
          const firstDayOfWeek = getFirstDayOfWeek(new Date(selectedPeriod));
          const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
          const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 6));
          setState((prevState) => ({
            ...prevState,
            selectedDateRangeType,
            selectedPeriod,
            startDate: formatDate(firstDayOfWeek),
            endDate: formatDate(lastDayOfWeek),
            aggregation: 'DAY',
          }));
        },
        [DateRangeType.Month]: () => {
          const startDate = new Date(selectedPeriod);
          const endDate = lastDayOfMonth(startDate);
          startDate.setDate(1);
          setState((prevState) => ({
            ...prevState,
            selectedDateRangeType,
            selectedPeriod,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            aggregation: 'DAY',
          }));
        },
        [DateRangeType.Year]: () => {
          const selectedYear = new Date(selectedPeriod).getFullYear();
          const startDate = `${selectedYear}-01-01`;
          const endDate = `${selectedYear}-12-31`;
          setState((prevState) => ({
            ...prevState,
            selectedDateRangeType,
            selectedPeriod,
            startDate,
            endDate,
            aggregation: 'MONTH',
          }));
        },
        [DateRangeType.All]: () => {
          const startDate = `${FIRST_YEAR_OF_DATA}-01-01`;
          const endDate = formatDate(new Date());
          setState((prevState) => ({
            ...prevState,
            selectedDateRangeType,
            selectedPeriod,
            startDate,
            endDate,
            aggregation: 'YEAR',
          }));
        },
      };

      selectedDateRangeTypeToCaller[selectedDateRangeType]();
    },
    []
  );

  const {
    data: fleetEnergyData,
    isLoading: isLoadingFleetEnergyData,
    isFetching: isFetchingFleetEnergyData,
  } = useGetFleetEnergyQuery(
    { tenantId: selectedTenantId, startDate, endDate, aggregation },
    { skip: selectedDateRangeType === DateRangeType.Day }
  );

  const {
    data: fleetPowerData,
    isLoading: isLoadingFleetPowerData,
    isFetching: isFetchingFleetPowerData,
  } = useGetFleetPowerQuery(
    { tenantId: selectedTenantId, startDate, endDate },
    { skip: selectedDateRangeType !== DateRangeType.Day }
  );

  const isChartLoaded =
    !isLoadingFleetEnergyData && !isFetchingFleetEnergyData && !isLoadingFleetPowerData && !isFetchingFleetPowerData;

  return {
    fleetData: (selectedDateRangeType === DateRangeType.Day ? fleetPowerData : fleetEnergyData) ?? DEFAULT_FLEET_DATA,
    handleUpdateDateRangeTypeAndPeriod,
    isWidgetDataLoaded,
    selectedDateRangeType,
    isChartLoaded,
    selectedPeriod,
    lastUpdated,
    showNewUserDashboardTemplate: !isLoadingSitesBasedOnCommissioningStatus && !(numActiveSites + numPendingSites),
  };
};
