import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useTheme } from '@chakra-ui/react';

interface Props {
  backgroundColor: string;
}

export const useStatusBar = ({ backgroundColor }: Props) => {
  const { colors } = useTheme();
  const isNativePlatform = Capacitor.isNativePlatform();

  useEffect(() => {
    const isDarkBackGround = backgroundColor === 'customBodyBackground.800';
    const backgroundHexColor = isDarkBackGround ? colors.gray[900] : colors.white;
    const statusBarTextStyle = isDarkBackGround ? 'Dark' : 'Light';

    if (isNativePlatform) {
      const setStatusBarStyle = async () => {
        await StatusBar.setStyle({ style: Style[statusBarTextStyle] });
        if (Capacitor.getPlatform() === 'android') {
          await StatusBar.setBackgroundColor({ color: backgroundHexColor });
        }
      };
      setStatusBarStyle();
    }
  }, [backgroundColor, isNativePlatform, colors]);
};
