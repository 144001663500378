import * as yup from 'yup';

const circuitSchema = yup.object().shape({
  clipsal_monitors: yup.string().required(),
  circuit_name: yup.string().required('Circuits must have a name'),
  voltage_reference: yup.string().required(),
});

const solarSchema = yup.object().shape({
  applianceId: yup.number().nullable(true),
  circuits: yup.array().of(circuitSchema).min(1, 'Please select at least one CT for solar'),
});

const loadSchema = yup.object().shape({
  applianceId: yup.number().nullable(true),
  loadName: yup.string().required('*Load name is required'),
  loadType: yup.string().required('*Load type is required'),
  circuits: yup.array().of(circuitSchema).min(1, 'Please select at least one CT'),
});

const batterySchema = yup.object().shape({
  applianceId: yup.number().nullable(true),
  circuits: yup.array().of(circuitSchema).min(1, 'Please select at least one CT for battery'),
});

const evChargerSchema = yup.object().shape({
  applianceId: yup.number().nullable(true),
  controlDeviceRowId: yup.number().required('*Site device id is required'),
  loadName: yup.string().required('*Load name is required'),
  circuits: yup.array().of(circuitSchema).min(1, 'Please select at least one CT for the EV charger'),
});

export const ctConfigFormSchema = yup.object().shape({
  hasAcCoupledInverter: yup.boolean(),
  hasDcCoupledInverter: yup.boolean(),
  hasBattery: yup.boolean(),
  hasEVCharger: yup.boolean(),
  grid: yup
    .array()
    .of(circuitSchema)
    .min(1, 'Please select at least one CT for grid')
    .max(3, 'A maximum of 3 circuits can be allocated to the grid'),
  solar: yup.array().of(solarSchema),
  battery: yup.array().of(batterySchema),
  load: yup.array().of(loadSchema),
  evCharger: yup.array().of(evChargerSchema),
  hybrid: yup.object().shape({
    inverter: yup
      .array()
      .of(circuitSchema)
      .test(
        'Checking if hybrid inverter has at least one ct when there is one DC coupled inverter',
        'Please select at least one CT for hybrid inverter',
        function (inverter) {
          // yuck but seems the only way to do this. No proper solutions in lib yet
          // https://github.com/jquense/yup/pull/201
          const hasDcCoupledInverter = (this as any).from[1].value.hasDcCoupledInverter;
          if (hasDcCoupledInverter) return !!inverter?.length;
          return true;
        }
      ),
    backup: yup
      .array()
      .of(circuitSchema)
      .test(
        'Checking if backup circuit has at least one ct when there is second AC output',
        'Please select at least one CT for backup circuit',
        function (backup) {
          // yuck but seems the only way to do this. No proper solutions in lib yet
          // https://github.com/jquense/yup/pull/201
          const hasSecondACOutput = (this as any).from[1].value.hasSecondACOutput;
          if (hasSecondACOutput) return !!backup?.length;
          return true;
        }
      ),
  }),
});
