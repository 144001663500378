import React, { useMemo } from 'react';
import { Box, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

import MultiToggleSwitch from '../../common/components/MultiToggleSwitch';

import './date-range-type-picker.css';

enum DateRangeType {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
  All = 'ALL',
}
export { DateRangeType };

type Props = {
  selectedDateRangeType: DateRangeType;
  setSelectedDateRangeType: (newValue: DateRangeType) => void;
  isDisabled?: boolean;
};

export default function DateRangeTypePicker({ selectedDateRangeType, setSelectedDateRangeType, isDisabled }: Props) {
  const isDesktopViewport = useBreakpointValue({
    base: false,
    lg: true,
  });
  const switchClassName = useColorModeValue(
    `light-mode-toggle-switch${isDesktopViewport ? '' : '-1'}`,
    `dark-mode-toggle-switch${isDesktopViewport ? '' : '-1'}`
  );

  const dateRangeOptions = useMemo(() => {
    return [
      {
        label: isDesktopViewport ? 'Day' : 'D',
        value: DateRangeType.Day,
      },
      {
        label: isDesktopViewport ? 'Week' : 'W',
        value: DateRangeType.Week,
      },
      {
        label: isDesktopViewport ? 'Month' : 'M',
        value: DateRangeType.Month,
      },
      {
        label: isDesktopViewport ? 'Year' : 'Y',
        value: DateRangeType.Year,
      },
      {
        label: 'All',
        value: DateRangeType.All,
      },
    ];
  }, [isDesktopViewport]);

  return (
    <Box fontWeight={'bold'} className={switchClassName}>
      <MultiToggleSwitch
        switchOptions={dateRangeOptions}
        onChange={(newValue) => setSelectedDateRangeType(newValue as DateRangeType)}
        value={selectedDateRangeType}
        disabled={isDisabled}
      />
    </Box>
  );
}
