import { ScheduleFormData } from '../switch-config-form-types';

export type ValidationResult = {
  type: 'SUCCESS' | 'ERROR' | 'WARNING';
  message: string;
  scheduleIndex?: number; // The index which the error occurred at, if relevant
};

// The amount of time each day that the switch is expected to be on. If exceeded, the user sees a warning, but can still
// proceed with less than this amount of time per day.
const EXPECTED_DAILY_ON_TIME_HRS = 4;

/**
 * Uses a 2D array to validate time ranges against each other, ensuring that there is no time overlap between
 * the provided schedules, that they can't start after their end time.
 *
 * This function will return a validation error for the first problem it finds.
 *
 * @param schedules - The list of schedules to validate
 * @returns An object with metadata about the validation result.
 */
export function validateSchedules(schedules: ScheduleFormData[]): ValidationResult {
  const weekdaysToPeriods: { startTime: Date; endTime: Date }[][] = Array.from(Array(7)).map<
    { startTime: Date; endTime: Date }[]
  >(() => []);

  for (const [scheduleIndex, schedule] of schedules.entries()) {
    if (!schedule.daysOfWeek.length) {
      return {
        type: 'ERROR',
        message: 'A schedule must be set for at least one day of the week.',
        scheduleIndex,
      };
    }

    const startTime = new Date('1970-01-01');
    const [startTimeHours, startTimeMins] = schedule.startTime.split(':').map((value) => Number(value));
    startTime.setHours(startTimeHours);
    startTime.setMinutes(startTimeMins);

    const endTime = new Date('1970-01-01');
    const [endTimeHours, endTimeMins] = schedule.endTime.split(':').map((value) => Number(value));
    endTime.setHours(endTimeHours);
    endTime.setMinutes(endTimeMins);

    if (startTime.getTime() > endTime.getTime()) {
      return {
        type: 'ERROR',
        message: 'Start time cannot be after end time.',
        scheduleIndex,
      };
    }

    if (startTime.getTime() === endTime.getTime()) {
      return {
        type: 'ERROR',
        message: 'Start time cannot be the same as end time.',
        scheduleIndex,
      };
    }

    for (const scheduleWeekdayIndex of schedule.daysOfWeek) {
      // For each day of the week, ensure there is no overlap
      const existingSchedulesForWeekday = weekdaysToPeriods[scheduleWeekdayIndex];

      for (const existingSchedule of existingSchedulesForWeekday) {
        const { startTime: existingStartTime, endTime: existingEndTime } = existingSchedule;

        if (existingEndTime.getTime() === startTime.getTime() || existingStartTime.getTime() === endTime.getTime()) {
          return {
            type: 'ERROR',
            message: 'Schedule times cannot overlap.',
            scheduleIndex,
          };
        }

        if (existingStartTime.getTime() <= startTime.getTime() && existingEndTime.getTime() >= endTime.getTime()) {
          return {
            type: 'ERROR',
            message: 'Schedule times cannot overlap.',
            scheduleIndex,
          };
        }

        if (startTime.getTime() <= existingStartTime.getTime() && endTime.getTime() >= existingEndTime.getTime()) {
          return {
            type: 'ERROR',
            message: 'Schedule times cannot overlap.',
            scheduleIndex,
          };
        }

        if (startTime.getTime() >= existingStartTime.getTime() && startTime.getTime() <= existingEndTime.getTime()) {
          return {
            type: 'ERROR',
            message: 'Schedule times cannot overlap.',
            scheduleIndex,
          };
        }

        if (existingStartTime.getTime() >= startTime.getTime() && existingStartTime.getTime() <= endTime.getTime()) {
          return {
            type: 'ERROR',
            message: 'Schedule times cannot overlap.',
            scheduleIndex,
          };
        }
      }

      weekdaysToPeriods[scheduleWeekdayIndex].push({ startTime, endTime });
    }
  }

  // Warn if not over 4 hrs per day
  for (const periodsInDay of weekdaysToPeriods) {
    const timeInPeriod = periodsInDay.reduce((totalMins, { startTime, endTime }) => {
      const delta = endTime.getTime() - startTime.getTime();

      return totalMins + delta;
    }, 0);

    const hoursInPeriod = timeInPeriod / 1000 / 60 / 60;

    if (hoursInPeriod < EXPECTED_DAILY_ON_TIME_HRS) {
      return {
        type: 'WARNING',
        message: 'It is recommended that this circuit is ON for at least 4 hours per day',
      };
    }
  }

  return {
    type: 'SUCCESS',
    message: 'Successfully validated',
  };
}
