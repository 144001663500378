import { BatteryUpsellInvitationStatus } from '../../api/api-battery-upsell';

export const BATTERY_INTEREST_STATUS_CONFIG = {
  all: { tabIndex: 0, label: 'All battery ready sites', mobileLabel: 'Battery ready' },
  interested: { tabIndex: 1, label: 'Interested in battery', mobileLabel: 'Interested' },
  not_interested: { tabIndex: 2, label: 'Not interested', mobileLabel: 'Not interested' },
} as const;

export type BatteryUpgradeOpportunitiesQueryParams = {
  limit: number;
  offset: number;
  ordering?: string;
  search_term?: string;
  invitation_status?: BatteryUpsellInvitationStatus;
};

export type BatteryUpsellSitesQueryParamKey = keyof BatteryUpgradeOpportunitiesQueryParams;

export const MOBILE_COLUMN_DISPLAY_TYPES = [
  {
    label: 'Usable battery',
    value: 'best_simulation__battery_usable_kwh',
  },
  {
    label: 'Avg. Solar Export',
    value: 'best_simulation__solar_export_daily_before_kwh',
  },
  {
    label: 'Avg.Grid Import',
    value: 'best_simulation__grid_import_daily_before_kwh',
  },
];

export const getTableHeader = (invitationStatus: BatteryUpsellInvitationStatus | 'all') => {
  if (invitationStatus === 'all') {
    return 'No eligible battery ready sites found!';
  } else if (invitationStatus === 'interested') {
    return 'No interested sites found!';
  } else {
    return 'No uninterested sites found!';
  }
};
export const getTableSubHeader = (invitationStatus: BatteryUpsellInvitationStatus | 'all') => {
  if (invitationStatus === 'all') {
    return 'Once your sites are eligible, they will appear here.';
  } else if (invitationStatus === 'interested') {
    return 'Once you have interested sites, they will appear here.';
  } else {
    return 'Once you have uninterested sites, they will appear here.';
  }
};
