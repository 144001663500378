import React from 'react';
import {
  Button,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import yellowSkippedIcon from '../../../../assets/images/yellow_skipped_icon.svg';
import CustomButton from '../../../../common/components/CustomButton';

type SkipTestBottomDrawerProps = {
  isOpen: boolean;
  onClose: (isConfirmed: boolean) => void;
};

export default function SkipTestBottomDrawer({ isOpen, onClose }: SkipTestBottomDrawerProps) {
  const { buttonTextColor, secondaryButtonTextColor } = useColorModeValue(
    { buttonTextColor: 'white', secondaryButtonTextColor: 'customBlack.500' },
    { buttonTextColor: 'black', secondaryButtonTextColor: 'dusk100.200' }
  );

  return (
    <Drawer placement={'bottom'} isOpen={isOpen} onClose={() => onClose(false)}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <Center px={2} textAlign={'center'} flexDirection={'column'} data-testid="skip-all-test-drawer">
          <Image my={2} w={['30%', '30%', '10%']} src={yellowSkippedIcon} alt={'Skip test icon'} />
          <Heading my={2} size={'md'}>
            Are you sure you want to skip all?
          </Heading>
          <Text fontSize={'md'}>Any incorrect data may cause data inconsistencies in the Cortex app.</Text>
          <CustomButton
            color={buttonTextColor}
            my={2}
            onClick={() => onClose(true)}
            data-testid="skip-all-test-drawer-btn"
          >
            Skip
          </CustomButton>
          <Center>
            <Button mb={2} onClick={() => onClose(false)} variant={'ghost'} color={secondaryButtonTextColor}>
              Back to test
            </Button>
          </Center>
        </Center>
      </DrawerContent>
    </Drawer>
  );
}
