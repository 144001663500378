import React, { PropsWithChildren, useMemo, useState } from 'react';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Link,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { AlertData } from '../../../api/api-site-alerts';
import { ReactComponent as NoAlertIllustration } from '../../../assets/images/no_alert_illustration.svg';
import { CORTEX_METER_TROUBLESHOOTING_URL } from '../../../common/constants';
import { capitalizeFirstLetter } from '../../../utils/string-formatting,';
import { SYSTEM_HEALTH_STATUS_CONFIG } from '../../fleet-dashboard/fleet-dashboard-helpers';
import { useSiteAlerts, useTenantAlerts } from './alertApi';
import { AlertDrawerContext } from './AlertDrawerContext';
import { FAULT_REASON_TO_TITLE_MAP, SiteAlertsQueryParams } from './site-alert-helpers';

const TABS = ['Active', 'Past'] as const;

const INITIAL_STATE = {
  siteId: 0,
  showTenantAlerts: true,
  tabIndex: 0,
  isDrawerOpen: false,
  pageSize: 5,
  pageIndex: 0,
};

const AlertDrawerContextProvider = ({ children }: PropsWithChildren) => {
  const [{ siteId, tabIndex, isDrawerOpen, showTenantAlerts, pageSize, pageIndex }, setState] = useState(INITIAL_STATE);
  const backgroundColor = useColorModeValue('white', 'gray.900');

  const isActiveAlertsTab = tabIndex === 0;

  const queryParams = useMemo<SiteAlertsQueryParams>(
    () => ({ limit: pageSize, offset: pageSize * pageIndex }),
    [pageIndex, pageSize]
  );

  const {
    alerts: siteAlerts,
    isLoading: isLoadingSiteAlerts,
    isFetching: isFetchingSiteAlerts,
    dataCount: siteAlertsCount,
    totalPages: totalSiteAlertsPages,
  } = useSiteAlerts(siteId, isActiveAlertsTab, queryParams, showTenantAlerts);

  const {
    alerts: tenantAlerts,
    isLoading: isLoadingTenantAlerts,
    isFetching: isFetchingTenantAlerts,
    dataCount: tenantAlertsCount,
    totalPages: totalTenantAlertsPages,
  } = useTenantAlerts(isActiveAlertsTab, queryParams, !showTenantAlerts);

  const alerts = showTenantAlerts ? tenantAlerts : siteAlerts;
  const totalPages = showTenantAlerts ? totalTenantAlertsPages : totalSiteAlertsPages;
  const showPagination = totalPages > 1;

  const onOpenAlertDrawer = (siteId?: number) => {
    // if no site id is provided, show tenant alerts
    setState((prevState) => ({ ...prevState, siteId: siteId || 0, showTenantAlerts: !siteId, isDrawerOpen: true }));
  };

  const onCloseSiteAlertDrawer = () => {
    setState((prevState) => ({ ...prevState, siteId: 0, showTenantAlerts: true, tabIndex: 0, isDrawerOpen: false }));
  };

  const isSiteAlertsLoaded = !isLoadingSiteAlerts && !isFetchingSiteAlerts;
  const isTenantAlertsLoaded = !isLoadingTenantAlerts && !isFetchingTenantAlerts;
  const isAlertsLoaded = isSiteAlertsLoaded && isTenantAlertsLoaded;

  const value = {
    onOpenAlertDrawer,
    tenantAlertsCount,
    isAlertsLoaded,
  };

  return (
    <AlertDrawerContext.Provider value={value}>
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onCloseSiteAlertDrawer}>
        <DrawerOverlay />
        <DrawerContent
          bg={backgroundColor}
          width={['100vw', '100vw', '70%']}
          maxWidth={['100vw', '100vw', '520px']}
          pt="env(safe-area-inset-top)"
        >
          <DrawerCloseButton mt="env(safe-area-inset-top)" data-testid="close-alerts-drawer-btn" />
          <DrawerHeader py={2}>Alerts</DrawerHeader>
          <DrawerBody p={0} data-testid="alert-drawer">
            <Tabs index={tabIndex} isFitted borderTop="1px solid" borderColor="dusk010.500" isLazy>
              <TabList>
                {TABS.map((title, index) => {
                  const isActiveTab = index === 0;
                  const alertCount = siteAlertsCount > 99 ? 99 : siteAlertsCount;
                  return (
                    <Tab
                      fontWeight="semibold"
                      py={3}
                      key={`${title}-alerts-${index}`}
                      data-testid={`${title}-tab-btn`}
                      onClick={() => setState((prevState) => ({ ...prevState, tabIndex: index }))}
                    >
                      <Box position={'relative'} width="fit-content">
                        <Text>{title}</Text>
                        {isAlertsLoaded && !!tenantAlertsCount && isActiveTab && (
                          <Center
                            position="absolute"
                            right={-6}
                            top={0}
                            w={5}
                            h={5}
                            rounded={50}
                            background="customRed.500"
                            fontSize="12px"
                            fontWeight="semibold"
                            color="white"
                          >
                            <Text height={5}>{alertCount}</Text>
                          </Center>
                        )}
                      </Box>
                    </Tab>
                  );
                })}
              </TabList>

              <TabPanels>
                {TABS.map((key) => {
                  const alertContents = !alerts.length ? (
                    <NoAlertTemplate />
                  ) : (
                    <Box>
                      <Box>
                        {alerts.map((alert, idx) => (
                          <AlertCard alert={alert} isActiveAlert={isActiveAlertsTab} key={idx} />
                        ))}
                        {showPagination && (
                          <Center data-testid="site-alert-pagination">
                            <Button
                              data-testid={'pagination-previous-page-btn'}
                              isDisabled={pageIndex === 0}
                              leftIcon={<ChevronLeftIcon w={6} h={6} />}
                              variant={'ghost'}
                              onClick={() => setState((prevState) => ({ ...prevState, pageIndex: pageIndex - 1 }))}
                            >
                              <Text h={6}>Prev</Text>
                            </Button>
                            <Text mx={4} fontWeight={500} data-testid="site-alert-pagination-info">
                              Page {pageIndex + 1} of {totalPages}
                            </Text>
                            <Button
                              data-testid={'pagination-next-page-btn'}
                              isDisabled={pageIndex >= totalPages - 1}
                              rightIcon={<ChevronRightIcon w={6} h={6} />}
                              variant={'ghost'}
                              onClick={() => setState((prevState) => ({ ...prevState, pageIndex: pageIndex + 1 }))}
                            >
                              <Text h={6}>Next</Text>
                            </Button>
                          </Center>
                        )}
                      </Box>
                    </Box>
                  );
                  return (
                    <TabPanel key={key} data-testid={`${key}-tab-panel`}>
                      <Flex direction="column">
                        {isAlertsLoaded ? alertContents : <CenteredLoader minH={'50vh'} />}
                      </Flex>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {children}
    </AlertDrawerContext.Provider>
  );
};

export default AlertDrawerContextProvider;

type AlertCardProps = {
  alert: AlertData;
  isActiveAlert?: boolean;
};

function AlertCard({ alert, isActiveAlert = false }: AlertCardProps) {
  const [isExpanded, setExpanded] = useState(false);
  const theme = useTheme();
  const { iconColor, textColor, secondaryTextColor, borderColor, secondaryBorderColor, cardBorderColor } =
    useColorModeValue(
      {
        iconColor: 'dusk100.500',
        textColor: 'fixedCostGrey.500',
        secondaryTextColor: 'gray',
        borderColor: theme.colors.black,
        secondaryBorderColor: theme.colors.dusk100[500],
        cardBorderColor: 'rgba(0, 0, 0, 0.25)',
      },
      {
        iconColor: 'dusk100.50',
        textColor: 'dusk100.200',
        secondaryTextColor: 'dusk100.300',
        borderColor: theme.colors.white,
        secondaryBorderColor: theme.colors.dusk100[50],
        cardBorderColor: 'rgba(255,255,255, 0.25)',
      }
    );

  const { color, alternateIcon: Icon, troubleshootingSteps, alertDetails } = SYSTEM_HEALTH_STATUS_CONFIG[alert.status];

  const troubleshootingListItems = isActiveAlert
    ? troubleshootingSteps.map((step, idx) => (
        <ListItem fontSize={'sm'} mb={2} fontWeight="semibold" key={step + idx}>
          {step}
        </ListItem>
      ))
    : [];

  return (
    <Flex
      boxShadow={`0px 0px 4px ${cardBorderColor}`}
      borderRadius={8}
      mb={4}
      borderWidth={isActiveAlert ? '1px' : '0px'}
      borderColor={isActiveAlert ? color : 'transparent'}
      data-testid={`alert-card-${alert.id}`}
    >
      {isActiveAlert && <Box w={'3%'} bg={color} borderTopLeftRadius={6} borderBottomLeftRadius={6} />}
      <Box p={1} w={isActiveAlert ? '97%' : '100%'}>
        <Flex alignItems="center" justify="space-between">
          <Flex alignItems="center">
            <Center rounded="full" shadow={`0px 0px 4px ${cardBorderColor}`} w={10} h={10} my="auto" mr={2} ml={1}>
              <Icon color={iconColor} w={7} h={7} />
            </Center>
            <Heading size={'sm'}>{capitalizeFirstLetter(alert.status.toLowerCase())}</Heading>
          </Flex>

          <Flex alignItems="center">
            <Text color={secondaryTextColor} fontSize={['xs', 'sm']} mr={2}>
              Started at {alert.event_start_time.replaceAll('-', '/')}
            </Text>
            {isActiveAlert && (
              <IconButton
                px={[0, 2]}
                height={[6, 10]}
                minW={['none', 2]}
                size={'md'}
                variant={'ghost'}
                data-testid={`alert-card-expand-btn-${alert.id}`}
                aria-label={isExpanded ? 'Minimize' : 'Maximize'}
                icon={
                  <ChevronDownIcon
                    w={6}
                    h={6}
                    style={{ transition: 'transform 200ms', transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                  />
                }
                onClick={() => setExpanded(!isExpanded)}
              />
            )}
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-around" pb={2} px={2}>
          <Flex direction={'column'} borderRight={`1px solid ${borderColor}4D`} w="33%" alignItems="center">
            <Text fontSize={'sm'} color={textColor}>
              Type
            </Text>
            <Text fontSize={'sm'} fontWeight="semibold" mt={1}>
              {alert.fault_reason in FAULT_REASON_TO_TITLE_MAP ? FAULT_REASON_TO_TITLE_MAP[alert.fault_reason] : 'N/A'}
            </Text>
          </Flex>

          <Flex direction={'column'} borderRight={`1px solid ${borderColor}4D`} w="33%" alignItems="center">
            <Text fontSize={'sm'} color={textColor}>
              End Time
            </Text>
            <Text fontSize={'sm'} fontWeight="semibold" mt={1}>
              {alert.event_end_time ? alert.event_end_time.replaceAll('-', '/') : 'Ongoing'}
            </Text>
          </Flex>

          <Flex direction={'column'} w="28%" alignItems="center">
            <Text fontSize={'sm'} color={textColor}>
              Status
            </Text>
            <Text
              fontSize={'sm'}
              fontWeight="semibold"
              mt={1}
              color={isActiveAlert ? 'customRed.500' : 'primaryBranding.500'}
            >
              {isActiveAlert ? 'Active' : 'Resolved'}
            </Text>
          </Flex>
        </Flex>

        <Collapse in={isExpanded}>
          <Flex mt={6} data-testid={`active-alert-details-${alert.id}`}>
            <Flex w="100%" direction="column">
              <Flex w="100%" borderTop={`1px solid ${secondaryBorderColor}33`} py={4} pl={2}>
                <Text fontSize={'sm'} color={textColor} minW="fit-content">
                  Reason:
                </Text>
                <Text fontSize={'sm'} fontWeight="semibold" ml={16}>
                  {alert.fault_reason_description}
                </Text>
              </Flex>
              <Flex w="100%" borderTop={`1px solid ${secondaryBorderColor}33`} py={4} pl={2}>
                <Text fontSize={'sm'} color={textColor} minW="fit-content">
                  Alert details:
                </Text>
                <Text fontSize={'sm'} fontWeight="semibold" ml={8}>
                  {alertDetails}
                </Text>
              </Flex>
              <Flex w="100%" borderTop={`1px solid ${secondaryBorderColor}33`} py={4} pl={2}>
                <Text fontSize={'sm'} color={textColor} mr={2}>
                  Troubleshooting:
                </Text>
                {alert.status === 'OFFLINE' ? (
                  <Text fontSize={'sm'} mb={2} fontWeight="semibold">
                    For troubleshooting, please click{' '}
                    <Link color="customBlue.500" href={CORTEX_METER_TROUBLESHOOTING_URL} isExternal>
                      here
                    </Link>
                    .
                  </Text>
                ) : (
                  <UnorderedList>{troubleshootingListItems}</UnorderedList>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Collapse>
      </Box>
    </Flex>
  );
}

function NoAlertTemplate() {
  return (
    <Center flexDirection={'column'} data-testid="empty-alert-template">
      <NoAlertIllustration style={{ width: '100%' }} />
      <Heading size={'sm'} mt={8} mb={4}>
        No Alerts Detected
      </Heading>
      <Text>All sites are performing as expected.</Text>
    </Center>
  );
}
