import React from 'react';
import { RepeatIcon } from '@chakra-ui/icons';
import { Box, Center, FormLabel, IconButton, Image, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../user/userSlice';
import { useNearmapImage, useRefreshSiteImageMutation } from '../siteApi';
import { selectSite } from '../siteSlice';

type SiteImageProps = {
  siteImageUrl?: string | null;
};

const SiteImage = ({ siteImageUrl }: SiteImageProps) => {
  const { role } = useSelector(selectUser);
  const toast = useToast({ duration: 3000, isClosable: true });
  const [refreshImage, { isLoading }] = useRefreshSiteImageMutation();
  const { clipsal_solar_id: siteId, site_latitude, site_longitude } = useSelector(selectSite);
  const hasSiteCoordinates = site_latitude && site_longitude;
  const isSuperAdmin = role === 'SUPER_ADMIN';
  const nearMapImageLink = useNearmapImage(!!siteImageUrl || !isSuperAdmin || !hasSiteCoordinates);
  const siteImageLink = siteImageUrl || nearMapImageLink;

  if (!siteImageLink) return null;

  return (
    <Box mt={3}>
      <Center w="100%" justifyContent={'space-between'}>
        <FormLabel>Site Image</FormLabel>
        {isSuperAdmin && hasSiteCoordinates && (
          <IconButton
            aria-label="Refresh Image"
            icon={<RepeatIcon />}
            variant={'ghost'}
            data-testid="refresh-image-button"
            onClick={async () => {
              toast.closeAll();
              try {
                await refreshImage(siteId).unwrap();
                toast({
                  title: 'Site image is being refreshed...',
                  description: 'Please wait a few seconds and refresh the page',
                  status: 'info',
                });
              } catch (error) {
                await toast({
                  title: 'Failed to refresh image!',
                  status: 'error',
                });
              }
            }}
            isLoading={isLoading}
          />
        )}
      </Center>
      <Center>
        <Image src={siteImageLink} alt={'Site satellite image'} />
      </Center>
    </Box>
  );
};

export default SiteImage;
