import './common/types/pwa-register.d.ts';
import 'clipsal-cortex-utils/src/build/polyfills';
import 'regenerator-runtime/runtime';
import 'focus-visible/dist/focus-visible'; // Removes blue border when clicking focusable items
import './index.css';

import React from 'react';
import { Capacitor } from '@capacitor/core';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { createRoutes } from './common/components/route/createRoutes';
import { IS_PRODUCTION_BUILD, IS_RUNNING_CYPRESS_TESTS } from './common/constants';
import { AppProvider } from './Provider';
import { routes } from './routes';
import { setupAPI } from './utils/setup-api';
import { setupSentry } from './utils/setup-sentry';

async function setupReactDOM() {
  // This is only here to prompt for user tracking permissions on app launch, then the result is used elsewhere
  // (i.e. when a user is logged in). Just ensures the prompt occurs on app launch, rather than at login time.
  if (IS_PRODUCTION_BUILD && Capacitor.getPlatform() === 'ios') {
    await AppTrackingTransparency.requestPermission();
  }

  setupAPI();

  const reactRoutes = createRoutes(routes);
  const router = createBrowserRouter([{ element: <AppProvider />, children: reactRoutes }]);

  const container = document.getElementById('root');
  const root = createRoot(container!);

  // Only send events to sentry in staging and production environments
  if (import.meta.env['MODE'] !== 'development' && !IS_RUNNING_CYPRESS_TESTS) setupSentry();
  root.render(<RouterProvider router={router} />);
}

setupReactDOM();
