import { extendTheme, theme } from '@chakra-ui/react';

export const colors = {
  ...theme.colors,
  ellipse: {
    50: '#FFC634',
    100: '#FFC634',
    200: '#FFC634',
    300: '#FFC634',
    400: '#FFC634',
    500: '#FFC634',
    600: '#FFC634',
    700: '#FFC634',
    800: '#FFC634',
    900: '#FFC634',
  },
  customBlack: {
    50: '#000000',
    100: '#000000',
    200: '#000000',
    300: '#000000',
    400: '#000000',
    500: '#000000',
    600: '#000000',
    700: '#000000',
    800: '#000000',
    900: '#000000',
  },
  heat: {
    50: '#DC4C88',
    100: '#DC4C88',
    200: '#DC4C88',
    300: '#DC4C88',
    400: '#DC4C88',
    500: '#DC4C88',
    600: '#DC4C88',
    700: '#DC4C88',
    800: '#DC4C88',
    900: '#DC4C88',
  },
  clipsalGreenFaded: {
    50: '#e4fce92b',
    100: '#bff0c92b',
    200: '#98e5a72b',
    300: '#71db852b',
    400: '#4ad0632b',
    500: '#31b74a2b',
    600: '#248e392b',
    700: '#1866272b',
    800: '#0b3d162b',
    900: '#0016022b',
  },
  customGray: {
    50: '#1c2230',
    100: '#1c2230',
    200: '#1c2230',
    300: '#1c2230',
    400: '#1c2230',
    500: '#1c2230',
    600: '#1c2230',
    700: '#1c2230',
    800: '#1c2230',
    900: '#1c2230',
  },
  fixedCostGrey: {
    50: '#6F6F6F',
    100: '#6F6F6F',
    200: '#6F6F6F',
    300: '#6F6F6F',
    400: '#6F6F6F',
    500: '#6F6F6F',
    600: '#6F6F6F',
    700: '#6F6F6F',
    800: '#6F6F6F',
    900: '#6F6F6F',
  },
  fixedCostGreyDark: {
    50: '#c2c2c2',
    100: '#c2c2c2',
    200: '#c2c2c2',
    300: '#c2c2c2',
    400: '#c2c2c2',
    500: '#c2c2c2',
    600: '#c2c2c2',
    700: '#c2c2c2',
    800: '#c2c2c2',
    900: '#c2c2c2',
  },
  customBlue: {
    50: '#dcf3ff',
    100: '#aed7ff',
    200: '#7dbdff',
    300: '#4aa2ff',
    400: '#1a87ff',
    500: '#006ee6',
    600: '#0055b4',
    700: '#003d82',
    800: '#002551',
    900: '#000d21',
  },
  customGreen: {
    50: '#e3fde8',
    100: '#bef0c8',
    200: '#98e6a6',
    300: '#70da84',
    400: '#49d062',
    500: '#3DCD58',
    600: '#238e37',
    700: '#166526',
    800: '#093e15',
    900: '#001601',
  },
  customTabBlue: {
    500: '#5E94F1',
  },
  primaryBranding: {
    50: '#e3fde8',
    100: '#bef0c8',
    200: '#98e6a6',
    300: '#70da84',
    400: '#49d062',
    500: '#3DCD58',
    600: '#238e37',
    700: '#166526',
    800: '#093e15',
    900: '#001601',
  },
  primaryBrandingStatic: {
    50: '#3DCD58',
    100: '#3DCD58',
    200: '#3DCD58',
    300: '#3DCD58',
    400: '#3DCD58',
    500: '#3DCD58',
    600: '#3DCD58',
    700: '#3DCD58',
    800: '#3DCD58',
    900: '#3DCD58',
  },
  primaryButton: {
    50: '#ccd0cc',
    100: '#c0c4c0',
    200: '#b2b7b2',
    300: '#a4a9a4',
    400: '#747979',
    500: '#2B2E2D',
    600: '#212222',
    700: '#242323',
    800: '#1a1919',
    900: '#0a0a0a',
  },
  secondaryButton: {
    50: '#ccd0cc',
    100: '#c0c4c0',
    200: '#b2b7b2',
    300: '#a4a9a4',
    400: '#747979',
    500: '#2B2E2D',
    600: '#212222',
    700: '#242323',
    800: '#1a1919',
    900: '#0a0a0a',
  },
  dusk100: {
    50: '#ccd0cc',
    100: '#c0c4c0',
    200: '#b2b7b2',
    300: '#a4a9a4',
    400: '#747979',
    500: '#2B2E2D',
    600: '#212222',
    700: '#242323',
    800: '#1a1919',
    900: '#0a0a0a',
  },
  dusk050: {
    500: '#9BA0A1',
  },
  dusk010: {
    500: '#EAEBEA',
  },
  dusk005: {
    500: '#F1F1F2',
  },
  slate: {
    500: '#ABBAC3',
  },
  natural: {
    500: '#F4F4F2',
  },
  day: {
    500: '#86B5D1',
  },
  backgroundGrey: {
    500: '#F5F6F8',
  },
  textGrey: {
    500: '#8A8A8D',
  },
  customLinkBlue: {
    500: '#007AFF',
  },
  customRed: {
    500: '#E53E3E',
  },
  customSelectBlue: {
    500: '#3182ce',
  },
  customOrange: {
    500: '#FFC634',
  },
  customTableGray: {
    400: '#e2e8f0',
    500: 'rgb(237, 242, 247)',
  },
  customLineChartReferenceLineGray: {
    500: '#76797a',
  },
  customTeal: {
    500: '#8ED2CE',
  },
  customPaleBlue: {
    500: '#6B8DA0',
  },
  customDarkOrange: {
    500: '#E06D00',
  },
  customBodyBackground: {
    500: '#ECF2F4',
    800: '#1c2230',
  },
  customPink: {
    500: '#E04E8B',
  },
  customPurple: {
    500: '#8E95D2',
  },
};

const config = {
  initialColorMode: 'system',
};

const customTheme = extendTheme({
  config,
  colors,
  components: {
    Button: {
      baseStyle: {
        // removes weird glitching outlines in components
        outline: '0px transparent',
      },
    },
  },
});

export default customTheme;
