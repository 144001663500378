/**
 * Implemented in a separate file due to the inability to mix multiple exports from `index.tsx`
 * with `react-testing-library`.
 */

export type ShorthandEnvironmentType = 'STAGING' | 'PROD' | 'TEST';

export let ENV_TYPE_SHORTHAND: ShorthandEnvironmentType = import.meta.env['MODE'] === 'production' ? 'PROD' : 'STAGING';

// Check local storage for a different env setting
let fromStorage = localStorage.getItem('cortexEnvType');
// Short-term fix to re-assign DEV to STAGING
if (fromStorage && fromStorage === 'DEV') fromStorage = 'STAGING';
if (!!fromStorage) ENV_TYPE_SHORTHAND = fromStorage as ShorthandEnvironmentType;
